<div class="content">
  <div class="content-panel">
    <h2 translate>page.declarations.suivi-declarations.title</h2>

    <table table class="table table-striped" *ngIf="historiques?.length > 0; else aucunHistorique">
      <thead>
        <tr>
          <th class="liste-col-header" translate>page.declarations.suivi-declarations.table.header.numeroDeclaration
          </th>
          <th class="liste-col-header" translate>page.declarations.suivi-declarations.table.header.cvi</th>
          <th class="liste-col-header" translate>page.declarations.suivi-declarations.table.header.raisonSociale</th>
          <th class="liste-col-header" translate>page.declarations.suivi-declarations.table.header.siret</th>
          <th class="liste-col-header" translate>page.declarations.suivi-declarations.table.header.typeDeclaration</th>
          <th class="liste-col-header" translate>page.declarations.suivi-declarations.table.header.date</th>
          <th class="liste-col-header" translate>page.declarations.suivi-declarations.table.header.volumeTotal</th>
          <th class="liste-col-header" translate>page.declarations.suivi-declarations.table.header.cdc</th>
          <th class="liste-col-header" translate>page.declarations.suivi-declarations.table.header.produit</th>
          <th class="liste-col-header" translate>page.declarations.suivi-declarations.table.header.annee</th>
          <th class="liste-col-header" translate>page.declarations.suivi-declarations.table.header.numeroLot</th>
          <th class="liste-col-header" translate>
            page.declarations.suivi-declarations.table.header.statut
            <fa-icon icon="envelope-open" (click)="onPasserToutALuOuNonLu(true)"
              title="{{ 'page.declarations.suivi-declarations.table.header.toutALu' | translate }}"
              class="pointer icon"></fa-icon>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let historique of historiques">
          <td>{{ historique.numeroDeclaration}}</td>
          <td>{{ historique.cvi.toString()}}</td>
          <td>{{ historique.operateur?.raisonSociale }}</td>
          <td>{{ historique.operateur?.siret }}</td>
          <td>{{ getTypeMouvement(historique.code) }}</td>
          <td>{{ historique.date | date:'dd/MM/yyyy HH:mm' }}</td>
          <td>{{ historique.volume | number:'1.2-2' }}</td>
          <td>{{ historique.libelleCahier }}</td>
          <td>{{ historique.produit?.libelle }}</td>
          <td>{{ historique.annee }}</td>
          <td>{{ historique.numeroLot }}</td>
          <td>
            <span class="lue badge" *ngIf="historique.estLu"
              translate>page.declarations.suivi-declarations.table.header.lu</span>
            <span class="non-lue badge" *ngIf="!historique.estLu" (click)="onChangerStatut(historique)"
              translate>page.declarations.suivi-declarations.table.header.nonLu</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- Template aucun historique -->
<ng-template #aucunHistorique>
  <div class="alert alert-warning alert-block" translate>
    <fa-icon icon="exclamation"></fa-icon> page.declarations.suivi-declarations.aucunHistorique
  </div>
</ng-template>
<!-- Fin template aucun historique -->