import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObjetFacture } from '../../models/objet-facture.model';
import { ObjetFactureDTO } from '../../models/objet-facture-dto.model';

@Injectable({
  providedIn: 'root'
})
export class FacturationService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
  ) { }

  postFacturation(idOrganisme: number, idModeleFacturation: number, idsObjetsFacture: number[]): Observable<number[]> {
    return this.http.post<number[]>(
      `/api/facturation/private/organismes/${idOrganisme}/modeleFacturation/${idModeleFacturation}/facturer`,
      idsObjetsFacture
    ).pipe(map(result => result));
  }

  postFacturationAfterMouvement(idModeleFacturation: number, objetsFacture: ObjetFactureDTO[]): Observable<string> {
    
    return this.http.post(
      `/api/facturation/private/modeleFacturation/${idModeleFacturation}/facturerAfterMouvement`,
      objetsFacture, 
      {  observe: 'response', 
      responseType: 'text' }
    ).pipe(map(result => result.body));
  }
  
}
