<div class="content">
  <div class="content-panel">
    <h2>
      <span *ngIf="edition == false" translate>page.commissions.edit.titleCreer</span>
      <span *ngIf="edition == true" translate>page.commissions.edit.titleEditer</span>
      <span class="chiffres">
        <fa-icon icon="clipboard-list" title="{{'page.commissions.edit.tooltip.cdcs'|translate}}"></fa-icon>{{nbCdcs}} -
        <fa-icon icon="user-plus" title="{{'page.commissions.edit.tooltip.jures_incrits'|translate}}"></fa-icon>
        {{nbJuresInscrits}} -
        <fa-icon icon="user-check" title="{{'page.commissions.edit.tooltip.jures_presents'|translate}}"></fa-icon>
        {{nbJuresPresents}} -
        <fa-icon icon="users" title="{{'page.commissions.edit.tooltip.jures_total'|translate}}"></fa-icon>
        {{nbJuresTotal}} -
        <fa-icon icon="wine-glass-alt" title="{{'page.commissions.edit.tooltip.echantillons'|translate}}"></fa-icon>
        {{nbEchantillons}}
        <span *ngIf="edition == true">
          <label translate style="font-size: 20px; padding-right: 10px; padding-left: 50px;">Terminée</label>
          <input type="checkbox" (change)="checkValue(commissionIsOver? true:false)" [(ngModel)]="commissionIsOver" />
        </span>

      </span>
      <div *ngIf="edition == true">
        <span>
          <button style="position:relative;" type="button" class="btn btn-primary" (click)="telechargerDocument()"
            translate>page.commissions.edit.telechargerDocuments</button>
          <button style="position:relative;margin-left: 1em;" type="button" class="btn btn-primary"
            (click)="envoyerDocumentsMail()" translate>page.commissions.edit.envoyerDocumentsMail</button>
        </span>
      </div>

    </h2>

    <form *ngIf="formGroup" [formGroup]="formGroup" #form="ngForm" (ngSubmit)="submit()" novalidate>

      <div class="form-section">
        <div class="form-group">
          <label translate>page.commissions.edit.date</label>

          <div class="input-group">
            <input formControlName="date" ngbDatepicker #datePicker="ngbDatepicker"
              [ngClass]="{'is-invalid': form.submitted && formGroup.get('date').invalid}" class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="datePicker.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label translate>page.commissions.edit.heureDebut</label>
          <ngb-timepicker formControlName="heureDebut"
            [ngClass]="{'is-invalid': form.submitted && formGroup.get('heureDebut').invalid}" class="form-control">
          </ngb-timepicker>
        </div>


        <div class="form-group">
          <label translate>page.commissions.edit.heureFin</label>
          <ngb-timepicker formControlName="heureFin"
            [ngClass]="{'is-invalid': form.submitted && formGroup.get('heureFin').invalid}" class="form-control">
          </ngb-timepicker>
        </div>
      </div>

      <div class="form-section">
        <div class="form-group">
          <label translate>page.commissions.edit.salle</label>
          <select formControlName="salle" [ngClass]="{'is-invalid': form.submitted && formGroup.get('salle').invalid}"
            class="form-control">
            <option *ngFor="let salle of salles" [ngValue]="salle">{{salle.nom}}</option>
          </select>
        </div>

        <div class="form-group">
          <label translate>page.commissions.edit.animateur</label>
          <select formControlName="animateur"
            [ngClass]="{'is-invalid': form.submitted && formGroup.get('animateur').invalid}" class="form-control">
            <option *ngFor="let animateur of animateurs" [ngValue]="animateur">{{animateur.prenom}} {{animateur.nom}}
            </option>
          </select>
        </div>
      </div>

      <div>
        <button style="position:relative;left:66%;margin:1em;" type="button" class="btn btn-primary"
          (click)="affecterEchantillons()" translate>page.commissions.edit.jurys.affecter-echantillons</button>
      </div>

      <div cdkDropList (cdkDropListDropped)="moveJury($event)">
        <div formArrayName="jurys" *ngFor="let juryForm of jurys; let index = index" cdkDrag>
          <div class="jury-dad-placeholder" *cdkDragPlaceholder></div>
          <div [formGroupName]="index" class="form-group form-jury">
            <h3 class="jury-title">
              <fa-icon cdkDragHandle icon="bars" class="grab" style="font-size:0.8em;"
                [ngClass]="{'disabled':jurys.length < 2}"></fa-icon>
              {{'page.commissions.edit.jurys.title' | translate}} {{index+1}}
              <fa-icon icon="trash" class="pointer" style="font-size:0.8em;" [ngClass]="{'disabled':jurys.length < 2}"
                (click)="deleteJury(index)"></fa-icon>
            </h3>
            <div class="jury-listes">
              <div class="list-cdcs jury-liste"
                [ngClass]="{'is-invalid': form.submitted && juryForm.get('cdcs').invalid}">
                <h4 class="jury-liste-title">
                  <span translate>page.commissions.edit.jurys.cdcs</span>
                  <span> ({{juryForm.get('cdcs').value.length}})</span>
                  <fa-icon icon="plus" (click)="addCdc(index)"></fa-icon>
                </h4>
                <div cdkDropList id="cdcs-{{index}}" [cdkDropListConnectedTo]="generateIdsList('cdcs', jurys.length)"
                  [cdkDropListEnterPredicate]="canDropCdc" (cdkDropListDropped)="moveCdc($event)"
                  [cdkDropListData]="juryForm.get('cdcs').value">
                  <div *ngFor="let cdc of juryForm.get('cdcs').value" cdkDrag class="jury-liste-item"
                    [cdkDragData]="cdc">
                    <div class="listes-dad-placeholder" *cdkDragPlaceholder></div>
                    <fa-icon cdkDragHandle icon="bars" class="grab" style="font-size:0.8em;"></fa-icon>
                    {{cdc.libelle}}
                    <span class="flex-expand"></span>
                    <fa-icon icon="trash" (click)="removeCdc(index, cdc)" class="pointer jury-liste-delete"></fa-icon>
                  </div>
                </div>
              </div>
              <div class="list-jures jury-liste"
                [ngClass]="{'is-invalid': form.submitted && juryForm.get('jures').invalid}">
                <h4 class="jury-liste-title">
                  <span translate>page.commissions.edit.jurys.jures</span>
                  <span> ({{juryForm.get('jures').value.length}})</span>
                  <fa-icon icon="plus" (click)="addJure(index)"></fa-icon>
                </h4>
                <div cdkDropList id="jures-{{index}}" [cdkDropListConnectedTo]="generateIdsList('jures', jurys.length)"
                  [cdkDropListEnterPredicate]="canDropJure" (cdkDropListDropped)="moveJure($event)"
                  [cdkDropListData]="juryForm.get('jures').value">
                  <div *ngFor="let jure of juryForm.get('jures').value" cdkDrag class="jury-liste-item"
                    [cdkDragData]="jure">
                    <div class="listes-dad-placeholder" *cdkDragPlaceholder></div>
                    <fa-icon cdkDragHandle icon="bars" class="grab" style="font-size:0.8em;"></fa-icon>
                    {{jure.jure.nom}} - {{jure.college.code}}
                    <ng-container *ngTemplateOutlet="statutJure;context:{statut:jure.statut, jure:jure}"></ng-container>
                    <span class="flex-expand"></span>
                    <fa-icon icon="trash" (click)="removeJure(index, jure)" class="pointer jury-liste-delete"></fa-icon>
                  </div>
                </div>
              </div>
              <div class="list-echantillons jury-liste"
                [ngClass]="{'is-invalid': form.submitted && juryForm.get('echantillons').invalid}">
                <h4 class="jury-liste-title">
                  <span translate>page.commissions.edit.jurys.echantillons</span>
                  <span> ({{juryForm.get('echantillons').value.length}})</span>
                  <fa-icon icon="plus" (click)="addEchantillons(index)"></fa-icon>
                </h4>
                <div cdkDropList id="echantillons-{{index}}"
                  [cdkDropListConnectedTo]="generateIdsList('echantillons', jurys.length)"
                  (cdkDropListDropped)="moveEchantillon($event)" [cdkDropListData]="juryForm.get('echantillons').value">
                  <div *ngFor="let echantillon of juryForm.get('echantillons').value" cdkDrag class="jury-liste-item"
                    [cdkDragData]="echantillon">
                    <div class="listes-dad-placeholder" *cdkDragPlaceholder></div>
                    <fa-icon cdkDragHandle icon="bars" class="grab" style="font-size:0.8em;"></fa-icon>
                    {{echantillon.numeroAnonymat}} - <strong *ngIf="echantillon.echantillon">E</strong><strong
                      *ngIf="echantillon.leurre">L</strong> -
                      <ng-container *ngIf="echantillon.echantillon && echantillon.echantillon.produit">
                        <span  title="{{ echantillon.echantillon.produit.libelle }}">
                      {{echantillon.echantillon.produit.libelle | truncate:[50, '...']}}
                      {{echantillon.echantillon.millesime? ' - '+echantillon.echantillon.millesime: ''}}
                      <br />{{getCVI(echantillon.echantillon)}} - {{getRaisonSociale(echantillon.echantillon)}}
                    </span>
                      </ng-container>
                    
                    <span *ngIf="echantillon.leurre">
                      {{echantillon.leurre.produit.libelle | truncate:[50, '...']}} - {{echantillon.leurre.millesime}} -
                      {{echantillon.leurre.type.libelle}}
                      <br /><span
                        *ngFor="let defaut of echantillon.leurre.defauts;let isLast=last">{{defaut.libelle}}{{isLast ? '' : ', '}}</span>
                    </span>
                    <span class="flex-expand"></span>
                    <fa-icon icon="trash" (click)="removeEchantillon(index, echantillon)"
                      class="pointer jury-liste-delete"></fa-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="actions-bar">
        <button type="button" class="btn btn-primary" (click)="addJury()"
          translate>page.commissions.edit.ajouter_jury</button>
        <span style="flex:1"></span>
        <button type="button" class="btn btn-primary" (click)="enregistrer()" translate>label.enregistrer</button>
        <button type="submit"  style="position:relative;margin-left: 1em;" class="btn btn-primary" translate>label.fermer</button>
      </div>
    </form>

  </div>
  <div style="height:20vh"></div>
</div>

<ng-template #statutJure let-statut="statut" , let-jure="jure">
  <!-- <span class="statutJure {{statut.code}}">
      {{statut.libelle}}
      <fa-icon icon="pen"></fa-icon>
    </span> -->
  <div ngbDropdown class="dropdown" class="statut-jure">
    <strong ngbDropdownToggle class="btn-sm dropdown-toggle pointer statut-jure-current {{statut.code}}"
      data-toggle="dropdown">
      <fa-icon [icon]="getStatutIcon(statut.code)"></fa-icon>
      {{statut.libelle}}
    </strong>
    <div class="dropdown-menu" ngbDropdownMenu>
      <span *ngFor="let statut of statutsJure" class="dropdown-item pointer statut-jure-item {{statut.code}}"
        (click)="changeSatut(jure, statut)">
        <fa-icon [icon]="getStatutIcon(statut.code)"></fa-icon>
        {{statut.libelle}}
      </span>
    </div>
  </div>

</ng-template>