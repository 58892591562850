import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { CdkDragDrop, CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { forkJoin, of, from } from 'rxjs';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { remove, find } from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animateur } from 'src/app/data/commission/models/animateur.model';
import { Salle } from 'src/app/data/commission/models/salle.model';
import { SallesService } from 'src/app/data/commission/services/salles/salles.service';
import { AnimateursService } from 'src/app/data/commission/services/animateurs/animateurs.service';
import { Commission } from 'src/app/data/commission/models/commission.model';
import { Jury } from 'src/app/data/commission/models/jury.model';
import { CommissionsService } from 'src/app/data/commission/services/commissions/commissions.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { Jure } from 'src/app/data/commission/models/jure.model';
import { JureJury } from 'src/app/data/commission/models/jure-jury.model';
import { Cahier } from 'src/app/data/habilitation/models/cahier.model';
import { RechercheJureComponent } from './modals/recherche-jure/recherche-jure.component';
import { RefStatutJureCode } from 'src/app/data/commission/models/enums/ref-statut-jure.enum';
import { ReferencesService } from 'src/app/data/commission/services/references/references.service';
import { StatutJure } from 'src/app/data/commission/models/statut-jure.model';
import { RechercheCdcsComponent } from './modals/recherche-cdcs/recherche-cdcs.component';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Fail } from 'src/app/shared/errors/fail.error';
import { RechercheEchantillonsComponent } from './modals/recherche-echantillons/recherche-echantillons.component';
import { Echantillon } from 'src/app/data/commission/models/echantillon.model';
import { Leurre } from 'src/app/data/commission/models/leurre.model';
import { EchantillonJury } from 'src/app/data/commission/models/echantillon-jury.model';
import { FunctionalError } from 'src/app/shared/errors/functional.error';
import { AffectationEchantillonsComponent } from './modals/affectation-echantillons/affectation-echantillons.component';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { ProduitsService } from 'src/app/data/habilitation/services/produits/produits.service';
import { TypeLeurre } from 'src/app/data/commission/models/type-leurre.model';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { CategorieEnum } from 'src/app/data/edition/models/enums/categorie.enum.model';
import { TelechargerDocumentComponent } from '../../../main/components/telecharger-document/telecharger-document.component';
import { JureCdc } from 'src/app/data/commission/models/jure-cdc.model';

const heureRequiredValidator = function(control: FormControl) {
  if (!control.value || typeof control.value.hour === 'undefined' || typeof control.value.minute === 'undefined') {
    return { invalid: true };
  }

  return null;
};

@Component({
  selector: 'app-edit-commission',
  templateUrl: './edit-commission.component.html',
  styleUrls: ['./edit-commission.component.scss']
})
export class EditCommissionComponent implements OnInit {
  commission: Commission;
  salles: Salle[];
  animateurs: Animateur[];
  statutsJure: StatutJure[];
  typesLeurres: TypeLeurre[];
  indexLastEchantillon: number;
  edition: boolean;
  commissionIsOver = false;

  private readonly statutsJureOrder = [
    RefStatutJureCode.A_CONVOQUER,
    RefStatutJureCode.CONVOQUE,
    RefStatutJureCode.INSCRIT,
    RefStatutJureCode.DESINSCRIT,
    RefStatutJureCode.EXCUSE,
    RefStatutJureCode.PRESENT,
    RefStatutJureCode.ABSENT
  ];

  get nbCdcs(): number {
    let idCdcs: number[] = [];
    return this.jurys.reduce((total, jury) => {
      const cdcs: Cahier[] = jury.get('cdcs').value.filter(cdc =>
        !idCdcs.includes(cdc.id)
      );
      idCdcs = idCdcs.concat(cdcs.map(cdc => cdc.id));
      return total + cdcs.length;
    }, 0);
  }
  get nbJuresInscrits(): number {
    return this.jurys.reduce((total, jury) => {
      const jures: JureJury[] = jury.get('jures').value;
      return total + jures.reduce((tt, jure) => tt + (jure.statut.code === RefStatutJureCode.INSCRIT ? 1 : 0), 0);
    }, 0);
  }
  get nbJuresPresents(): number {
    return this.jurys.reduce((total, jury) => {
      const jures: JureJury[] = jury.get('jures').value;
      return total + jures.reduce((tt, jure) => tt + (jure.statut.code === RefStatutJureCode.PRESENT ? 1 : 0), 0);
    }, 0);
  }
  get nbJuresTotal(): number {
    return this.jurys.reduce((total, jury) => {
      const jures: JureJury[] = jury.get('jures').value;
      return total + jures.length;
    }, 0);
  }
  get nbEchantillons(): number {
    return this.jurys.reduce((total, jury) => {
      const echantillons: EchantillonJury[] = jury.get('echantillons').value;
      return total + echantillons.length;
    }, 0);
  }

  formGroup: FormGroup;
  get jurys(): FormGroup[] {
    try {
      return (this.formGroup.get('jurys') as FormArray).controls as FormGroup[];
    } catch (e) {
      return [];
    }
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly sallesServices: SallesService,
    private readonly animateursService: AnimateursService,
    private readonly commissionsService: CommissionsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly modalService: NgbModal,
    private readonly refService: ReferencesService,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly produitService: ProduitsService,
    private readonly operateursService: OperateursService
  ) { }

  ngOnInit() {
    let idCommission = null;
    if (this.route.snapshot.paramMap.has('idCommission')) {
      idCommission = parseInt(this.route.snapshot.paramMap.get('idCommission'), 10);
      isNaN(idCommission) ? this.edition = false : this.edition = true;
    }

    this.loadData(idCommission);
  }

  loadData(idCommission: number) {
    forkJoin(
      isNaN(idCommission) ? of(new Commission()) : this.commissionsService.getCommissions(SessionContext.get('idOrganisme'), idCommission), // TODO if idCommission is set, get from server
      // of(new Commission()),
      this.sallesServices.getSalles(SessionContext.get('idOrganisme')),
      this.animateursService.getAnimateurs(SessionContext.get('idOrganisme')),
      this.refService.getReferences()
    ).pipe(
      this.loaderService.operator()
    ).subscribe(
      ([commission, salles, animateurs, ref]) => {
        this.commission = commission;
        this.salles = salles;
        this.animateurs = animateurs;
        this.statutsJure = ref.statutsJure;
        this.statutsJure.sort((a, b) => {
          return this.statutsJureOrder.indexOf(a.code) - this.statutsJureOrder.indexOf(b.code);
        });
        this.typesLeurres = ref.typesLeurres;

        this.commissionIsOver = this.commission.terminee;

        this.createForm(commission);

        this.indexLastEchantillon = this.nbEchantillons;

        if (this.commission.jurys) {
          this.commission.jurys.forEach(jury => {
            jury.echantillons.forEach(echantillon => {
              // Si échantillon
              if (echantillon.echantillon) {
                this.produitService.getProduitByCode(echantillon.echantillon.codeProduit)
                  .subscribe(produit => {
                    echantillon.echantillon.produit = produit;
                  });
                this.operateursService.getInformationsDomaine(echantillon.echantillon.idOperateur)
                  .subscribe(infos => echantillon.echantillon.infosOperateur = infos);
                this.operateursService.getOperateur(echantillon.echantillon.idOperateur)
                  .subscribe(op => echantillon.echantillon.operateur = op);
              } else {  // Sinon (= si leurre)
                this.produitService.getProduitByCode(echantillon.leurre.codeProduit)
                  .subscribe(produit => {
                    echantillon.leurre.produit = produit;
                  });
              }
            });
          });
        }


      }
    );

  }

  createForm(commission: Commission) {

    if (this.commission.id) {
      const selectedAnimateur = find(this.animateurs, anim => anim.id === commission.animateur.id);
      const selectedSalle = find(this.salles, salle => salle.id === commission.salle.id);
      this.formGroup = this.fb.group({
        date: [{
          year: commission.dateDebut.toDate().getFullYear(),
          month: commission.dateDebut.toDate().getMonth() + 1,
          day: commission.dateDebut.toDate().getDate()
        }, Validators.required],
        heureDebut: [{ hour: commission.dateDebut.toDate().getHours(), minute: commission.dateDebut.toDate().getMinutes() }, heureRequiredValidator],
        heureFin: [{ hour: commission.dateFin.toDate().getHours(), minute: commission.dateFin.toDate().getMinutes() }, heureRequiredValidator],
        animateur: [selectedAnimateur, Validators.required],
        salle: [selectedSalle, Validators.required],
        jurys: this.fb.array([])
      });

      commission.jurys.forEach(item => {
        (this.formGroup.get('jurys') as FormArray).push(this.getJuryForm(item));
      });

    } else {
      this.formGroup = this.fb.group({
        date: [undefined, Validators.required],
        heureDebut: [{ hour: 8, minute: 0 }, heureRequiredValidator],
        heureFin: [{ hour: 18, minute: 0 }, heureRequiredValidator],
        animateur: ['undefined', Validators.required],
        salle: [undefined, Validators.required],
        jurys: this.fb.array([])
      });
      this.addJury();

    }
  }

  createJuryForm(): FormGroup {
    return this.fb.group({
      cdcs: [[]],
      jures: [[]],
      echantillons: [[]],
    });
  }

  getJuryForm(jury: Jury): FormGroup {
    return this.fb.group({
      cdcs: [jury.cdcs],
      jures: [jury.jures],
      echantillons: [jury.echantillons],
    });
  }

  getField(name: string) {
    return this.formGroup.get(name);
  }

  submit() {
    if (this.formGroup.valid) {
      const commission = new Commission();

      const date = this.getField('date').value;
      const heureDebut = this.getField('heureDebut').value;
      const heureFin = this.getField('heureFin').value;

      commission.dateDebut = moment([date.year, date.month - 1, date.day, heureDebut.hour, heureDebut.minute, 0, 0]);
      commission.dateFin = moment([date.year, date.month - 1, date.day, heureFin.hour, heureFin.minute, 0, 0]);

      commission.animateur = this.getField('animateur').value;
      commission.salle = this.getField('salle').value;

      if (this.commissionIsOver) {
        commission.terminee = true;
      } else {
        commission.terminee = false;
      }

      commission.jurys = (this.getField('jurys') as FormArray).controls.map(
        (formGroup, index) => {
          const jury = new Jury();

          jury.ordre = index + 1;
          jury.jures = formGroup.get('jures').value;
          jury.cdcs = formGroup.get('cdcs').value;
          jury.echantillons = formGroup.get('echantillons').value.map((ech: EchantillonJury, index: number) => {
            ech.ordre = index;
            return ech;
          });

          return jury;
        }
      );

      if (this.edition) {
        commission.id = this.commission.id;
        this.commissionsService.modifyCommission(SessionContext.get('idOrganisme'), commission).subscribe(
          response => {
            this.toastr.success(
              this.translate.instant('page.commissions.edit.alert.modification.message', { numero: response.numero }),
              this.translate.instant('page.commissions.edit.alert.modification.title'),
              { timeOut: 10 * 1000 }
            );
            this.router.navigate(['../../planning'], { relativeTo: this.route });
          }
        );
      } else {
        this.commissionsService.createCommission(SessionContext.get('idOrganisme'), commission).subscribe(
          response => {
            this.toastr.success(
              this.translate.instant('page.commissions.edit.alert.creation.message', { numero: response.numero }),
              this.translate.instant('page.commissions.edit.alert.creation.title'),
              { timeOut: 10 * 1000 }
            );
            this.router.navigate(['../../planning'], { relativeTo: this.route });
          }
        );
      }

    } else {
      console.warn(this.formGroup);
    }
  }

  enregistrer() {
    if (this.formGroup.valid) {
      const commission = new Commission();

      const date = this.getField('date').value;
      const heureDebut = this.getField('heureDebut').value;
      const heureFin = this.getField('heureFin').value;

      commission.dateDebut = moment([date.year, date.month - 1, date.day, heureDebut.hour, heureDebut.minute, 0, 0]);
      commission.dateFin = moment([date.year, date.month - 1, date.day, heureFin.hour, heureFin.minute, 0, 0]);

      commission.animateur = this.getField('animateur').value;
      commission.salle = this.getField('salle').value;

      if (this.commissionIsOver) {
        commission.terminee = true;
      } else {
        commission.terminee = false;
      }

      commission.jurys = (this.getField('jurys') as FormArray).controls.map(
        (formGroup, index) => {
          const jury = new Jury();

          jury.ordre = index + 1;
          jury.jures = formGroup.get('jures').value;
          jury.cdcs = formGroup.get('cdcs').value;
          jury.echantillons = formGroup.get('echantillons').value.map((ech: EchantillonJury, index: number) => {
            ech.ordre = index;
            return ech;
          });

          return jury;
        }
      );

      if (this.edition) {

        commission.id = this.commission.id;
        this.commissionsService.modifyCommission(SessionContext.get('idOrganisme'), commission).subscribe(
          response => {
            this.toastr.success(
              this.translate.instant('page.commissions.edit.alert.modification.message', { numero: response.numero }),
              this.translate.instant('page.commissions.edit.alert.modification.title'),
              { timeOut: 10 * 1000 }
            );
            
            this.router.navigate(['main/controles/commissions/' + response.id + '/edit'])
            .then(() => {
              window.location.reload();
            });
          }
        );
      } else {
        this.commissionsService.createCommission(SessionContext.get('idOrganisme'), commission).subscribe(
          response => {
            this.toastr.success(
              this.translate.instant('page.commissions.edit.alert.creation.message', { numero: response.numero }),
              this.translate.instant('page.commissions.edit.alert.creation.title'),
              { timeOut: 10 * 1000 }
            );
            this.router.navigate(['main/controles/commissions/' + response.id + '/edit'])
            .then(() => {
              window.location.reload();
            });
          }
        );
      }

    } else {
      console.warn(this.formGroup);
    }
  }

  getStatutIcon(code: RefStatutJureCode) {
    switch (code) {
      case RefStatutJureCode.A_CONVOQUER: return 'envelope-open';
      case RefStatutJureCode.CONVOQUE: return 'envelope';
      case RefStatutJureCode.INSCRIT: return 'user-plus';
      case RefStatutJureCode.EXCUSE: return 'user-times';
      case RefStatutJureCode.DESINSCRIT: return 'user-minus';
      case RefStatutJureCode.PRESENT: return 'user-check';
      case RefStatutJureCode.ABSENT: return 'user-slash';
      default: Fail.never(code);
    }
  }

  // ####################################
  // ############  Jurys  ###############
  // ####################################

  addJury() {
    (this.formGroup.get('jurys') as FormArray).push(this.createJuryForm());
  }

  deleteJury(index: number) {
    const jurys = (this.formGroup.get('jurys') as FormArray);

    const jures = this.formGroup.get(`jurys.${index}.jures`).value;
    const cdcs = this.formGroup.get(`jurys.${index}.cdcs`).value;

    if (jurys.length > 1) {
      if (jures.length || cdcs.length) {
        this.modalsService.confirm(
          this.translate.instant(`page.commissions.edit.jurys.confirm_remove.message`),
          this.translate.instant(`page.commissions.edit.jurys.confirm_remove.title`),
          {
            cancelText: this.translate.instant(`label.annuler`),
            submitText: this.translate.instant(`label.valider`)
          }
        ).then(
          () => {
            jurys.removeAt(index);
          },
          () => { }
        );
      } else {
        jurys.removeAt(index);
      }
    }
  }

  moveJury(event: CdkDragDrop<FormGroup>) {
    const jurys = (this.formGroup.get('jurys') as FormArray);
    const jury = jurys.at(event.previousIndex);
    jurys.removeAt(event.previousIndex);
    jurys.insert(event.currentIndex, jury);
  }

  changeSatut(jure: JureJury, statut: StatutJure) {
    jure.statut = statut;
  }

  checkValue(event: any) {
    this.commissionIsOver = event;
  }

  // ####################################
  // #############  cdcs  ###############
  // ####################################

  addCdc(indexJury: number) {
    const modalRef = this.modalService.open(RechercheCdcsComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.idOrganisme = SessionContext.get('idOrganisme');
    modalRef.componentInstance.alreadySelectedCahiersIds = this.formGroup.get(`jurys.${indexJury}.cdcs`).value.map((cahier: Cahier) => cahier.id);

    modalRef.result.then(
      (cdcs: Cahier[]) => {
        cdcs.forEach(cdc => {
          this.formGroup.get(`jurys.${indexJury}.cdcs`).value.push(cdc);
        });
        this.formGroup.get(`jurys.${indexJury}.cdcs`).updateValueAndValidity();
      },
      error => { throw error; }
    );
  }

  removeCdc(indexJury: number, cdc: Cahier) {
    remove(this.formGroup.get(`jurys.${indexJury}.cdcs`).value, cdc);
    this.formGroup.get(`jurys.${indexJury}.cdcs`).updateValueAndValidity();
  }

  canDropCdc(drag: CdkDrag<Cahier>, drop: CdkDropList<Cahier[]>) {
    return drop.data.findIndex(cahier => cahier.id === drag.data.id) === -1;
  }

  moveCdc(event: CdkDragDrop<Cahier[]>) {
    remove(event.previousContainer.data, event.item.data);
    event.container.data.splice(event.currentIndex, 0, event.item.data);

    this.formGroup.get(`jurys.${event.previousContainer.id.replace(/\w+-/, '')}.cdcs`).updateValueAndValidity();
    this.formGroup.get(`jurys.${event.container.id.replace(/\w+-/, '')}.cdcs`).updateValueAndValidity();
  }

  // ####################################
  // ############  Jures  ###############
  // ####################################

  addJure(indexJury: number) {
    if (this.formGroup.get(`jurys.${indexJury}.cdcs`).value.length === 0) {
      throw new FunctionalError('COMMISSION_NO_CDC_SELECTED');
    }

    const modalRef = this.modalService.open(RechercheJureComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.idOrganisme = SessionContext.get('idOrganisme');
    modalRef.componentInstance.idsCdcs = this.formGroup.get(`jurys.${indexJury}.cdcs`).value.map((cdc: Cahier) => cdc.id);
    modalRef.componentInstance.alreadySelectedJuresIds = this.formGroup.get(`jurys.${indexJury}.jures`).value.map((jure: JureJury) => jure.jure.id);

    from(modalRef.result).subscribe(
      (jures: JureCdc[]) => {
        jures.forEach(jure => {
          const jureJury = Object.assign(new JureJury(), {
            jure,
            college: jure.colleges[0],
            statut: find(this.statutsJure, st => st.code === RefStatutJureCode.A_CONVOQUER)
          });
          this.formGroup.get(`jurys.${indexJury}.jures`).value.push(jureJury);
        });
        this.formGroup.get(`jurys.${indexJury}.jures`).updateValueAndValidity();
      }
    );
  }

  removeJure(indexJury: number, jure: JureJury) {
    remove(this.formGroup.get(`jurys.${indexJury}.jures`).value, jure);
    this.formGroup.get(`jurys.${indexJury}.jures`).updateValueAndValidity();
  }

  canDropJure(drag: CdkDrag<JureJury>, drop: CdkDropList<JureJury[]>) {
    return drop.data.findIndex(item => item.jure.id === drag.data.jure.id && item.college.id === drag.data.college.id) === -1;
  }

  moveJure(event: CdkDragDrop<JureJury[]>) {
    remove(event.previousContainer.data, event.item.data);
    event.container.data.splice(event.currentIndex, 0, event.item.data);

    this.formGroup.get(`jurys.${event.previousContainer.id.replace(/\w+-/, '')}.jures`).updateValueAndValidity();
    this.formGroup.get(`jurys.${event.container.id.replace(/\w+-/, '')}.jures`).updateValueAndValidity();
  }

  // ####################################
  // ############  Echs   ###############
  // ####################################

  affecterEchantillons() {
    const modal = this.modalService.open(AffectationEchantillonsComponent, { windowClass: 'app-modal-1000px', backdrop: 'static' });
    const modalComponent = modal.componentInstance as AffectationEchantillonsComponent;
    modalComponent.echantillonsListe = this.jurys.map((fg, index) => {
      const echantillons = fg.get('echantillons').value as EchantillonJury[];
      echantillons.forEach(echantillon => echantillon.numeroJury = index + 1);
      return echantillons;
    }).reduce((echantillons, part) => echantillons.concat(part), []);
    modalComponent.nbJurys = this.jurys.length;

    from(modal.result).subscribe(affectations => {
      this.jurys.forEach((fg, idx) => {
        let liste = fg.get('echantillons').value as EchantillonJury[];
        liste = liste.filter(e => e.leurre);

        liste = liste.concat(affectations[idx + 1] || []);

        fg.get('echantillons').setValue(liste);
      });
    });
  }

  addEchantillons(indexJury: number) {
    if (this.formGroup.get(`jurys.${indexJury}.cdcs`).value.length === 0) {
      throw new FunctionalError('COMMISSION_NO_CDC_SELECTED');
    }

    const modalRef = this.modalService.open(RechercheEchantillonsComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.idOrganisme = SessionContext.get('idOrganisme');
    modalRef.componentInstance.idsCdcs = this.formGroup.get(`jurys.${indexJury}.cdcs`).value.map((cdc: Cahier) => cdc.id);

    const alreadySelectedEchantillonsIds = this.jurys.reduce((ids, jury) => {
      const echs: EchantillonJury[] = jury.get('echantillons').value;
      return ids.concat(echs.filter(e => e.echantillon).reduce((eIds, ech) => eIds.concat(ech.echantillon.id), ([] as number[])));
    }, ([] as number[]));

    modalRef.componentInstance.alreadySelectedEchantillonsIds = alreadySelectedEchantillonsIds;

    from(modalRef.result).subscribe((res: { echantillons?: Echantillon[], leurre?: Leurre }) => {
      if (res.echantillons) {
        res.echantillons.forEach(echantillon => {
          const echJury = new EchantillonJury();
          echJury.numeroAnonymat = (++this.indexLastEchantillon).toString();
          echJury.echantillon = echantillon;

          this.formGroup.get(`jurys.${indexJury}.echantillons`).value.push(echJury);
        });
      }
      if (res.leurre) {
        const echJury = new EchantillonJury();
        echJury.numeroAnonymat = (++this.indexLastEchantillon).toString();
        echJury.leurre = res.leurre;

        this.formGroup.get(`jurys.${indexJury}.echantillons`).value.push(echJury);
      }

      this.formGroup.get(`jurys.${indexJury}.echantillons`).updateValueAndValidity();
    });
  }

  removeEchantillon(indexJury: number, ech: EchantillonJury) {
    remove(this.formGroup.get(`jurys.${indexJury}.echantillons`).value, ech);
    this.formGroup.get(`jurys.${indexJury}.echantillons`).updateValueAndValidity();
  }

  moveEchantillon(event: CdkDragDrop<EchantillonJury[]>) {
    remove(event.previousContainer.data, event.item.data);
    event.container.data.splice(event.currentIndex, 0, event.item.data);

    this.formGroup.get(`jurys.${event.previousContainer.id.replace(/\w+-/, '')}.echantillons`).updateValueAndValidity();
    this.formGroup.get(`jurys.${event.container.id.replace(/\w+-/, '')}.echantillons`).updateValueAndValidity();
  }

  // ####################################
  // ############   D&D   ###############
  // ####################################

  generateIdsList(prefix: string, nb: number) {
    return Array.from(Array(nb).keys()).map(i => prefix + '-' + i);
  }

  getCVI(echantillon: Echantillon): string {
    try {
      return echantillon.infosOperateur.find(infos => !!~infos.code.indexOf('CVI')).valeur;
    } catch (e) {
      return '';
    }
  }

  getRaisonSociale(echantillon: Echantillon): string {
    try {
      return echantillon.operateur.raisonSociale;
    } catch (e) {
      return '';
    }
  }

  public telechargerDocument() {
    const modalRef = this.modalService.open(TelechargerDocumentComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.commission = this.commission;
    modalRef.componentInstance.categorie = CategorieEnum.COMMISSION;
    modalRef.result.then(
      () => { },
      error => { throw error; }
    );
  }

  public envoyerDocumentsMail() {
    const modalRef = this.modalService.open(TelechargerDocumentComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.commission = this.commission;
    modalRef.componentInstance.categorie = CategorieEnum.COMMISSION;
    modalRef.componentInstance.commissionSendMail = true;
    modalRef.componentInstance.documentsIds =
      modalRef.result.then(
        () => { },
        error => { throw error; }
      );
  }
}
