import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { GenerationHabilitationsComponent } from './pages/generation-csv/generation-csv.component';
import { GestionTemplatesComponent } from './pages/gestion-templates/gestion-templates.component';
import { GenerationDeclarationsComponent } from './pages/generation-declarations/generation-declarations.component';

@Component({
  selector: 'app-edition',
  templateUrl: './edition.component.html',
  styleUrls: ['./edition.component.scss']
})
export class EditionComponent implements OnInit {
  static routes: Routes = [
    {
      path: '',
      data: {
        role: 'ORGANISME',
        domaines: ['VIN'],
        menu: { icon: 'copy', libelle: 'menu.edition' }
      },
      children: [{
        path: '',
        redirectTo: 'generation-documents',
        pathMatch: 'full'
      }, {
        path: 'generation-habilitations',
        component: GenerationHabilitationsComponent,
        data: { menu: { libelle: 'Export des habilitations' } }
      },
      {
        path: 'generation-declarations',
        component: GenerationDeclarationsComponent,
        data: { menu: { libelle: 'Export des déclarations' } }
      },
      {
        path: 'gestion-templates',
        component: GestionTemplatesComponent,
        data: { menu: { libelle: 'Gestion des templates' } }
      }
      ]
    }
  ];

  constructor() { }

  ngOnInit() {
  }
}
