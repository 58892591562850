import { JsonProperty, CustomConverter, JsonObject, Optional } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import { Produit } from '../../habilitation/models/produit.model';
import { RefMouvement } from './ref-mouvement.model';
import { Operateur } from '../../intervenant/models/operateur.model';

@JsonObject
export class HistoriqueMouvement {
    @JsonProperty('idMouvement', Number)
    idMouvement: number;

    @JsonProperty('idIntervenant', Number)
    idOperateur: number;

    @JsonProperty('codeMouvement', String)
    code: string;

    @JsonProperty('dateMouvement', String)
    @CustomConverter(DateConverter)
    date: moment.Moment;

    @JsonProperty('numeroLot', String)
    @Optional
    numeroLot: string;

    @JsonProperty('libelleCahier', String)
    libelleCahier: string;

    @JsonProperty('codeProduit', String)
    codeProduit: string;

    @JsonProperty('annee', Number)
    @Optional
    annee: number;

    @JsonProperty('volume', Number)
    volume: number;

    @JsonProperty('estOrigine', Boolean)
    estOrigine: boolean;

    @JsonProperty('numeroDeclaration', String)
    @Optional
    numeroDeclaration: string;

    @JsonProperty('numeroDossier', Number)
    @Optional
    numeroDossier: number;

    cvi = 0;
    estLu = false;
    operateur: Operateur = new Operateur();
    produit: Produit = new Produit();
    refMouvement: RefMouvement = new RefMouvement();
}
