import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NatureControle } from 'src/app/data/declaration/models/nature-controle.model';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { Animateur } from 'src/app/data/commission/models/animateur.model';
import { forkJoin } from 'rxjs';
import { AnimateursService } from 'src/app/data/commission/services/animateurs/animateurs.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { ManquementsService } from 'src/app/data/declaration/services/manquements/manquements.service';
import * as moment from 'moment';
import { ManquementResultat } from 'src/app/data/declaration/models/ManquementResultat.model';
import { PaginateResponseStream } from 'src/app/shared/services/pagination/pagination.service';
import { StatutManquement } from 'src/app/data/declaration/models/statut-manquement.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditManquementComponent } from './modals/edit-manquement/edit-manquement.component';

@Component({
  selector: 'app-search-manquements',
  templateUrl: './search-manquements.component.html',
  styleUrls: ['./search-manquements.component.scss']
})
export class SearchManquementsComponent implements OnInit {
  private idOrganisme: number;
  public pagination: PaginateResponseStream<ManquementResultat[]>;
  public refAgents: Animateur[];
  public refNatures: NatureControle[];
  public refStatuts: StatutManquement[];
  public formSearchManquements: FormGroup;
  get nature() { return this.formSearchManquements.get('nature'); }
  get statuts() { return this.formSearchManquements.get('statuts'); }
  get debut() { return this.formSearchManquements.get('debut'); }
  get fin() { return this.formSearchManquements.get('fin'); }
  get agents() { return this.formSearchManquements.get('agents'); }
  get numeroManquement() { return this.formSearchManquements.get('numeroManquement'); }
  get numeroControle() { return this.formSearchManquements.get('numeroControle'); }
  get numeroCVI() { return this.formSearchManquements.get('numeroCVI'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly referentielService: ReferentielService,
    private readonly animateursService: AnimateursService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly manquementsService: ManquementsService,
    private readonly modalService: NgbModal
  ) { }

  ngOnInit() {
    // Obtenir l'identifiant de l'organisme
    this.idOrganisme = SessionContext.get('idOrganisme');

    // Initialiser le formulaire de recherche
    this.formSearchManquements = this.fb.group({
      nature: [undefined, Validators.required],
      statuts: undefined,
      debut: undefined,
      fin: undefined,
      agents: undefined,
      numeroManquement: undefined,
      numeroControle: undefined,
      numeroCVI: undefined
    });

    // Charger les données de référence pour les listes déroulantes
    this.loadData();
  }

  /**
   * Charge la liste des natures et statuts de contrôle ainsi que
   * la liste des animateurs
   */
  private loadData(): void {
    forkJoin(
      this.referentielService.getReferentiel(),
      this.animateursService.getAnimateurs(this.idOrganisme)
    ).pipe(
      // Cacher le loader
      this.loaderService.operator()
    ).subscribe(([referentiel, animateurs]) =>
      // Stocker les résultats dans les variables de référence
      [this.refNatures, this.refStatuts, this.refAgents] = [
        referentiel.naturesControle,
        referentiel.statutsManquement,
        animateurs
      ]
    );
  }

  /** Valide le formulaire et lance la recherche des manquements */
  public onSubmit(): void {
    // Vérifier si le formulaire est valide
    if (this.formSearchManquements.valid) {
      this.manquementsService.getFilteredManquements(
        this.idOrganisme,
        this.nature.value.id,
        (this.statuts.value) ? this.statuts.value.map(statut => statut.id) : null,
        this.getMomentDate(this.debut.value),
        this.getMomentDate(this.fin.value),
        (this.agents.value) ? this.agents.value.map(agent => agent.id) : null,
        this.numeroManquement.value,
        this.numeroControle.value,
        this.numeroCVI.value
      ).subscribe(manquementsPagines =>
        this.pagination = manquementsPagines
      );
    }
  }

  public onEdit(manquement: ManquementResultat): void {
    const modal = this.modalService.open(EditManquementComponent, { backdrop: 'static', windowClass: 'app-modal-1000px' });
    modal.componentInstance.manquement = manquement;

    modal.result.then(() => { }, () => {});
  }

  /**
   * Convertit une date en provenance d'un datepicker vers un objet de type momentJS
   * @param date La date à convertir
   * @see moment.Moment
   * @returns moment.Moment ou null
   */
  private getMomentDate(date: {
    year: number,
    month: number,
    day: number
  }): moment.Moment {
    return (date) ? moment([date.year, date.month - 1, date.day, 0, 0, 0]) : null;
  }
}
