import { JsonProperty, JsonObject, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { Organisme } from '../../intervenant/models/organisme.model';
import { Produit } from '../../habilitation/models/produit.model';
import { Operateur } from '../../intervenant/models/operateur.model';
import { Conditionnement } from '../../declaration/models/conditionnement.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { DateConverter, DateTimeConverter } from 'src/app/core/services/mapper/converters';
import * as moment from 'moment';
import { OperateurInformationsDomaine } from '../../intervenant/models/operateur-informations-domaine.model';

@JsonObject
export class Echantillon {
  @JsonProperty('id', Number)
  id: number;

  @JsonProperty('codeProduit', String)
  codeProduit: string;

  produit: Produit;

  @JsonProperty('idOperateur', Number)
  idOperateur: number;
  infosOperateur: OperateurInformationsDomaine[] = [];
  operateur: Operateur;

  @JsonProperty('idMouvement', Number)
  @Optional
  idMouvement: number;

  @JsonProperty('idConditionement', Number)
  @CustomConverter(DataIdConverterFactory.build(Conditionnement, 'conditionnements'))
  conditionnement: Conditionnement;

  @JsonProperty('millesime', Number)
  @Optional
  millesime: number;

  @JsonProperty('idOrganisme', Number)
  @CustomConverter(DataIdConverterFactory.build(Organisme, 'organismes'))
  organisme: Organisme;

  @JsonProperty('datePrelevement', String)
  @CustomConverter(DateTimeConverter)
  @Optional
  datePrelevement: moment.Moment;

  @JsonProperty('degre', Number)
  @Optional
  degre: number;

  @JsonProperty('numero', String)
  @Optional
  numero: string;
}


