import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Jure } from '../../models/jure.model';
import { Observable, forkJoin, of } from 'rxjs';
import { CahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import { map, mergeMap, tap } from 'rxjs/operators';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { ReferencesService } from '../references/references.service';
import { CacheKey, Cache, ClearCache } from 'src/app/core/services/cache/cache.service';
import { JureCdc } from '../../models/jure-cdc.model';
import * as moment from 'moment';
import { JureSynthese } from '../../models/jure-synthese.model';
import { DateConverter } from 'src/app/core/services/mapper/converters';

@Injectable({
  providedIn: 'root'
})
export class JuresService {
 

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly cahiersService: CahiersService,
    private readonly refService: ReferencesService,
    private readonly operateursService: OperateursService
  ) { }

  @ClearCache
  clezrCache() { }

  @Cache()
  getJuresByCdcs(@CacheKey idOrganisme: number, @CacheKey idsCahiers: number[]): Observable<JureCdc[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/declaration/private/organismes/${idOrganisme}/jures/cahiers?cdc=${idsCahiers.join(',')}`),
      this.cahiersService.getCahiers(),
      this.refService.getReferences()
    ).pipe(
      map(([jures, cahiers, ref]) => this.mapper.deserializeArray(jures, JureCdc, Object.assign({ cahiers }, ref))),
      mergeMap(jures => {
        if (jures.length === 0) {
          return of([]);
        }
        return forkJoin(
          jures.map(jure => this.loadOperateursJure(jure))
        );
      })
    );
  }

  getJures(idOrganisme: number): Observable<Jure[]> {
    // return this.http.get<object[]>(`/api/declaration/private/organismes/${idOrganisme}/jures`);
    /*return this.http.get<object[]>(`/api/declaration/private/organismes/${idOrganisme}/jures`).pipe(
      map(jures => this.mapper.deserializeArray(jures, Jure))
    );*/

    return forkJoin(
      this.http.get<object[]>(`/api/declaration/private/organismes/${idOrganisme}/jures`),
      this.cahiersService.getCahiers(),
      this.refService.getReferences()
    ).pipe(
      map(([jures, cahiers, ref]) => this.mapper.deserializeArray(jures, Jure, Object.assign({ cahiers }, ref)))
    );
  }

  private loadOperateursJure(jure: JureCdc): Observable<JureCdc> {
    if (!jure.idsOperateurs || jure.idsOperateurs.length == 0) {
      return of(jure);
    }

    return forkJoin(jure.idsOperateurs.map(id => this.operateursService.getOperateur(id))).pipe(
      map(operateurs => {
        jure.operateurs = operateurs;
        return jure;
      })
    );
  }

  public getSyntheseJures(idOrganisme: number, dateDebut: moment.Moment, dateFin: moment.Moment): Observable<JureSynthese[]> {

    const dateConverter = new DateConverter();

    const debut = dateConverter.serialize(dateDebut);
    const fin = dateConverter.serialize(dateFin);

    let params = new HttpParams();

    params = (dateDebut) ? params.set('debut', dateConverter.serialize(dateDebut)) : params;
    params = (dateFin) ? params.set('fin', dateConverter.serialize(dateFin)) : params;

    return this.http.get<JureSynthese[]>(`/api/declaration/private/organismes/${idOrganisme}/jures-synthese`, { params }) ;
  }

  public getDetailJures(idOrganisme: number, idJure: number, dateDebut: moment.Moment, dateFin: moment.Moment): Observable<JureCdc[]> {

    const dateConverter = new DateConverter();

    const debut = dateConverter.serialize(dateDebut);
    const fin = dateConverter.serialize(dateFin);

    let params = new HttpParams();

    params = (dateDebut) ? params.set('debut', dateConverter.serialize(dateDebut)) : params;
    params = (dateFin) ? params.set('fin', dateConverter.serialize(dateFin)) : params;



    return forkJoin(
      this.http.get<JureCdc[]>(`/api/declaration/private/organismes/${idOrganisme}/jure-details/${idJure}`, { params }),
      this.cahiersService.getCahiers(),
      this.refService.getReferences()
    ).pipe(
      map(([jures, cahiers, ref]) => this.mapper.deserializeArray(jures, JureCdc, Object.assign({ cahiers }, ref)))
    );

  }
}
