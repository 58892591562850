import { Component, OnInit, Input } from '@angular/core';
import { Cahier } from 'src/app/data/habilitation/models/cahier.model';
import { AbstractRechercheItemComponent } from '../abstract-recherche-items.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { CahiersService } from 'src/app/data/commission/services/cahiers/cahiers.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';

@Component({
  selector: 'app-recherche-cdcs',
  templateUrl: './recherche-cdcs.component.html',
  styleUrls: ['./recherche-cdcs.component.scss']
})
export class RechercheCdcsComponent extends AbstractRechercheItemComponent<Cahier> implements OnInit {
  @Input() idOrganisme: number;
  @Input() alreadySelectedCahiersIds: number[];

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly cahiersService: CahiersService,
    private readonly loaderService: AdvBootstrapLoaderService
  ) {
    super(modal);
  }

  getData() {
    return this.cahiersService.getCahiers().pipe(
      this.loaderService.operator(),
      map(cahiers => {
        return cahiers.filter(cahier => this.alreadySelectedCahiersIds.indexOf(cahier.id) === -1);
      })
    );
  }
}
