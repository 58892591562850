import { Component, OnInit, ViewChild } from '@angular/core';
import { BaliseService } from 'src/app/data/edition/services/balise/balise.service';
import { DocumentService } from 'src/app/data/edition/services/document/document.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Document } from 'src/app/data/edition/models/document.model';
import { forkJoin, Observable } from 'rxjs';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { map } from 'rxjs/operators';
import { ReferencesEditionService } from 'src/app/data/edition/services/references-edition/references-edition.service';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { TypeBaliseEnum } from 'src/app/data/edition/models/enums/type-balise.enum.model';
import { TreeComponent, IActionMapping, ITreeOptions, TREE_ACTIONS } from 'angular-tree-component';

import { element } from 'protractor';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Balise } from 'src/app/data/edition/models/balise.model';
import { ReferencesEdition } from 'src/app/data/edition/models/references-edition.model';
import { RefCategorie } from 'src/app/data/edition/models/ref-categorie.model';
import { RefTypeBalise } from 'src/app/data/edition/models/ref-type-balise.model';
import { CategorieEnum } from 'src/app/data/edition/models/enums/categorie.enum.model';
import { AddDocumentComponent } from '../../modals/add-document/add-document.component';

@Component({
  selector: 'app-gestion-templates',
  // templateUrl: './gestion-templates.component.html',
  templateUrl: './gestion-templates.component.html',
  styleUrls: ['./gestion-templates.component.scss']
})
export class GestionTemplatesComponent implements OnInit {

  constructor(
    private readonly baliseService: BaliseService,
    private readonly documentService: DocumentService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly referencesEditionService: ReferencesEditionService,
    private readonly mapper: JsonMapperService,
    private readonly modalService: NgbModal,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService
  ) {
    this.showHelp = false;
  }
  @ViewChild('tree') treeComponent: TreeComponent;
  focusBaliseCode: string;

  resultBalises: Balise[];
  resultDocuments: Document[];
  references: ReferencesEdition;
  categories: RefCategorie[];
  typesBalises: RefTypeBalise[];
  selectedCategorie: RefCategorie;
  selectedDocument: Document;

  balises: Balise[] = [];
  allDocuments: Document[] = [];
  documents: Document[] = [];
  tinyMceConfig: any;
  tinymce: any;
  loaded = false;

  balisesCommission: Balise[];
  balisesControle: Balise[];
  balisesEchantillon: Balise[];

  nodeBaliseCommission: Balise = new Balise();
  nodeBaliseEchantillon: Balise = new Balise();
  nodeBaliseControle: Balise = new Balise();

  disableEditor = false;
  showHelp: boolean;

  firstLoad = false;

  nodes: any = [];

  actionMapping: IActionMapping = {
    mouse: {
      click: (tree, node, $event) => {
        if (tree.activeNodes.length > 0 && tree.activeNodes[0] === node) {
          TREE_ACTIONS.ACTIVATE(tree, node, $event);
        } else {
          TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event);
        }
      }
    }
  };

  options: ITreeOptions = {
    // allowDrag: (node) => node.isLeaf,
    // allowDrop: false,
    idField: 'id',
    rootId: 'id',
    actionMapping: this.actionMapping
  };

  changeCategorie() {
    this.documents = this.allDocuments.filter(d => d.refCategorie.id === this.selectedCategorie.id);

    switch (this.selectedCategorie.code) {
      case CategorieEnum.COMMISSION:
        this.nodes = this.mapBaliseToTreeComponentObjet(this.nodeBaliseCommission);
        break;
      case CategorieEnum.ECHANTILLON:
        this.nodes = this.mapBaliseToTreeComponentObjet(this.nodeBaliseEchantillon);

        break;
      case CategorieEnum.CONTROLE:
        this.nodes = this.mapBaliseToTreeComponentObjet(this.nodeBaliseControle);
        break;
    }

    this.selectedDocument = null;
    this.firstLoad = true;
    if (this.tinymce) { this.tinymce.setContent(''); }
  }

  ngOnInit() {

    this.selectedCategorie = null;
    forkJoin(
      this.referencesEditionService.getReferences(),
      this.baliseService.getBalises(),
      this.documentService.getDocuments(SessionContext.get('idOrganisme'))
    ).pipe(
      this.loaderService.operator()
    ).subscribe(
      ([ref, balises, documents]) => {
        this.balises = balises;
        this.allDocuments = documents;
        this.categories = ref.refCategorieList.filter(refCategorie => refCategorie.code != 
          CategorieEnum.ORGANISME && refCategorie.code != CategorieEnum.COMMUN);
        this.typesBalises = ref.refTypeBaliseList;
        this.balisesCommission = this.balises.filter(b => b.refCategorie.code === CategorieEnum.COMMISSION);
        this.balisesControle = this.balises.filter(b => b.refCategorie.code === CategorieEnum.CONTROLE);
        this.balisesEchantillon = this.balises.filter(b => b.refCategorie.code === CategorieEnum.ECHANTILLON);

        this.nodeBaliseCommission = this.remplirArbre(CategorieEnum.COMMISSION);
        this.nodeBaliseControle = this.remplirArbre(CategorieEnum.CONTROLE);
        this.nodeBaliseEchantillon = this.remplirArbre(CategorieEnum.ECHANTILLON);

        this.loaded = true;
      }
    );

    this.tinyMceConfig = {
      height: 600,
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
        'table'
      ],
      toolbar:
        'undo redo | sizeselect | fontselect | fontsizeselect | formatselect | bold italic backcolor | table \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat | help | image',
      table_header_type: 'sectionCells',
      fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
      setup: tinymce => {
        this.tinymce = tinymce;
        /*  tinymce.on('drop', (event) => {
            this.drop(event);
          });
          tinymce.on('dragover', false);
          tinymce.on('dragend', false);*/
      },
    };
  }

  remplirArbre(categorie: CategorieEnum): Balise {
    let baliseReturn: Balise = new Balise();
    let balisesCategorie: Balise[] = [];

    switch (categorie) {
      case CategorieEnum.COMMISSION:
        balisesCategorie = this.balisesCommission;
        break;
      case CategorieEnum.ECHANTILLON:
        balisesCategorie = this.balisesEchantillon;
        break;
      case CategorieEnum.CONTROLE:
        balisesCategorie = this.balisesControle;
        break;
    }

    balisesCategorie.forEach(balise => {
      if (!balise.idBaliseParent && balise.refTypeBalise.code === TypeBaliseEnum.NESTED) {
        baliseReturn = balise;
        baliseReturn.childrens = [];
        const childrens: Balise[] = balisesCategorie.filter(b => b.idBaliseParent === baliseReturn.id);
        baliseReturn.childrens = childrens;

        baliseReturn.childrens.forEach(premierNiveau => {
          if (premierNiveau.refTypeBalise.code === TypeBaliseEnum.NESTED) {
            premierNiveau.childrens = [];
            const childrens: Balise[] = balisesCategorie.filter(b => b.idBaliseParent === premierNiveau.id);
            premierNiveau.childrens = childrens;

            premierNiveau.childrens.forEach(deuxiemeNiveau => {
              if (deuxiemeNiveau.refTypeBalise.code === TypeBaliseEnum.NESTED) {
                deuxiemeNiveau.childrens = [];
                const childrens: Balise[] = balisesCategorie.filter(b => b.idBaliseParent === deuxiemeNiveau.id);
                deuxiemeNiveau.childrens = childrens;

                deuxiemeNiveau.childrens.forEach(troisiemeNiveau => {
                  if (troisiemeNiveau.refTypeBalise.code === TypeBaliseEnum.NESTED) {
                    troisiemeNiveau.childrens = [];
                    const childrens: Balise[] = balisesCategorie.filter(b => b.idBaliseParent === troisiemeNiveau.id);
                    troisiemeNiveau.childrens = childrens;
                  }
                });
              }
            });
          }
        });
      }
    });
    return baliseReturn;
  }

  mapBaliseToTreeComponentObjet(balise: Balise): any {
    const nodes = [];

    let balisesCategorie: Balise[] = [];

    switch (this.selectedCategorie.code) {
      case CategorieEnum.COMMISSION:
        balisesCategorie = this.balisesCommission;
        break;
      case CategorieEnum.ECHANTILLON:
        balisesCategorie = this.balisesEchantillon;
        break;
      case CategorieEnum.CONTROLE:
        balisesCategorie = this.balisesControle;
        break;
    }

    nodes.push({ id: balise.id, code: balise.code, name: balise.libelle, codeTypeBalise: balise.refTypeBalise.code, idBaliseParent: balise.idBaliseParent, children: [] });

    const childrenPremierNiveau = [];
    balise.childrens.forEach(c => {
      if (c.refTypeBalise.code === TypeBaliseEnum.NESTED) {
        childrenPremierNiveau.push({
          id: c.id, code: c.code, name: c.libelle, codeTypeBalise: c.refTypeBalise.code, idBaliseParent: balise.idBaliseParent, children: []
        });
      } else {
        childrenPremierNiveau.push({ id: c.id, code: c.code, name: c.libelle, codeTypeBalise: c.refTypeBalise.code, idBaliseParent: balise.idBaliseParent });
      }
      nodes[0].children = childrenPremierNiveau;
    });
    nodes[0].children.forEach(element => {
      if (element.codeTypeBalise === TypeBaliseEnum.NESTED) {
        const childrenDeuxiemeNiveau = [];
        const balisesByParent: Balise[] = balisesCategorie.filter(b => b.idBaliseParent === element.id);
        balisesByParent.forEach(bbp => {
          childrenDeuxiemeNiveau.push({
            id: bbp.id, code: bbp.code, name: bbp.libelle, codeTypeBalise: bbp.refTypeBalise.code, idBaliseParent: bbp.idBaliseParent, children: []
          });
        });
        element.children = childrenDeuxiemeNiveau;
      }

      if (element.codeTypeBalise === TypeBaliseEnum.NESTED) {
        element.children.forEach(element => {
          if (element.codeTypeBalise === TypeBaliseEnum.NESTED) {
            const childrenTroisiemeNiveau = [];
            const balisesByParent: Balise[] = balisesCategorie.filter(b => b.idBaliseParent === element.id);
            balisesByParent.forEach(bbp => {
              childrenTroisiemeNiveau.push({
                id: bbp.id, code: bbp.code, name: bbp.libelle, codeTypeBalise: bbp.refTypeBalise.code, idBaliseParent: bbp.idBaliseParent, children: []
              });
            });
            element.children = childrenTroisiemeNiveau;
          }
        });
      }
    });
    return nodes;
  }
  /*
    drag(ev) {
      ev.dataTransfer.setData("value", this.focusBaliseCode);
    }

    drop(ev) {
      var data = ev.dataTransfer.getData("value");
      this.tinymce.insertContent(data);
    }

    onFocus($event) {
      this.focusBaliseCode = this.getHtmlByBaliseCode($event.node.data.code);
    }*/

  onActivate($event) {
    this.tinymce.insertContent(this.getHtmlByBaliseCode($event.node.data.code));
  }

  getHtmlByBaliseCode(code: string): string {
    return this.balises.find(balise => balise.code === code).html;
  }

  addNewDocument() {
    const modalRef = this.modalService.open(AddDocumentComponent, { size: 'lg', backdrop: 'static' });
    modalRef.result.then((result) => {
      if (result) {
        const document: Document = new Document();
        document.libelle = result;
        document.refCategorie = this.selectedCategorie;
        document.isIndividuel = false;
        document.isMailable = false;
        document.template = '';
        document.idOrganisme = SessionContext.get('idOrganisme');
        this.documentService.createDocument(SessionContext.get('idOrganisme'), document).subscribe(
          response => {
            this.toastr.success(
              this.translate.instant(`page.edition.gestion-templates.creation-reussie`),
              this.translate.instant(`page.edition.gestion-templates.nouveau-document`),
              { timeOut: 10 * 1000 }
            );
            this.documentService.getDocumentsByCategorie(this.selectedCategorie.id, SessionContext.get('idOrganisme')).subscribe(documents => {
              this.documents = documents;
              this.selectedDocument = this.documents.find(d => d.id === response.id);
              if (this.firstLoad) {
                setTimeout(() => {
                  this.tinymce.setContent('');
                  this.firstLoad = false;
                }, 3000);
              } else {
                this.tinymce.setContent('');
              }
            });
          }
        );
      }
    }
    );
  }

  save() {
    if (this.selectedDocument.idOrganisme) {
      const document: Document = new Document();
      document.libelle = this.selectedDocument.libelle;
      document.id = this.selectedDocument.id;
      document.refCategorie = this.selectedCategorie;
      document.isIndividuel = this.selectedDocument.isIndividuel;
      document.isMailable = false;
      document.idOrganisme = SessionContext.get('idOrganisme');
      document.template = this.getContentEditor();
      document.isEntete = this.selectedDocument.isEntete;
      this.documentService.modifyDocument(SessionContext.get('idOrganisme'), document).subscribe(
        response => {
          this.toastr.success(
            this.translate.instant(`page.edition.gestion-templates.modification-reussie`),
            this.translate.instant(document.libelle),
            { timeOut: 10 * 1000 }
          );

          this.documentService.getDocumentsByCategorie(this.selectedCategorie.id, SessionContext.get('idOrganisme')).subscribe(documents => {
            this.documents = documents;
            this.selectedDocument = this.documents.find(d => d.id === document.id);
          });
        }
      );
    } else {
      const document: Document = new Document();
      document.libelle = this.selectedDocument.libelle;
      document.refCategorie = this.selectedCategorie;
      document.isIndividuel = this.selectedDocument.isIndividuel;
      document.isMailable = false;
      document.idOrganisme = SessionContext.get('idOrganisme');
      document.template = this.getContentEditor();
      this.documentService.createDocument(SessionContext.get('idOrganisme'), document).subscribe(
        response => {
          this.toastr.success(
            this.translate.instant(`page.edition.gestion-templates.creation-reussie`),
            this.translate.instant(`page.edition.gestion-templates.nouveau-document`),
            { timeOut: 10 * 1000 }
          );
          this.documentService.getDocumentsByCategorie(this.selectedCategorie.id, SessionContext.get('idOrganisme')).subscribe(documents => this.documents = documents);
          this.tinymce.setContent('');
        }
      );
    }
  }

  addModelePersonnalise() {
    const modalRef = this.modalService.open(AddDocumentComponent, { size: 'lg', backdrop: 'static' });
    modalRef.result.then((result) => {
      const document: Document = new Document();
      document.libelle = result;
      document.refCategorie = this.selectedDocument.refCategorie;
      document.isIndividuel = this.selectedDocument.isIndividuel;
      document.isMailable = this.selectedDocument.isMailable;
      document.template = this.selectedDocument.template;
      document.idOrganisme = SessionContext.get('idOrganisme');
      this.documentService.createDocument(SessionContext.get('idOrganisme'), document).subscribe(
        response => {
          this.toastr.success(
            this.translate.instant(`page.edition.gestion-templates.creation-modele-personnalise-reussie`),
            this.translate.instant(document.libelle),
            { timeOut: 10 * 1000 }
          );
          this.documentService.getDocumentsByCategorie(this.selectedCategorie.id, SessionContext.get('idOrganisme')).subscribe(documents => {
            this.documents = documents;
            this.tinymce.setContent(document.template);
            this.selectedDocument = this.documents.find(d => d.id === response.id);
            this.disableEditor = !this.selectedDocument.idOrganisme;
          });
        }
      );
    });
  }

  getContentEditor() {
    return this.tinymce.getContent();
  }

  changeDocument() {
    this.disableEditor = !this.selectedDocument.idOrganisme;
    if (this.firstLoad) {
      setTimeout(() => {
        this.tinymce.setContent(this.selectedDocument.template);
        this.firstLoad = false;
      }, 3000);
    } else {
      this.tinymce.setContent(this.selectedDocument.template);
    }
  }
}
