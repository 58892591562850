import { NgModule, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { TranslateService } from "@ngx-translate/core";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthentificationModule } from "./authentification/authentification.module";
import { CoreModule } from "./core/core.module";
import { MainModule } from "./main/main.module";
import { NgbDateMomentParserFormatter } from "./shared/services/ngb-date-parser-Formatter/ngb-date-parser-formatter.service";
import { NgxEchartsModule } from "ngx-echarts";
export class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super("");
  }

  toString() {
    return this.service.currentLang;
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    MainModule,
    AuthentificationModule,
    AppRoutingModule,
    NgxEchartsModule,
  ],
  providers: [
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateMomentParserFormatter,
      deps: [TranslateService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
