import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DocumentService } from 'src/app/data/edition/services/document/document.service';
import { Document } from 'src/app/data/edition/models/document.model';
import { forkJoin, Observable } from 'rxjs';
import { ReferencesEditionService } from 'src/app/data/edition/services/references-edition/references-edition.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AbstractRechercheItemComponent } from 'src/app/main/pages/commissions/pages/edit-commission/modals/abstract-recherche-items.component';
import { Controle } from 'src/app/data/declaration/models/controle.model';
import { Echantillon } from 'src/app/data/commission/models/echantillon.model';
import { Commission } from 'src/app/data/commission/models/commission.model';
import { Jure } from 'src/app/data/commission/models/jure.model';
import { SelectJuresComponent } from '../../../commissions/pages/edit-commission/modals/select-jures/select-jures.component';
import { MapperEditionService } from 'src/app/data/edition/services/mapper-edition/mapper-edition.service';
import { CommissionEdition } from 'src/app/data/edition/models/commission-edition.model';
import { ControleEdition } from 'src/app/data/edition/models/controle-edition.model';
import { EchantillonEdition } from 'src/app/data/edition/models/echantillon-edition.model';
import { EditionService } from 'src/app/data/edition/services/edition.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { saveAs } from 'file-saver';
import { faWindowRestore } from '@fortawesome/free-regular-svg-icons';
import { Operateur } from 'src/app/data/intervenant/models/operateur.model';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { CategorieEnum } from 'src/app/data/edition/models/enums/categorie.enum.model';
import { ReferencesEdition } from 'src/app/data/edition/models/references-edition.model';
import { Fichier } from 'src/app/data/edition/models/fichier.model';
import { map } from 'rxjs/operators';
import { async } from '@angular/core/testing';
import { FileDownloadTools } from 'src/app/shared/file-download-tools/file-download-tools';
import { OrganismesService } from 'src/app/data/intervenant/services/organismes/organismes.service';
import { OrganismeEdition } from 'src/app/data/edition/models/organisme-edition.model';
import { Organisme } from 'src/app/data/intervenant/models/organisme.model';


@Component({
  selector: 'app-telecharger-document',
  templateUrl: './telecharger-document.component.html',
  styleUrls: ['./telecharger-document.component.scss']
})
export class TelechargerDocumentComponent extends AbstractRechercheItemComponent<Document> implements OnInit {

  constructor(
    private readonly fb: FormBuilder,
    public readonly modal: NgbActiveModal,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly documentService: DocumentService,
    private readonly referencesEditionService: ReferencesEditionService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalService: NgbModal,
    private readonly editionService: EditionService,
    private readonly operateursService: OperateursService,
    private readonly organismesService: OrganismesService
  ) {
    super(modal);
  }
  @ViewChild('form') form: NgForm;
  formGroup: FormGroup;
  documents: Document[];
  referencesEdition: ReferencesEdition;
  @Input() categorie: CategorieEnum;
  @Input() controle: Controle;
  @Input() echantillon: Echantillon;
  @Input() commission: Commission;
  @Input() commissionSendMail: boolean;
  noDocuments: boolean;

  getData(): Observable<Document[]> {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      document: [undefined, Validators.required],
    });

    setTimeout(() => this.loadData());
  }

  loadData() {
    this.referencesEditionService.getReferences()
      .pipe(
        this.loaderService.operator()
      ).subscribe(ref => {
        this.referencesEdition = ref;
        const cat = this.referencesEdition.refCategorieList.find(c => c.code === this.categorie);
        this.documentService.getDocumentsByCategorie(cat.id, SessionContext.get('idOrganisme')).subscribe(documents => {
          this.documents = documents;
        });
      });

  }

  isDocumentsCheck(): void {
    const documents: Document[] = this.getDocumentsChecked(this.documents);
    if(documents && documents.length >0) {
      this.noDocuments = false;
    }
  }

  /*
   si on est dans le cas où il s'agit d'un envoi par mail, on envoie
   la commission, la catégorie et les documents sélectionnés à la modale
   suivante pour sélectionner les jurés
   */
  public next() {
    const documents: Document[] = this.getDocumentsChecked(this.documents);

    if (!documents || documents.length == 0) {
      this.noDocuments = true;
      return;
    }
    this.onClose();
    const modalRef = this.modalService.open(SelectJuresComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.commission = this.commission;
    modalRef.componentInstance.categorie = CategorieEnum.COMMISSION;
    modalRef.componentInstance.documents = this.getDocumentsChecked(this.documents);
    modalRef.result.then(
      () => { },
      error => { throw error; }
    );
  }

  public onClose(): void { this.modal.dismiss(); }

  private getDocumentsChecked(documents: Document[]): Document[] {
    const result: Document[] = [];
    documents.forEach(d => {
      if (d.checked) {
        result.push(d);
      }
    });
    return result;
  }

  public onSubmit() {
    const documents: Document[] = this.getDocumentsChecked(this.documents);

    if (!documents || documents.length == 0) {
      this.noDocuments = true;
      return;
    }

    const idDocumentList: number[] = [];
    documents.forEach(doc => idDocumentList.push(doc.id));
    this.onClose();
              
    switch (this.categorie) {
      case CategorieEnum.COMMISSION:
        // const commissionEdition: CommissionEdition = this.mapperEditionService.convertCommissionToCommissionEdition(this.commission);
        this.editionService.getCommissionEdition(SessionContext.get('idOrganisme'), this.commission.id)
          .subscribe(async commissionEdition => {
            if (commissionEdition.idResponsable) {

              const organismeEdition: OrganismeEdition = await this.editionService.getOrganismeEdition(SessionContext.get("idOrganisme")).toPromise();
              const responsable = await this.operateursService.getOperateur(commissionEdition.idResponsable).toPromise();
              commissionEdition.organisme = organismeEdition;
              commissionEdition.responsable = responsable.nom || responsable.prenom ? responsable.prenom.concat(' ').concat(responsable.nom) : 'N/A';
              /*
              this.operateursService.getOperateur(commissionEdition.idResponsable)
                .subscribe(responsable => {
                  commissionEdition.responsable = responsable.nom || responsable.prenom ? responsable.prenom.concat(' ').concat(responsable.nom) : 'N/A';
                });
                */
            } else {
              commissionEdition.responsable = 'N/A';
            }
            if (commissionEdition.echantillons.length > 0) {

              for (let i = 0; i < commissionEdition.echantillons.length; i++) {
                const operateur = await this.operateursService.getOperateur(commissionEdition.echantillons[i].operateur.id).toPromise();
                commissionEdition.echantillons[i].operateur.raisonSociale = operateur.raisonSociale ? operateur.raisonSociale : 'N/A';
                commissionEdition.echantillons[i].operateur.telephone = operateur.telephoneFixe ? operateur.telephoneFixe : 'N/A';
                commissionEdition.echantillons[i].operateur.portable = operateur.telephoneMobile ? operateur.telephoneMobile : 'N/A';
                commissionEdition.echantillons[i].operateur.fax = operateur.fax ? operateur.fax : 'N/A';
                commissionEdition.echantillons[i].operateur.ville = operateur.ville ? operateur.ville : 'N/A';
                commissionEdition.echantillons[i].operateur.codePostal = operateur.codePostal ? operateur.codePostal : 'N/A';
              }

              this.editionService.genererPdfCommission(SessionContext.get('idOrganisme'), commissionEdition, idDocumentList)
                .subscribe((fichiers: Fichier[]): void => {
                  fichiers.forEach(fichier => {
                    const fileURL = URL.createObjectURL(FileDownloadTools.b64ToBlob(fichier.base64, 'application/pdf'));
                    window.open(fileURL);
                  });
                }), () => { },
                () => { };
            } else {

              commissionEdition.echantillons.forEach(e => {
                e.operateur.raisonSociale = 'N/A';
                e.operateur.telephone = 'N/A';
                e.operateur.portable = 'N/A';
                e.operateur.fax = 'N/A';
                e.operateur.ville = 'N/A';
                e.operateur.codePostal = 'N/A';
              });

              this.editionService.genererPdfCommission(SessionContext.get('idOrganisme'), commissionEdition, idDocumentList)
                .subscribe((fichiers: Fichier[]): void => {
                  fichiers.forEach(fichier => {
                    const fileURL = URL.createObjectURL(FileDownloadTools.b64ToBlob(fichier.base64, 'application/pdf'));
                    window.open(fileURL);
                  });
                }), () => { },
                () => { };
            }

          });
        break;
      case CategorieEnum.CONTROLE:
        this.editionService.getControleEdition(SessionContext.get('idOrganisme'), this.controle.id)
          .subscribe(async controleEdition => {
            const organismeEdition: OrganismeEdition = await this.editionService.getOrganismeEdition(SessionContext.get("idOrganisme")).toPromise();
              controleEdition.organisme = organismeEdition;
            if (controleEdition.operateur.id) {
              forkJoin(
                this.operateursService.getOperateur(controleEdition.operateur.id),
                this.operateursService.getInformationsDomaine(controleEdition.operateur.id),
              ).pipe(
                map(([operateur, infosDomaine]) => {
                  controleEdition.operateur.raisonSociale = operateur.raisonSociale ? operateur.raisonSociale : 'N/A';
                  controleEdition.operateur.telephone = operateur.telephoneFixe ? operateur.telephoneFixe : 'N/A';
                  controleEdition.operateur.portable = operateur.telephoneMobile ? operateur.telephoneMobile : 'N/A';
                  controleEdition.operateur.fax = operateur.fax ? operateur.fax : 'N/A';
                  controleEdition.operateur.adresse = operateur.adresse ? operateur.adresse : 'N/A';
                  controleEdition.operateur.date = 'N/A';
                  controleEdition.operateur.ville = operateur.ville ? operateur.ville : 'N/A';
                  controleEdition.operateur.siret = operateur.siret ? operateur.siret : 'N/A';
                  controleEdition.operateur.codePostal = operateur.codePostal ? operateur.codePostal : 'N/A';

                  controleEdition.operateur.cvi = infosDomaine.find(i => i.code === 'VIN_CVI').valeur ? infosDomaine.find(i => i.code === 'VIN_CVI').valeur : 'N/A';

                  controleEdition.manquements.forEach(manquement => {
                    if (manquement.idAuteurConstat) {
                      this.operateursService.getOperateur(manquement.idAuteurConstat)
                        .subscribe(operateurManquement => {
                          manquement.auteurConstat = operateurManquement.nom || operateurManquement.prenom ? operateurManquement.prenom.concat(' ').concat(operateurManquement.nom) : 'N/A';
                          manquement.fonctionAuteur = operateurManquement.structureJuridique ? operateurManquement.structureJuridique : 'N/A';
                        });
                    } else {
                      manquement.auteurConstat = 'N/A';
                      manquement.fonctionAuteur = 'N/A';
                    }
                  });
                })
              ).subscribe(() => {
                this.editionService.genererPdfControle(SessionContext.get('idOrganisme'), controleEdition, idDocumentList)
                  .subscribe((fichiers: Fichier[]): void => {
                    fichiers.forEach(fichier => {
                      const fileURL = URL.createObjectURL(FileDownloadTools.b64ToBlob(fichier.base64, 'application/pdf'));
                      window.open(fileURL);
                    });
                  }), () => { },
                  () => { };
              });
            } else {
              controleEdition.operateur.raisonSociale = 'N/A';
              controleEdition.operateur.telephone = 'N/A';
              controleEdition.operateur.portable = 'N/A';
              controleEdition.operateur.fax = 'N/A';
              controleEdition.operateur.adresse = 'N/A';
              controleEdition.operateur.date = 'N/A';
              controleEdition.operateur.ville = 'N/A';
              controleEdition.operateur.siret = 'N/A';
              controleEdition.operateur.codePostal = 'N/A';
              controleEdition.operateur.cvi = 'N/A';

              for (let i = 0; i < controleEdition.manquements.length; i++) {
                if (controleEdition.manquements[i].idAuteurConstat) {
                  const operateurManquement = await this.operateursService.getOperateur(controleEdition.manquements[i].idAuteurConstat).toPromise();

                  controleEdition.manquements[i].auteurConstat = operateurManquement.nom || operateurManquement.prenom ? operateurManquement.prenom.concat(' ').concat(operateurManquement.nom) : 'N/A';
                  controleEdition.manquements[i].fonctionAuteur = operateurManquement.structureJuridique ? operateurManquement.structureJuridique : 'N/A';

                } else {
                  controleEdition.manquements[i].auteurConstat = 'N/A';
                  controleEdition.manquements[i].fonctionAuteur = 'N/A';
                }
              }

              this.editionService.genererPdfControle(SessionContext.get('idOrganisme'), controleEdition, idDocumentList)
                .subscribe((fichiers: Fichier[]): void => {
                  fichiers.forEach(fichier => {
                    const fileURL = URL.createObjectURL(FileDownloadTools.b64ToBlob(fichier.base64, 'application/pdf'));
                    window.open(fileURL);
                  });
                }), () => { },
                () => { };
            }
          });
        break;

      case CategorieEnum.ECHANTILLON:
        this.editionService.getEchantillonEdition(SessionContext.get('idOrganisme'), this.echantillon.id)
          .subscribe(async echantillonEdition => {
            const organismeEdition: OrganismeEdition = await this.editionService.getOrganismeEdition(SessionContext.get("idOrganisme")).toPromise();
            echantillonEdition.organisme = organismeEdition;
            forkJoin(
              this.operateursService.getOperateur(echantillonEdition.operateur.id),
              this.operateursService.getInformationsDomaine(echantillonEdition.operateur.id)
            ).pipe(
              map(([operateur, infosDomaine]) => {
                echantillonEdition.operateur.raisonSociale = operateur.raisonSociale ? operateur.raisonSociale : 'N/A';
                echantillonEdition.operateur.telephone = operateur.telephoneFixe ? operateur.telephoneFixe : 'N/A';
                echantillonEdition.operateur.portable = operateur.telephoneMobile ? operateur.telephoneMobile : 'N/A';
                echantillonEdition.operateur.fax = operateur.fax ? operateur.fax : 'N/A';
                echantillonEdition.operateur.adresse = operateur.adresse ? operateur.adresse : 'N/A';
                echantillonEdition.operateur.date = 'N/A';
                echantillonEdition.operateur.ville = operateur.ville ? operateur.ville : 'N/A';
                echantillonEdition.operateur.siret = operateur.siret ? operateur.siret : 'N/A';
                echantillonEdition.operateur.codePostal = operateur.codePostal ? operateur.codePostal : 'N/A';

                echantillonEdition.operateur.cvi = infosDomaine.find(i => i.code === 'VIN_CVI').valeur ? infosDomaine.find(i => i.code === 'VIN_CVI').valeur : 'N/A';
              })
            ).subscribe(() => {
              this.editionService.genererPdfEchantillon(SessionContext.get('idOrganisme'), echantillonEdition, idDocumentList)
                .subscribe((fichiers: Fichier[]): void => {
                  fichiers.forEach(fichier => {
                    const fileURL = URL.createObjectURL(FileDownloadTools.b64ToBlob(fichier.base64, 'application/pdf'));
                    window.open(fileURL);
                  });
                }), () => { },
                () => { };
            });
          });
        break;
    }
  }
}
