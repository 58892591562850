import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportToCsv } from 'export-to-csv';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { Campagne } from 'src/app/data/declaration/models/campagne.model';
import { DeclarationExport } from 'src/app/data/declaration/models/declaration-export.model';
import { RefMouvement } from 'src/app/data/declaration/models/ref-mouvement.model';
import { DeclarationsService } from 'src/app/data/declaration/services/declarations/declarations.service';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { Cahier } from 'src/app/data/habilitation/models/cahier.model';
import { CahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';

@Component({
  selector: 'app-generation-declarations',
  templateUrl: './generation-declarations.component.html',
  styleUrls: ['./generation-declarations.component.scss']
})
export class GenerationDeclarationsComponent implements OnInit {


  public refAnnees: Campagne[] = [];
  public refCahiers: Cahier[];
  public refMouvements: RefMouvement[];
  public formDeclaration: FormGroup;

  public exportDeclarations: DeclarationExport[]; 

  get cdcs() { return this.formDeclaration.get('cdcs'); }
  get mvts() { return this.formDeclaration.get('mvts'); }
  get annees() { return this.formDeclaration.get('annees'); }

  constructor( 
    private readonly fb: FormBuilder,
    private readonly cahiersService: CahiersService,
    private readonly declarationService: DeclarationsService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly referentielService: ReferentielService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
    ) { }

  ngOnInit() {
    this.loadData();
  }

   /**
   * Charge la pression dans le cas d'une modification et charge toutes les données
   * nécessaires nécessaires au remplissage des listes déroulantes
   */
   private loadData(): void {
    forkJoin(
      this.cahiersService.getCahiers(),
      this.referentielService.getReferentiel()
    ).pipe(
      this.loaderService.operator()
    ).subscribe(([ cahiers,  referentiel]) => {
      this.refAnnees = referentiel.campagnes.sort((a,b) => { return b.annee - a.annee; });
      this.refCahiers = cahiers;
      this.refMouvements = referentiel.refMouvements;
      this.initForm();
    });
  }

   /** Initialise le formulaire */
   private initForm(): void {
    this.formDeclaration = this.fb.group({
      cdcs: [ undefined, [Validators.required, Validators.maxLength(1)]],
      mvts: [ undefined, [Validators.required]],
      annees: [ undefined, [Validators.required]]
    });   
  }

  public onSubmit(): void {

  if (this.formDeclaration.valid) {

      const expCamp = [];
      const expMvt = [];
      const expCdc = [];
      const expParam = [];

      
      if(this.annees.value != undefined){
        this.annees.value.forEach((element) => {
              expCamp.push(element.id);
        });
      }
      else{
        this.refAnnees.forEach((element) => {
          expCamp.push(element.id);
            });
      }

      if(this.mvts.value != undefined){
        this.mvts.value.forEach((element) => {
        expMvt.push(element.id);
            });
      }
      else{
        this.refMouvements.forEach((element) => {
          expMvt.push(element.id);
            });
      }

      

      if(this.cdcs.value != undefined){
        this.cdcs.value.forEach((element) => {
        expCdc.push(element.id);
            });
      }
      else{
        this.refCahiers.forEach((element) => {
          expCdc.push(element.id);
            });
      }

      

      expParam.push(expCamp);
      expParam.push(expMvt);
      expParam.push(expCdc);

    forkJoin(     
         
        this.declarationService.getDeclarationExport(expParam)
    ).subscribe(([declaration]) => {
      this.exportDeclarations = declaration;

      this.exporterDeclarations();
        });

  }
      
    }

    exporterDeclarations(): void {
      const data = [];
      this.exportDeclarations.forEach(exp => {
  
        data.push({
          campagne: exp.campagne,
          raisonSociale: exp.raisonSociale,
          numeroCVI: exp.numeroCVI,
          numeroDossier: exp.numeroDossier,
          dateMvt: exp.dateMvt.format('DD/MM/YYYY'),
          logement: exp.logement.replace(';',','),
          numero: exp.numero.replace(';',','),
          cahierDesCharges: exp.cahierDesCharges,
          produit: exp.produit,
          couleur: exp.couleur,
          mouvement: exp.mouvement,
          volume:  +exp.volume.toFixed(2),

        });
      });
  
  
      const options = {
        filename: 'EXPORT_DECLARATION'.concat('_').concat(moment().format('YYYYMMDD')),
        fieldSeparator: ';',
        quoteStrings: '',
        decimalSeparator: ',',
        showLabels: true,
        showTitle: false,
        title: 'EXPORT_DECLARATION'.concat('_').concat(moment().format('YYYYMMDD')),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: [
          'Campagne',
          'Raison Sociale',
          'N° CVI',
          'N° Dossier',
          'Date',
          'Logement',
          'N° Lot',
          'Cahier des charges',
          'Produit',
          'Couleur',
          'Déclaration (Type)',
          'Volume (Hl)'
        ]
      };
  
      const csvExporter = new ExportToCsv(options);
  
      csvExporter.generateCsv(data);
  
    }

  

}
