<div class="content">
  <div class="content-panel">
    <h2 class="section-title" translate>page.operateurs.informations.entreprise.title</h2>
    <div class="flex" *ngIf="operateur && infosDomaine">
      <div>
        <div *ngIf="operateur.structureJuridique"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.structureJuridique</label>{{operateur.structureJuridique}}</div>
        <div *ngIf="operateur.raisonSociale"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.raisonSociale</label>{{operateur.raisonSociale}}</div>
        <div *ngIf="operateur.siret"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.siret</label>{{operateur.siret}}</div>

        <div *ngIf="operateur.tvaIntracommunautaire"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.tvaIntracommunautaire</label>{{operateur.tvaIntracommunautaire}}</div>
        <div *ngFor="let info of infosDomaine"><label class="entreprise-label">{{info.libelle}}</label>{{info.valeur}}</div>
      </div>
      <div>
        <div *ngIf="operateur.nom"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.nom</label>{{operateur.nom}}</div>
        <div *ngIf="operateur.prenom"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.prenom</label>{{operateur.prenom}}</div>
        <div *ngIf="operateur.adresse"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.adresse</label>{{operateur.adresse}}</div>
        <div *ngIf="operateur.codePostal"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.codePostal</label>{{operateur.codePostal}}</div>
        <div *ngIf="operateur.ville"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.ville</label>{{operateur.ville}}</div>
        <div *ngIf="operateur.cedex"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.cedex</label>{{operateur.cedex}}</div>
        <div *ngIf="operateur.pays"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.pays</label>{{operateur.pays?.libelle}}</div>
        <div *ngIf="operateur.email"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.email</label>{{operateur.email}}</div>
        <div *ngIf="operateur.telephoneFixe"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.telephoneFixe</label>{{operateur.telephoneFixe | phoneFormat}}</div>
        <div *ngIf="operateur.telephoneMobile"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.telephoneMobile</label>{{operateur.telephoneMobile | phoneFormat}}</div>
        <div *ngIf="operateur.fax"><label class="entreprise-label" translate>page.operateurs.informations.entreprise.fax</label>{{operateur.fax| phoneFormat}}</div>
      </div>
    </div>
  </div>

  <div class="content-panel">
    <h2 class="section-title" translate>page.operateurs.informations.sites.title</h2>
    <div class="liste-sites-container">
      <table class="table liste-sites" *ngIf="sites">
        <thead>
          <tr>
            <th>#</th>
            <th translate>page.operateurs.informations.sites.table.header.adresse</th>
            <th translate>page.operateurs.informations.sites.table.header.responsable</th>
            <th translate>page.operateurs.informations.sites.table.header.activites</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let site of sites; let idx = index">
            <ng-container *ngIf="site.__form === undefined">
              <td>{{idx+1}}</td>
              <td>{{site.adresse}}</td>
              <td>{{site.responsable}}</td>
              <td>
                <div *ngFor="let activite of site.siteActivites">
                  {{activite.libelle}}
                </div>
              </td>
              <td>
                <fa-icon class="clickable-icon" icon="edit" (click)="editSite(site)"></fa-icon>
                &nbsp;
                <fa-icon class="clickable-icon" icon="trash" (click)="deleteSite(site)"></fa-icon>
              </td>
            </ng-container>
            <ng-container *ngIf="site.__form">
                <td>
                  {{idx+1}}
                  <form [formGroup]="site.__form" #form="ngForm" (ngSubmit)="saveSite(site)" ></form>
                </td>
                <td>
                  <form [formGroup]="getFormGroup(site, 'adresse')">
                    <input id="adresse_{{idx}}" formControlName="adresse"
                    [ngClass]="{'is-invalid': form.submitted && getFormControl(site, 'adresse').invalid}" class="form-control" />
                  </form>
                </td>
                <td>
                  <form [formGroup]="getFormGroup(site, 'responsable')">
                    <input id="responsable_{{idx}}" formControlName="responsable"
                    [ngClass]="{'is-invalid': form.submitted && getFormControl(site, 'responsable').invalid}" class="form-control" />
                  </form>
                </td>
                <td>
                  <form [formGroup]="getFormGroup(site, 'activites')">
                    <app-multiselect bindLabel="libelle" controlName="activites" [items]="sitesActivites" [ngClass]="{'is-invalid': form.submitted && getFormControl(site, 'activites').invalid}"></app-multiselect>
                  </form>
                </td>
                <td>
                  <fa-icon class="clickable-icon" icon="save" (click)="form.onSubmit()"></fa-icon>
                  &nbsp;
                  <fa-icon class="clickable-icon" [icon]="site.__new ? 'trash' : 'undo'" (click)="cancelEditSite(site, idx)"></fa-icon>
                </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="actions-bar" *ngIf="sites">
      <button type="button" (click)="ajouterSite()" class="btn btn-primary" translate>page.operateurs.informations.sites.table.ajouter</button>
    </div>
  </div>
  <div style="height:30vh;opacity:0">
    <!-- create some empty space for dropdown display -->
  </div>
</div>
