import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { ReferencesEditionService } from '../references-edition/references-edition.service';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Document } from '../../models/document.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly refEditionService: ReferencesEditionService
  ) {

  }

  getDocumentsByCategorie(idCategorie: number, idOrganisme: number): Observable<Document[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/edition/private/organismes/${idOrganisme}/categories/${idCategorie}/documents`),
      // this.http.get<object[]>(`/assets/mocks/documents.json`),
      this.refEditionService.getReferences()
    ).pipe(
      map(([documents, ref]) => this.mapper.deserializeArray(documents,
        Document, Object.assign({}, ref)))
    );
  }


  getDocuments(idOrganisme: number): Observable<Document[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/edition/private/organismes/${idOrganisme}/documents`),
      // this.http.get<object[]>(`/assets/mocks/documents.json`),
      this.refEditionService.getReferences()
    ).pipe(
      map(([documents, ref]) => this.mapper.deserializeArray(documents,
        Document, Object.assign({}, ref)))
    );
  }

  createDocument(idOrganisme: number, document: Document): Observable<{ id: number, libelle: string }> {
    return this.http.post(`/api/edition/private/organismes/${idOrganisme}/documents`, this.mapper.serialize(document), { observe: 'response', responseType: 'text' }).pipe(
      map(response => {
        return {
          id: parseInt(response.headers.get('location').split('/').pop(), 10),
          libelle: response.body
        };
      })
    );
  }

  modifyDocument(idOrganisme: number, document: Document): Observable<void> {
    return this.http.put(`/api/edition/private/organismes/${idOrganisme}/documents/${document.id}`, this.mapper.serialize(document), { observe: 'response', responseType: 'text' })
      .pipe(map(() => { }));
  }
}
