import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';
import { RefCategorie } from './ref-categorie.model';

@JsonObject
export class Document {
    @JsonProperty('id', Number)
    @Optional
    id: number;

    @JsonProperty('libelle', String)
    libelle: string;

    @JsonProperty('template', String)
    template: string;

    @JsonProperty('idOrganisme', Number)
    @Optional
    idOrganisme: number;

    @JsonProperty('idRefCategorie', Number)
    @CustomConverter(DataIdConverterFactory.build(RefCategorie, 'refCategorieList'))
    refCategorie: RefCategorie;

    @JsonProperty('isMailable', Boolean)
    @Optional
    isMailable: boolean;

    @JsonProperty('isIndividuel', Boolean)
    @Optional
    isIndividuel?: boolean;

    @JsonProperty('isEntete', Boolean)
    @Optional
    isEntete?: boolean;

    checked?: boolean;
}
