import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Prelevement } from 'src/app/data/declaration/models/prelevement.model';
import * as moment from 'moment';
import { PrelevementsService } from 'src/app/data/declaration/services/prelevements/prelevements.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-saisir-prelevement',
  templateUrl: './saisir-prelevement.component.html',
  styleUrls: ['./saisir-prelevement.component.scss']
})
export class SaisirPrelevementComponent implements OnInit {
  public idEchantillon: number;
  public formPrelevement: FormGroup;
  get date() { return this.formPrelevement.get('date'); }
  get observation() { return this.formPrelevement.get('observation'); }
  get representant() { return this.formPrelevement.get('representant'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly modal: NgbActiveModal,
    private readonly prelevementsService: PrelevementsService,
    private readonly translateService: TranslateService,
    private readonly toastrService: ToastrService
  ) { }

  ngOnInit() {
    // Instancier le formulaire
    this.formPrelevement = this.fb.group({
      date: [undefined, Validators.required],
      observation: undefined,
      representant: undefined
    });
  }

  /** Valide le formulaire puis ajoute le prélèvement à l'échantillon */
  public onSubmit(): void {
    if (this.formPrelevement.valid) {
      // Récupérer le prélèvement saisit par l'utilisateur
      const prelevement = Object.assign(new Prelevement(), {
        date: moment([
          this.date.value.year,
          this.date.value.month - 1,
          this.date.value.day, 0, 0, 0
        ]),
        observation: this.observation.value,
        representant: this.representant.value
      });

      // Ajouter le prélèvement à l'échantillon
      this.prelevementsService.patchPrelevement(this.idEchantillon, prelevement).subscribe(() => {
        this.translateService.get('page.controles.echantillons.modal.saisirPrelevement.creation-ok').subscribe(
          message => this.toastrService.success('', message)
        );

        this.modal.close(prelevement);
      });
    }
  }

  /** Ferme la fenêtre surgissante */
  public onClose(): void { this.modal.dismiss(); }
}
