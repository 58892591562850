import { JsonObject, JsonProperty, CustomConverter } from '@thorolf/json-ts-mapper';

import { Activite } from './activite.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';

@JsonObject
export class Cahier {
  @JsonProperty('id', Number)
  public id: number = undefined;

  @JsonProperty('libelle', String)
  public libelle: string = undefined;

  @JsonProperty('activites', [Number])
  @CustomConverter(DataIdConverterFactory.build(Activite, 'activites'))
  public activites: Activite[] = undefined;
}
