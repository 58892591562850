import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EchantillonEdition } from '../models/echantillon-edition.model';
import { ControleEdition } from '../models/controle-edition.model';
import { CommissionEdition } from '../models/commission-edition.model';
import { CommissionEditionMail } from '../models/commission-mail.model';
import { Fichier } from '../models/fichier.model';
import { OrganismeEdition } from '../models/organisme-edition.model';
import { GedFichier } from '../models/ged-fichier.model';
import { RefTypeFichier } from '../models/ref-type-fichier.model';
import { saveAs } from 'file-saver';
import { DashboardGed } from '../models/dashboard-ged.model';

@Injectable({
  providedIn: 'root'
})
export class EditionService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
  ) { }

  getCommissionEdition(idOrganisme: number, idCommission: number): Observable<CommissionEdition> {
    return this.http.get<CommissionEdition>(`/api/declaration/private/organismes/${idOrganisme}/commissions/${idCommission}/edition`)
      .pipe(
        map(commissionEdition => this.mapper.deserializeObject(commissionEdition, CommissionEdition))
      );
  }

  getControleEdition(idOrganisme: number, idControle: number): Observable<ControleEdition> {
    return this.http.get<ControleEdition>(`/api/declaration/private/organismes/${idOrganisme}/controles/${idControle}/edition`)
      .pipe(
        map(controleEdition => this.mapper.deserializeObject(controleEdition, ControleEdition))
      );
  }

  getEchantillonEdition(idOrganisme: number, idEchantillon: number): Observable<EchantillonEdition> {
    return this.http.get<EchantillonEdition>(`/api/declaration/private/organismes/${idOrganisme}/echantillons/${idEchantillon}/edition`)
      .pipe(
        map(echantillonEdition => this.mapper.deserializeObject(echantillonEdition, EchantillonEdition))
      );
  }

  getOrganismeEdition(idOrganisme: number): Observable<OrganismeEdition> {
    return this.http.get<OrganismeEdition>(`/api/intervenant/private/organismes/${idOrganisme}/edition`)
      .pipe(
        map(organismeEdition => this.mapper.deserializeObject(organismeEdition, OrganismeEdition))
      );
  }

  genererPdfCommission(idOrganisme: number, commissionEdition: CommissionEdition, idDocumentList: number[]): Observable<any> {
    return this.http.post<Fichier[]>(`/api/edition/private/organismes/${idOrganisme}/generer/commission?idDocumentList=${idDocumentList}`, commissionEdition)
      .pipe(
        tap(() => { }),
        map((fichier) => this.mapper.deserializeArray(fichier, Fichier))
      );
  }

  genererPdfControle(idOrganisme: number, controleEdition: ControleEdition, idDocumentList: number[]): Observable<any> {
    return this.http.post<Fichier[]>(`/api/edition/private/organismes/${idOrganisme}/generer/controle?idDocumentList=${idDocumentList}`, controleEdition)
      .pipe(
        tap(() => { }),
        map((fichier) => this.mapper.deserializeArray(fichier, Fichier))
      );
  }

  genererPdfEchantillon(idOrganisme: number, echantillonEdition: EchantillonEdition, idDocumentList: number[]): Observable<any> {
    return this.http.post<Fichier[]>(`/api/edition/private/organismes/${idOrganisme}/generer/echantillon?idDocumentList=${idDocumentList}`, echantillonEdition)
      .pipe(
        tap(() => { }),
        map((fichier) => this.mapper.deserializeArray(fichier, Fichier))
      );
  }

  envoyerMailCommission(idOrganisme: number, commissionEditionMail: CommissionEditionMail, idDocumentList: number[]): Observable<any> {
    return this.http.post<Fichier[]>(`/api/edition/private/organismes/${idOrganisme}/envoyer-mail/commission?idDocumentList=${idDocumentList}`, commissionEditionMail)
      .pipe(
        tap(() => { }),
        map((fichier) => this.mapper.deserializeArray(fichier, Fichier))
      );
  }

  public getDocuments(idIntervenant: number, idEntreprise: number, codeRefSousType: string): Observable<Array<GedFichier>> {
    //return this.http.get<Array<object>>('/assets/mocks/ged-fichiers.json')
    let params = `?`;
    if(idEntreprise) {
      params = params + `id_entreprise=${idEntreprise}`
    }
    if(idEntreprise && codeRefSousType) {
      params = params + '&';
    }
    if(codeRefSousType) {
      params = params + `code_sous_type=${codeRefSousType}`
    }
    return this.http.get<object[]>(`/api/edition/private/intervenants/${idIntervenant}/files${params}`)
      .pipe(map((fichiers: Array<object>) => this.mapper.deserializeArray(fichiers, GedFichier)));
  }

  public postFile(idOperateur: number, fichierDto: GedFichier): Observable<void> {
    return this.http.post<void>(`/api/edition/private/operateurs/${idOperateur}/files`, fichierDto);
  }

  public patchFile(idOperateur: number, fichierDto: GedFichier): Observable<void> {
    return this.http.patch<void>(`/api/edition/private/operateurs/${idOperateur}/files`, fichierDto);
  }

  public putFile(idOperateur: number, fichierDto: GedFichier): Observable<void> {
    return this.http.put<void>(`/api/edition/private/operateurs/${idOperateur}/files`, fichierDto);
  }

  public postUploadFile(idOperateur: number, fichier: File): Observable<string> {
    // TODO : Remplacer par un appel au Web Service de la GED directement
    const formData = new FormData();
    formData.append('file', fichier, fichier.name);
    return this.http.post<string>(`/api/edition/private/operateurs/${idOperateur}/files/upload/`, formData);
  }

  public putUploadFile(idOperateur: number, fichier: File, uuid: string): Observable<void> {
    const formData = new FormData();
    formData.append('file', fichier, fichier.name);
    return this.http.put<void>(`/api/edition/private/operateurs/${idOperateur}/files/${uuid}/upload/`, formData);
  }

  public downloadFile(idOperateur: number, uuid: string): void {
    this.http.get(`/api/edition/private/operateurs/${idOperateur}/files/${uuid}/download`, { 
      observe: 'response', 
      responseType: 'blob' 
    }).subscribe(data => {
      const blob = new Blob([data.body], { type: data.headers.get('Content-Type') });
      const contentDisposition = data.headers.get('content-disposition');
      const filename = contentDisposition
        .split(';')[1]
        .split('filename')[1]
        .split('=')[1].trim();
      saveAs(blob, filename);
    });
  }

  public getRefTypesFichier(idOperateur: number): Observable<Array<RefTypeFichier>> {
    return this.http.get<Array<object>>(`/api/edition/private/operateurs/${idOperateur}/files/types`).pipe(
      map((refTypesFichiers: Array<object>) => this.mapper.deserializeArray(refTypesFichiers, RefTypeFichier))
    );
  }

  public archiveFile(idOperateur: number, id: number): Observable<void> {
    return this.http.delete<void>(`/api/edition/private/operateurs/${idOperateur}/files/${id}`);
  }

  public getDashboardDocuments(idEntreprise: number, codeSousType: string, nbDocuments: number): Observable<DashboardGed> {
    //return this.http.get<Array<object>>('/assets/mocks/ged-fichiers.json')
    return this.http.get<object[]>(`/api/edition/private/types/${codeSousType}/entreprises/${idEntreprise}/dashboard/files?nb_documents=${nbDocuments}`)
      .pipe(
        map(result => this.mapper.deserializeObject(result, DashboardGed)));
  }

}
