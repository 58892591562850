<div>
  <div class="modal-header">
    <h4 class="modal-title" translate [translateParams]="{ numero: manquement.numero }" class="flex">
      page.controles.manquement.edit.title
      <ng-container *ngTemplateOutlet="statutManquement;context:{statut:manquement.statut, manquement:manquement}">
      </ng-container>
    </h4>
    <div (mouseover)="showControlDetails=true" (mouseout)="showControlDetails=false">
      <h4>
        <fa-icon icon="info-circle"></fa-icon>
        Contrôle n° {{controle.numeroControle}}
      </h4>
    </div>
    <div class="pointer">
      <fa-icon icon="times" (click)="onClose()"></fa-icon>
    </div>
  </div>

  <div *ngIf=" showControlDetails" style="margin-left: 20%; z-index: 5000; position: absolute;">
    <table class="tControlDetails">
      <tbody>
        <tr>
          <td style="font-size: 18px"><b>Contrôle n° {{ controle.numeroControle }}</b></td>
          <td>
            <b>{{'page.controles.manquement.controleDetails.typeControle' | translate}} : </b>
            {{ controle.nature.libelle }}</td>
        </tr>
        <tr>
          <td>
            <b>{{'page.controles.manquement.controleDetails.produit' | translate}} : </b>
            <br />
            {{ controleDetails.libelleProduit }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <b>{{'page.controles.manquement.controleDetails.millesime' | translate}} :</b>
            <br />
            {{ controleDetails.millesime }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <b>{{'page.controles.manquement.controleDetails.numeroLot' | translate}} :</b>
            <br />
            {{ controleDetails.numeroLot }}
          </td>
          <td>
            <b>{{'page.controles.manquement.controleDetails.volume' | translate}} :</b>
            <br />
            {{ controleDetails.volume }}
          </td>
        </tr>
        <tr>
          <td>
            <b>{{'page.controles.manquement.controleDetails.logement' | translate}} :</b>
            <br />
            {{ controleDetails.logement }}
          </td>
          <td>
            <b>{{'page.controles.manquement.controleDetails.datePrelevement' | translate}} :</b>
            <br />
            {{ controleDetails.datePrelevement | date:'dd/MM/yyyy' }}
          </td>
        </tr>
        <tr>
          <td>
            <b>{{'page.controles.manquement.controleDetails.numeroEchantillon' | translate}} :</b>
            <br />
            {{ controleDetails.numeroEchantillon }}
          </td>
          <td>
            <b>{{'page.controles.manquement.controleDetails.numeroExpertise' | translate}} :</b>
            <br /> {{ controleDetails.numeroExpertise }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Div de contenu de la fenêtre surgissante -->
  <div class="modal-body">
    <ngb-tabset #tabSet="ngbTabset" *ngIf="!chargementEnCours && controle && manquementDetail; else loading"
      (tabChange)="onTabChange($event, tabSet)">
      <ngb-tab id="constat" title="Constat">
        <ng-template ngbTabContent>
          <app-saisir-constat [controle]="controle" [(saisieLot)]="lot" [estOnglet]="true" #constat>
          </app-saisir-constat>
        </ng-template>
      </ngb-tab>

      <ngb-tab id="notification" title="Notification" (tabChange)="onTabChange($event, tabSet)">
        <ng-template ngbTabContent>
          <app-saisir-notification [manquement]="manquementDetail" [estOnglet]="true" #notification>
          </app-saisir-notification>
        </ng-template>
      </ngb-tab>

      <ngb-tab id="decision" title="Décision" (tabChange)="onTabChange($event, tabSet)">
        <ng-template ngbTabContent>
          <app-saisir-decision [(manquement)]="lot.lot[0]" [controle]="controle" [estOnglet]="true" #decision>
          </app-saisir-decision>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="recours" title="Recours" (tabChange)="onTabChange($event, tabSet)"
        *ngIf="manquementDetail.notification.recours">
        <ng-template ngbTabContent>
          <app-saisir-recours [(manquement)]="lot.lot[0]" [controle]="controle" [estOnglet]="true" #recours>
          </app-saisir-recours>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>

    <!-- Boutons d'action -->
    <div class="actions-bar">
      <button type="button" class="btn btn-light" (click)="onClose()" translate>label.annuler</button>
      <button type="button" class="btn btn-primary ml-2" (click)="onSave()"
        translate>page.controles.manquement.edit.enregistrer</button>
    </div>
  </div>
</div>

<ng-template #statutManquement let-statut="statut" , let-manquement="manquement">
  <div ngbDropdown class="dropdown" class="statut-manquement" *hasRole="'DECLARATION_CONTROLE_ECRITURE'; elseDisable">
    <strong ngbDropdownToggle class="btn-sm dropdown-toggle pointer statut-manquement-current {{statut.code}}"
      data-toggle="dropdown">
      {{statut.libelle}}
    </strong>
    <div class="dropdown-menu" ngbDropdownMenu>
      <span *ngFor="let statut of refStatuts" class="dropdown-item pointer statut-manquement-item {{statut.code}}"
        (click)="changeManquementStatut(manquement, statut)">
        {{statut.libelle}}
      </span>
    </div>
  </div>

</ng-template>

<ng-template #loading>
  Chargement du contrôle et du manquement en cours
</ng-template>