
import { JsonObject, JsonProperty, CustomConverter, Optional } from "@thorolf/json-ts-mapper";
import { DateConverter } from "src/app/core/services/mapper/converters";
import { DataIdConverterFactory } from "src/app/core/services/mapper/data-id.converter";
import { Cahier } from "../../habilitation/models/cahier.model";
import { Operateur } from "../../intervenant/models/operateur.model";
import { College } from "./college.model";
import { StatutJure } from "./statut-jure.model";

@JsonObject
export class JureCdc {
  @JsonProperty('id', Number)
  @Optional
  id: number;

  @JsonProperty('nom', String)
  @Optional
  nom: string;

  @JsonProperty('idCollege', [Number])
  @CustomConverter(DataIdConverterFactory.build(College, 'colleges'))
  @Optional
  colleges: College[];

  @JsonProperty('idCahiers', [Number])
  @CustomConverter(DataIdConverterFactory.build(Cahier, 'cahiers'))
  @Optional
  cahiers: Cahier[];

  @JsonProperty('idIntervenantList', [Number])
  @Optional
  idsOperateurs: number[];

  @JsonProperty('dateDerniereFormation', String)
  @CustomConverter(DateConverter)
  @Optional
  dateDerniereFormation: moment.Moment;

  @JsonProperty('mail', String)
  @Optional
  mail: string;

  @JsonProperty('idStatut', Number)
  @CustomConverter(DataIdConverterFactory.build(StatutJure, 'statutsJure'))
  @Optional
  statutJure: StatutJure;

  @JsonProperty('dateCommission', String)
  @CustomConverter(DateConverter)
  @Optional
  dateCommission: moment.Moment;

  @JsonProperty('numeroCommission', String)
  @Optional
  numeroCommission: string;
  
  @Optional
  operateurs: Operateur[];
  
  @Optional
  checked?: boolean;
}