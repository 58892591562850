import { Component, OnInit } from '@angular/core';

import { Organisme } from 'src/app/data/intervenant/models/organisme.model';
import { OrganismesService } from 'src/app/data/intervenant/services/organismes/organismes.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';

@Component({
  selector: 'app-operateur-organismes',
  templateUrl: './operateur-organismes.component.html',
  styleUrls: ['./operateur-organismes.component.scss']
})
export class OperateurOrganismesComponent implements OnInit {
  static routes = [
    {
      path: '',
      component: OperateurOrganismesComponent,
      data: {
        role: 'INTERVENANT_ORGANISME_LISTE_LECTURE',
        menu: {
          icon: 'address-book',
          libelle: 'menu.operateur-organismes'
        }
      }
    }
  ];

  organismes: Organisme[];
  searchStringInput: string;
  searchString: string;
  searchStringUpdate = new Subject<string>();
  order = {
    property: 'id',
    reverse: false
  };

  constructor(
    private readonly organismesService: OrganismesService,
    private readonly loaderService: AdvBootstrapLoaderService
  ) {
    this.searchStringUpdate.pipe(
      debounceTime(200),
      distinctUntilChanged())
      .subscribe(value => {
        this.searchString = value;
      });
  }

  ngOnInit() {
    this.organismesService.getOrganismes()
      .pipe(this.loaderService.operator())
      .subscribe(organismes => { this.organismes = organismes; });
  }

  setOrder(property: string) {
    if (property) {
      if (property === this.order.property) {
        this.order.reverse = !this.order.reverse;
      } else {
        this.order = {
          property,
          reverse: false
        };
      }
    }
  }

  getIcon(property: string) {
    if (property === this.order.property) {
      return this.order.reverse ? 'sort-down' : 'sort-up';
    } else {
      return 'sort';
    }
  }
}
