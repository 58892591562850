import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { ListControlesComponent } from './pages/list-controles/list-controles.component';
import { PressionControleComponent } from './pages/pression-controle/pression-controle.component';
import { ListEchantillonsComponent } from './pages/list-echantillons/list-echantillons.component';
import { EditionControleComponent } from './pages/list-controles/modals/edition-controle/edition-controle.component';
import { SuiviControlesComponent } from './pages/suivi-controles/suivi-controles.component';
import { SearchManquementsComponent } from './pages/search-manquements/search-manquements.component';
import { CommissionsComponent } from '../commissions/commissions.component';
import { PlanningCommissionsComponent } from '../commissions/pages/planning-commissions/planning-commissions.component';
import { EditCommissionComponent } from '../commissions/pages/edit-commission/edit-commission.component';
import { SyntheseJureComponent } from '../commissions/pages/synthese-jure/synthese-jure.component';

@Component({
  selector: 'app-controles',
  templateUrl: './controles.component.html',
  styleUrls: ['./controles.component.scss']
})
export class ControlesComponent implements OnInit {
  static routes: Routes = [
    {
      path: '',
      data: {
        role: 'ORGANISME',
        domaines: ['VIN'],
        menu: { icon: 'clipboard-check', libelle: 'menu.controles' }
      },
      children: [{
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
      }, {
        path: 'commissions/planning',
        component: PlanningCommissionsComponent,
        data: { menu: { libelle: 'Commissions' } }
      },{ 
        path: 'commissions/:idCommission/edit', component: EditCommissionComponent },
      {
        path: 'list',
        component: ListControlesComponent,
        data: { menu: { libelle: 'Pressions' } }
      }, {
        path: 'echantillons',
        component: ListEchantillonsComponent,
        data: { menu: { libelle: 'Echantillons' } }
      }, {
        path: 'suivi-controles',
        component: SuiviControlesComponent,
        data: { menu: { libelle: 'Suivi des contrôles' } }
      }, {
        path: 'search-manquements',
        component: SearchManquementsComponent,
        data: { menu: { libelle: 'Manquements' } }
      },
      {
        path: 'new', redirectTo: 'new/edit', pathMatch: 'full'
      }, {
        path: ':idControle/edit', component: EditionControleComponent
      }, {
        path: 'pression-controle/new', component: PressionControleComponent
      }, {
        path: 'pression-controle/edit/:id', component: PressionControleComponent
      }, {
        path: 'synthese-jure',
        component: SyntheseJureComponent,
        data: { menu: { libelle: 'Synthèses des jurés' } }
      }]
    }
  ];

  constructor() { }

  ngOnInit() {
  }
}
