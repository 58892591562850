<!-- Div .content -->
<div class="content">
  <!-- Div .content-panel -->
  <div class="content-panel">
    <h2 translate>page.controles.manquements.title</h2>

    <!-- Formulaire de recherche des manquements -->
    <form [formGroup]="formSearchManquements" #form="ngForm" (submit)="onSubmit()" novalidate>
      <div class="form-row">
        <div class="form-group col-md-5 row">
          <label for="nature" class="col-md-3 col-form-label" translate>page.controles.manquements.form.nature</label>
          <div class="col-md-6">
            <select id="nature" formControlName="nature" [ngClass]="{ 'is-invalid': form.submitted && nature.invalid }"
              class="form-control">
              <option [ngValue]="null" selected hidden translate>page.controles.manquements.form.nature</option>
              <option *ngFor="let nature of refNatures" [ngValue]="nature">{{ nature.libelle }}</option>
            </select>
          </div>
        </div>

        <div class="form-group col-md-5 row">
          <label for="statuts" class="col-md-3 col-form-label" translate>page.controles.manquements.form.statut</label>
          <div class="col-md-6">
            <app-multiselect id="statuts" controlName="statuts" [items]="refStatuts" bindLabel="libelle"
              maxWidth="initial">
            </app-multiselect>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-5 row">
          <label for="debut" class="col-md-3 col-form-label"
            translate>page.controles.manquements.form.constatEntre</label>
          <div class="col-md-6">
            <div class="input-group">
              <input id="debut" class="form-control" formControlName="debut" ngbDatepicker
                #datePickerDebut="ngbDatepicker" />
              <div class="input-group-append">
                <button type="button" (click)="datePickerDebut.toggle()" class="btn btn-secondary">
                  <fa-icon icon="calendar-alt"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-md-5 row">
          <label for="fin" class="col-md-3 col-form-label" translate>page.controles.manquements.form.et</label>
          <div class="col-md-6">
            <div class="input-group">
              <input id="fin" class="form-control" formControlName="fin" ngbDatepicker #datePickerFin="ngbDatepicker" />
              <div class="input-group-append">
                <button type="button" (click)="datePickerFin.toggle()" class="btn btn-secondary">
                  <fa-icon icon="calendar-alt"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-5 row">
          <label for="agents" class="col-md-3 col-form-label" translate>page.controles.manquements.form.agent</label>
          <div class="col-md-6">
            <app-multiselect id="agents" controlName="agents" [items]="refAgents" bindLabel="nomComplet"
              maxWidth="initial">
            </app-multiselect>
          </div>
        </div>

        <div class="form-group col-md-5 row">
          <label for="numeroManquement" class="col-md-3 col-form-label"
            translate>page.controles.manquements.form.numeroManquement</label>
          <div class="col-md-6">
            <input type="text" id="numeroManquement" formControlName="numeroManquement" class="form-control" />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-5 row">
          <label for="numeroControle" class="col-md-3 col-form-label"
            translate>page.controles.manquements.form.numeroControle</label>
          <div class="col-md-6">
            <input type="text" id="numeroControle" formControlName="numeroControle" class="form-control" />
          </div>
        </div>
        <div class="form-group col-md-5 row">
          <label for="numeroCVI" class="col-md-3 col-form-label" translate>page.controles.manquements.form.numeroCVI</label>
          <div class="col-md-6">
            <input type="text" id="numeroCVI" formControlName="numeroCVI" class="form-control" />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-10 row">
          <button type="submit" class="btn btn-primary rechercher">Rechercher</button>
        </div>
      </div>
    </form>
    <!-- Fin formulaire de recherche des manquements -->

    <ng-container *ngIf="pagination?.response?.length > 0; else aucunResultat">
      <table class="table">
        <thead>
          <th translate>page.controles.manquements.table.numeroManquement</th>
          <th translate>page.controles.manquements.table.cvi</th>
          <th translate>page.controles.manquements.table.raisonSociale</th>
          <th translate>page.controles.manquements.table.nature</th>
          <th translate>page.controles.manquements.table.dateConstat</th>
          <th translate>page.controles.manquements.table.agent</th>
          <th translate>page.controles.manquements.table.cdc</th>
          <th translate>page.controles.manquements.table.produit</th>
          <th translate>page.controles.manquements.table.statut</th>
          <th></th>
        </thead>

        <tbody>
          <tr *ngFor="let manquement of pagination.response">
            <td>{{ manquement.numero }}</td>
            <td>{{ manquement.cvi }}</td>
            <td>{{ manquement.operateur?.raisonSociale }}</td>
            <td>{{ manquement.nature?.libelle }}</td>
            <td>{{ manquement.dateConstat | date:'dd/MM/yyyy' }}</td>
            <td>{{ manquement.agent?.nomComplet }}</td>
            <td>{{ manquement.cahierDesCharges?.libelle }}</td>
            <td>{{ manquement.produit?.libelle }}</td>
            <td>{{ manquement.statut?.libelle }}</td>
            <td>
              <button type="button" class="btn btn-light" (click)="onEdit(manquement)" *hasRole="'DECLARATION_CONTROLE_ECRITURE'">
                <fa-icon icon="pen" title="Modifier"></fa-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <app-pagination-bar [pagination]="pagination.pagination"></app-pagination-bar>
    </ng-container>
  </div>
  <!-- Fin div .content-panel -->
</div>
<!-- Fin div .content -->

<ng-template #aucunResultat>
  <div class="alert alert-warning alert-block" translate>
    <fa-icon icon="exclamation"></fa-icon>
    page.controles.manquements.form.aucunManquement
  </div>
</ng-template>