<div class="modal-content anpp">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      <fa-icon icon="plus-square" class="primary-icon"></fa-icon>
      <b *ngIf="!modifierBlocBool" translate> page.inventaire-verger.modals.add-bloc.addBlocTitle</b>
      <b *ngIf="modifierBlocBool" translate> page.inventaire-verger.modals.add-bloc.modifBlocTitle</b></h5>
    <button (click)="cancel()" type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngb-tabset justify="justified" (tabChange)="beforeChange($event, tabSet)" #tabSet="ngbTabset">
      <ngb-tab id="1" title="{{'page.inventaire-verger.modals.add-bloc.localisationTitle'|translate }}">
        <ng-template ngbTabContent>
          <ngb-accordion [destroyOnHide]='false' [closeOthers]="true" (panelChange)='panelShadow($event, shadow)'
                         activeIds="panelListeLocalisation" #shadow>
            <ngb-panel id="panelListeLocalisation" title="{{'page.inventaire-verger.modals.add-bloc.panelTitleListeLoca'|translate }}">
              <ng-template ngbPanelContent>
                <div class="form-group">
                  <table  class="table table-striped" *ngIf="localisations">
                    <thead>
                    <tr>
                      <th></th>
                      <th translate>page.inventaire-verger.modals.add-bloc.operation</th>
                      <th *ngIf="show('departement')" translate>page.inventaire-verger.departement</th>
                      <th *ngIf="show('commune')" translate>page.inventaire-verger.commune</th>
                      <th *ngIf="show('lieuDit')" translate>page.inventaire-verger.lieuDit</th>
                      <th *ngIf="show('refCadastrale')" translate>page.inventaire-verger.refCadastrale</th>
                      <th *ngIf="show('situation')" translate>page.inventaire-verger.situation</th>
                      <th *ngIf="show('systemeIrrigation')" translate>page.inventaire-verger.systemeIrrigation</th>
                      <th *ngIf="show('protectionGrele')" translate>page.inventaire-verger.protectionGrele</th>
                      <th *ngIf="show('protectionGel')" translate>page.inventaire-verger.protectionGel</th>
                      <th *ngIf="show('forme')" translate>page.inventaire-verger.forme</th>
                      <th *ngIf="show('cultureBio')" translate>page.inventaire-verger.cultureBio</th>
                      <th *ngIf="show('nbBloc')" translate>page.inventaire-verger.modals.add-bloc.nbBlocsLoca</th>
                      <th>
                        <fa-icon class="clickable-icon" icon="ellipsis-h" (click)="showList()"></fa-icon>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="localisations && localisations.length === 0">
                      <td colspan="99" class="p-4"><fa-icon icon="exclamation-triangle"></fa-icon>&nbsp;
                        <span translate>page.inventaire-verger.modals.localisations.no_localisations</span>
                      </td>
                    </tr>
                    <ng-container *ngIf="localisations && localisations.length > 0">
                    <tr *ngFor="let localisation of localisations; let i=index;">
                      <td>
                        <div class="custom-control custom-checkbox">
                          <input [checked]="localisationCheck === localisation" type="checkbox"
                                 class="custom-control-input" id="loca-{{localisation.id}}" name="example1"
                                 (change)="onCheckboxChange(localisation,$event)">
                          <label class="custom-control-label" for="loca-{{localisation.id}}"></label>
                        </div>
                      </td>
                      <td>
                        <div class="btn-group dropright  ">
                          <div ngbDropdown placement="right-start">
                            <button class="btn btn-secondary dropdown-toggle btnprimary btn-sm "
                                    id="dropdownConfig" ngbDropdownToggle>
                              <fa-icon icon="cog"></fa-icon>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                              <button (click)="modifierLocalisation(localisation)" ngbDropdownItem
                                      class="dropdown-item" translate>
                                <fa-icon icon="pencil-alt"></fa-icon>
                                page.inventaire-verger.modals.add-bloc.buttonModifierLocalisation
                              </button>
                              <button (click)="supprimerLocalisation(localisation)" ngbDropdownItem
                                      class="dropdown-item" translate>
                                <fa-icon icon="trash-alt"></fa-icon>
                                page.inventaire-verger.modals.add-bloc.buttonSupprimerLocalisation
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td *ngIf="show('departement')">{{ localisation.departement}}</td>
                      <td *ngIf="show('commune')">{{ localisation.commune}}</td>
                      <td *ngIf="show('lieuDit')">{{ localisation.lieuDit}}</td>
                      <td *ngIf="show('refCadastrale')">{{ localisation.referenceCadastrale}}</td>
                      <td *ngIf="show('situation')">{{ localisation.caracteristiqueParcelle.refSituation ? localisation.caracteristiqueParcelle.refSituation.libelle : ''}}</td>
                      <td *ngIf="show('systemeIrrigation')">{{localisation.caracteristiqueParcelle.refSystemeIrrigation ? localisation.caracteristiqueParcelle.refSystemeIrrigation.libelle : ''}}</td>
                      <td *ngIf="show('protectionGrele')">{{localisation.caracteristiqueParcelle.refProtectionGrele ? localisation.caracteristiqueParcelle.refProtectionGrele.libelle : ''}}</td>
                      <td *ngIf="show('protectionGel')">{{ localisation.caracteristiqueParcelle.refProtectionGel ?localisation.caracteristiqueParcelle.refProtectionGel.libelle : '' }}</td>
                      <td *ngIf="show('forme')">{{ localisation.caracteristiqueParcelle.refForme ? localisation.caracteristiqueParcelle.refForme.libelle : '' }}</td>
                      <td *ngIf="show('cultureBio')">{{ localisation.caracteristiqueParcelle.refCultureBio ? localisation.caracteristiqueParcelle.refCultureBio.libelle : '' }}</td>
                      <td *ngIf="show('nbBloc')">{{ localisation.nbBloc}}</td>
                    </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </div>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="panelAjouterLocalisation" title="{{'page.inventaire-verger.modals.add-bloc.panelTitleAjouterLoca'|translate }}">
              <ng-template ngbPanelContent>
                <ngb-tabset (tabChange)="changeTabLoca(tabSet)" #tabSet="ngbTabset">
                  <ngb-tab id="localisationGeo" title="{{'page.inventaire-verger.modals.add-bloc.panelTitleLocaGeo'|translate }}">
                    <ng-template ngbTabContent>
                      <ng-container *ngIf="controleFormLocalisationGeo">
                      <form [formGroup]="controleFormLocalisationGeo" #form="ngForm" (ngSubmit)="submit()" novalidate>
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label translate>page.inventaire-verger.modals.add-bloc.codePostal</label>
                              <input [ngClass]="{'is-invalid': (form.submitted || form.touched) && codePostal.invalid || (communes && communes.length === 0)}" (keyup)="changeValueCodePostal($event)" type="text"  maxlength="5" formControlName="codePostal"
                                     class="form-control">
                              <ng-container *ngIf="form.submitted || form.touched">
                                <div *ngIf="codePostal.errors?.required" class="form-error" translate>label.obligatoire</div>
                              </ng-container>
                              <ng-container >
                                <div *ngIf="communes && communes.length === 0" class="form-error" translate>Aucune commune avec ce code postal</div>
                              </ng-container>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label translate>page.inventaire-verger.departement</label>
                              <select [ngClass]="{'is-invalid': (form.submitted || form.touched) && departement.invalid}" formControlName="departement" class="form-control">

                                <option *ngFor="let departement of departements"
                                        [ngValue]="departement">{{departement.code}} - {{departement.nom}}</option>
                              </select>
                              <ng-container *ngIf="form.submitted || form.touched">
                                <div *ngIf="departement.errors?.required" class="form-error" translate>label.obligatoire</div>
                              </ng-container>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label translate>page.inventaire-verger.commune</label>
                              <select [ngClass]="{'is-invalid': (form.submitted || form.touched) && commune.invalid}" formControlName="commune" class="form-control">
                                <option *ngFor="let commune of communes" [ngValue]="commune">{{commune.nom}}</option>
                              </select>
                              <ng-container *ngIf="form.submitted || form.touched">
                                <div *ngIf="commune.errors?.required" class="form-error" translate>label.obligatoire</div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label translate>page.inventaire-verger.lieuDit</label>
                              <input [ngClass]="{'is-invalid': (form.submitted || form.touched) && lieuDit.invalid}" (keyup)="changeLocalisationGeo()" type="text" formControlName="lieuDit"
                                     class="form-control">
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label translate>page.inventaire-verger.refCadastrale</label>
                              <input [ngClass]="{'is-invalid': (form.submitted || form.touched) && refCadastrale.invalid}" (keyup)="changeLocalisationGeo()" type="text" formControlName="refCadastrale"
                                     class="form-control">
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label translate>page.inventaire-verger.situation</label>
                              <select [ngClass]="{'is-invalid': (form.submitted || form.touched) && situationLoca.invalid}"  formControlName="situation" class="form-control">
                                <option *ngFor="let situation of refSituations"
                                        [ngValue]="situation">{{situation.libelle}}</option>
                              </select>
                              <ng-container *ngIf="form.submitted || form.touched">
                                <div *ngIf="situationLoca.errors?.required" class="form-error" translate>label.obligatoire</div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                        <button  (click)=onTabAddLocaChange(tabSet) type="button"
                                 class="btn btn-primary"
                                 style="background:#41905c;border:1px solid #41905c" data-dismiss="modal" aria-label="Close" translate>page.inventaire-verger.modals.add-bloc.buttonSuivant
                          <fa-icon icon="arrow-right" class="primary-icon"></fa-icon>
                        </button>
                      </form>
                      </ng-container>
                    </ng-template>
                  </ngb-tab>
                  <ngb-tab id="equipementsConduite" title="{{'page.inventaire-verger.modals.add-bloc.panelEquipementsConduites'|translate }}">
                    <ng-template ngbTabContent>
                      <form [formGroup]="controleFormEquipementConduite" #form="ngForm" (ngSubmit)="submit()"
                            novalidate>
                        <div class="row">
                          <div class="col-lg-4">
                        <div class="form-group">
                          <label translate>page.inventaire-verger.systemeIrrigation</label>
                          <select formControlName="systemeIrrigation" class="form-control">
                            <option [ngValue]="null"></option>
                            <option *ngFor="let systemeIrrigation of refSystemeIrrigations"
                                    [ngValue]="systemeIrrigation">{{systemeIrrigation.libelle}}</option>
                          </select>
                        </div>
                          </div>
                          <div class="col-lg-4">
                        <div class="form-group">
                          <label translate>page.inventaire-verger.protectionGel</label>
                          <select formControlName="protectionGel" class="form-control">
                            <option [ngValue]="null"></option>
                            <option *ngFor="let protectionGel of refProtectionGels"
                                    [ngValue]="protectionGel">{{protectionGel.libelle}}</option>
                          </select>
                        </div>
                          </div>
                          <div class="col-lg-4">
                        <div class="form-group">
                          <label translate>page.inventaire-verger.protectionGrele</label>
                          <select formControlName="protectionGrele" class="form-control">
                            <option [ngValue]="null"></option>
                            <option *ngFor="let protectionGrele of refProtectionGreles"
                                    [ngValue]="protectionGrele">{{protectionGrele.libelle}}</option>
                          </select>
                        </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-4">
                        <div class="form-group">
                          <label translate>page.inventaire-verger.cultureBio</label>
                          <select formControlName="cultureBio" class="form-control">
                            <option [ngValue]="null"></option>
                            <option *ngFor="let cultureBio of refCultureBios"
                                    [ngValue]="cultureBio">{{cultureBio.libelle}}</option>
                          </select>
                        </div>
                          </div>
                          <div class="col-lg-4">
                        <div class="form-group">
                          <label translate>page.inventaire-verger.forme</label>
                          <select formControlName="forme" class="form-control">
                            <option [ngValue]="null"></option>
                            <option *ngFor="let forme of refFormes" [ngValue]="forme">{{forme.libelle}}</option>
                          </select>
                        </div>
                        </div>
                        </div>
                        <button type="submit" *ngIf="!this.updateLocalisation"
                                class="btn btn-primary"
                                style="background:#41905c;border:1px solid #41905c" data-dismiss="modal" aria-label="Close" translate>
                          page.inventaire-verger.modals.add-bloc.buttonAjouterLocalisation
                        </button>
                        <button type="submit"  *ngIf="this.updateLocalisation" class="btn btn-primary"
                                style="background:#41905c;border:1px solid #41905c" data-dismiss="modal" aria-label="Close" translate>
                          page.inventaire-verger.modals.add-bloc.buttonModifierLocalisation
                        </button>
                      </form>
                    </ng-template>
                  </ngb-tab>
                </ngb-tabset>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="2" title="{{'page.inventaire-verger.modals.add-bloc.tracabiliteTitle'|translate }}">
        <ng-template ngbTabContent>
          <form [formGroup]="controleFormTracabilite" #form="ngForm" (ngSubmit)="submit()" novalidate>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.numBloc</label>
                  <input [ngClass]="{'is-invalid': (form.submitted || form.touched) && numBloc.invalid}" (keyup)="changeTrancabilite()" type="text" formControlName="numBloc"
                         class="form-control">
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.numDeTracabilite</label>
                  <input [ngClass]="{'is-invalid':(form.submitted || form.touched) && numTracabilite.invalid}"  (keyup)="changeTrancabilite()" type="text" formControlName="numTracabilite"
                         class="form-control">
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.codeParcelle</label>
                  <input [ngClass]="{'is-invalid': (form.submitted || form.touched) && codeParcelle.invalid}" (keyup)="changeTrancabilite()"   type="text" formControlName="codeParcelle"
                         class="form-control">
                </div>
              </div>
            </div>
          </form>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="3" title="{{'page.inventaire-verger.modals.add-bloc.botaniqueTitle'|translate }}">
        <ng-template ngbTabContent>
          <form [formGroup]="controleFormBotanique" #form="ngForm" (ngSubmit)="submit()" novalidate>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.espece</label>
                  <select [ngClass]="{'is-invalid': (form.submitted || form.touched) && espece.invalid}" formControlName="espece" class="form-control" (change)="changeValueEspece()">
                    <option *ngFor="let espece of especes" [ngValue]="espece">{{espece.libelle}}</option>
                  </select>
                  <ng-container *ngIf="form.submitted || form.touched">
                    <div *ngIf="espece.errors?.required" class="form-error" translate>label.obligatoire</div>
                  </ng-container>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.variete</label>
                  <select [ngClass]="{'is-invalid': (form.submitted || form.touched) && variete.invalid}" formControlName="variete" class="form-control" (change)="changeValueVariete()">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let variete of varietes" [ngValue]="variete">{{variete.libelle}}</option>
                  </select>
                  <ng-container *ngIf="form.submitted || form.touched">
                    <div *ngIf="variete.errors?.required" class="form-error" translate>label.obligatoire</div>
                  </ng-container>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.clone</label>
                  <select formControlName="clone" class="form-control">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let clone of varietesClone" [ngValue]="clone">{{clone.libelle}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.porteGreffe</label>
                  <select formControlName="porteGreffe" class="form-control">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let refPorteGreffe of refPorteGreffes "
                            [ngValue]="refPorteGreffe">{{refPorteGreffe.libelle}}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.anneePlantation</label>
                  <input [ngClass]="{'is-invalid': (form.submitted || form.touched) && anneePlantation.invalid}" (keyup)="changeBotanique()" (keypress)="keyPressNumbers($event)" type="number" min="0"
                         formControlName="anneePlantation" class="form-control">
                  <ng-container *ngIf="form.submitted || form.touched">
                    <div *ngIf="anneePlantation.errors?.max" class="form-error" translate >page.inventaire-verger.modals.add-bloc.formatDateAlert</div>
                    <div *ngIf="anneePlantation.errors?.min" class="form-error" translate >page.inventaire-verger.modals.add-bloc.formatDateAlert</div>
                  </ng-container>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.anneePremiereFeuille</label>
                  <input [ngClass]="{'is-invalid': (form.submitted || form.touched) && anneePremiereFeuille.invalid}" (keyup)="changeBotanique()" (keypress)="keyPressNumbers($event)" type="number" min="0"
                         formControlName="anneePremiereFeuille" class="form-control">
                  <ng-container *ngIf="form.submitted || form.touched">
                    <div *ngIf="anneePremiereFeuille.errors?.max" class="form-error" translate >page.inventaire-verger.modals.add-bloc.formatDateAlert</div>
                    <div *ngIf="anneePremiereFeuille.errors?.min" class="form-error" translate >page.inventaire-verger.modals.add-bloc.formatDateAlert</div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.anneeSurgreffage</label>
                  <input [ngClass]="{'is-invalid': (form.submitted || form.touched) && anneeSurgreffage.invalid}" (keyup)="changeBotanique()" (keypress)="keyPressNumbers($event)" type="number" min="0"
                         formControlName="anneeSurgreffage" class="form-control">
                  <ng-container *ngIf="form.submitted || form.touched">
                    <div *ngIf="anneeSurgreffage.errors?.max" class="form-error" translate >page.inventaire-verger.modals.add-bloc.formatDateAlert</div>
                    <div *ngIf="anneeSurgreffage.errors?.min" class="form-error" translate >page.inventaire-verger.modals.add-bloc.formatDateAlert</div>
                  </ng-container>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.distanceInterRang</label>
                  <input [ngClass]="{'is-invalid': (form.submitted || form.touched) && distanceInterRang.invalid}" (keyup)="arrondirDistance($event); calculeSurface()"  type="number" formControlName="distanceInterRang"
                         class="form-control">
                  <ng-container *ngIf="form.submitted || form.touched">
                    <div *ngIf="distanceInterRang.errors?.required" class="form-error" translate>label.obligatoire</div>
                    <div *ngIf="distanceInterRang.errors?.max" class="form-error" translate [translateParams]="{ maxDistance : maxDistance }">page.inventaire-verger.modals.add-bloc.maxDistanceAlert</div>
                    <div *ngIf="distanceInterRang.errors?.min" class="form-error" translate>page.inventaire-verger.modals.add-bloc.minDistanceAlert</div>
                  </ng-container>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.distanceIntraRang</label>
                  <input [ngClass]="{'is-invalid': (form.submitted || form.touched) && distanceIntraRang.invalid}" (keyup)="arrondirDistance($event); calculeSurface()" type="number" formControlName="distanceIntraRang"
                         class="form-control">
                  <ng-container *ngIf="form.submitted || form.touched">
                    <div *ngIf="distanceIntraRang.errors?.required" class="form-error" translate>label.obligatoire</div>
                    <div *ngIf="distanceIntraRang.errors?.max" class="form-error" translate [translateParams]="{ maxDistance : maxDistance }">page.inventaire-verger.modals.add-bloc.maxDistanceAlert</div>
                    <div *ngIf="distanceIntraRang.errors?.min" class="form-error" translate>page.inventaire-verger.modals.add-bloc.minDistanceAlert</div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.nombreArbre</label>
                  <input [ngClass]="{'is-invalid': (form.submitted || form.touched) && nbArbre.invalid}" (keyup)="calculeSurface()" (keypress)="keyPressNumbers($event)" min="0" type="number" formControlName="nbArbre" class="form-control">
                  <ng-container *ngIf="form.submitted || form.touched">
                    <div *ngIf="nbArbre.errors?.required" class="form-error" translate>label.obligatoire</div>
                  </ng-container>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-lg-12 text-center">
                <ng-template #htmlContent ><span [innerHtml]="'page.inventaire-verger.modals.add-bloc.surfaceTooltip'|translate"></span></ng-template>
                <span href="#" [ngbTooltip]="htmlContent" tooltipClass="text-left">

                <div class="  pt-2 m-2"
                     style="width:90%;display:inline-block;border-radius:15px;line-height:30px;border:1px solid  rgba(131,187,38,1); background:rgba(131,187,38,0.15);">
                  <b style="font-size:25px" translate> <fa-icon icon="info-circle"></fa-icon> page.inventaire-verger.surfaceHa <span
                    style="font-size:25px;color:rgba(131,187,38,1)">{{this.surfaceHa|number:'1.0-3' }} Ha</span></b><br/>
                </div>
                  </span>
              </div>
            </div>
          </form>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="4" title="{{'page.inventaire-verger.modals.add-bloc.caracteristiqueTitle'|translate}}">
        <ng-template ngbTabContent>
          <form [formGroup]="controleFormCaracteristique" #form="ngForm" (ngSubmit)="submit()" novalidate>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.systemeIrrigation</label>
                  <select formControlName="systemeIrrigation" class="form-control" (change)="manualChange=true">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let systemeIrrigation of refSystemeIrrigations"
                            [ngValue]="systemeIrrigation">{{systemeIrrigation.libelle}}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.protectionGel</label>
                  <select formControlName="protectionGel" class="form-control" (change)="manualChange=true">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let protectionGel of refProtectionGels"
                            [ngValue]="protectionGel">{{protectionGel.libelle}}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.protectionGrele</label>
                  <select formControlName="protectionGrele" class="form-control" (change)="manualChange=true">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let protectionGrele of refProtectionGreles"
                            [ngValue]="protectionGrele">{{protectionGrele.libelle}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.cultureBio</label>
                  <select formControlName="cultureBio" class="form-control" (change)="manualChange=true">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let cultureBio of refCultureBios"
                            [ngValue]="cultureBio">{{cultureBio.libelle}}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.forme</label>
                  <select formControlName="forme" class="form-control" (change)="manualChange=true">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let forme of refFormes" [ngValue]="forme">{{forme.libelle}}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.situation</label>
                  <select formControlName="situation" class="form-control" (change)="manualChange=true">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let situation of refSituations" [ngValue]="situation">{{situation.libelle}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label translate>page.inventaire-verger.confusion</label>
                  <input type="checkbox" formControlName="confusion" class="form-control" style="width:30%">
                </div>
              </div>
            </div>
          </form>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="5" title="{{'page.inventaire-verger.modals.add-bloc.recapitulatifTitle'|translate}}">
        <ng-template ngbTabContent>
          <div>
            <h5 class="sub-popup-title" translate>page.inventaire-verger.modals.add-bloc.localisationTitle</h5>
            <div class="row">
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.departement</span>
                <span>: {{localisationCheck?.departement ? localisationCheck.departement:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.commune</span>
                <span>: {{localisationCheck?.commune ? localisationCheck.commune:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.lieuDit</span>
                <span>: {{localisationCheck?.lieuDit ? localisationCheck.lieuDit:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.refCadastrale</span>
                <span>: {{localisationCheck?.referenceCadastrale ? localisationCheck.referenceCadastrale:'N/C'}}</span>
              </div>
            </div>
            <hr>
            <h5 class="sub-popup-title" translate>page.inventaire-verger.modals.add-bloc.tracabiliteTitle</h5>
            <div class="row">
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.numBloc</span>
                <span>: {{numBloc?.value ? numBloc.value:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.numDeTracabilite</span>
                <span>: {{numTracabilite?.value ? numTracabilite.value:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.codeParcelle</span>
                <span>: {{codeParcelle?.value ? codeParcelle.value:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
              </div>
            </div>
            <hr>
            <h5 class="sub-popup-title" translate>page.inventaire-verger.modals.add-bloc.botaniqueTitle</h5>
            <div class="row">
              <div  class="col-lg-3">
                <span translate>page.inventaire-verger.espece</span>
                <span>: {{espece.value?.libelle ? espece.value.libelle:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.variete</span>
                <span>: {{variete.value?.libelle ? variete.value.libelle:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.clone</span>
                <span>: {{clone.value?.libelle ? clone.value.libelle:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.porteGreffe</span>
                <span>: {{porteGreffe.value?.libelle ? porteGreffe.value.libelle:'N/C'}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.anneePlantation</span>
                <span>: {{anneePlantation?.value ? anneePlantation.value:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.anneePremiereFeuille</span>
                <span>: {{anneePremiereFeuille?.value ? anneePremiereFeuille.value:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.anneeSurgreffage</span>
                <span>: {{anneeSurgreffage?.value ? anneeSurgreffage.value:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.distanceInterRang</span>
                <span>: {{distanceInterRang?.value ? distanceInterRang.value:'N/C'}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.distanceIntraRang</span>
                <span>: {{distanceIntraRang?.value ? distanceIntraRang.value:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.nombreArbre</span>
                <span>: {{nbArbre?.value ? nbArbre.value:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.surface</span>
                <span>: {{surfaceHa ? (surfaceHa|number:'1.0-3')  :'N/C'}}</span>
              </div>
              <div class="col-lg-3">
              </div>
            </div>
            <hr>
            <h5 class="sub-popup-title" translate>page.inventaire-verger.modals.add-bloc.caracteristiqueTitle</h5>
            <div class="row">
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.systemeIrrigation</span>
                <span>: {{systemeIrrigationBloc.value?.libelle ? systemeIrrigationBloc.value.libelle:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.protectionGel</span>
                <span>: {{protectionGelBloc.value?.libelle ? protectionGelBloc.value.libelle:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.forme</span>
                <span>: {{formeBloc.value?.libelle ? formeBloc.value.libelle:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.cultureBio</span>
                <span>: {{cultureBioBloc.value?.libelle ? cultureBioBloc.value.libelle:'N/C'}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.protectionGrele</span>
                <span>: {{protectionGreleBloc.value?.libelle ? protectionGreleBloc.value.libelle:'N/C'}}</span>
              </div>
              <div class="col-lg-3">
                <span translate>page.inventaire-verger.situation</span>
                <span>: {{situationBloc.value?.libelle ? situationBloc.value.libelle:'N/C'}}</span>
              </div>
              <div class=" col-lg-3">
                <span translate>page.inventaire-verger.confusion</span>
                <span>: {{confusion?.value ? 'Oui':'Non'}}</span>
              </div>
              <div class="col-lg-3">
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
  <div class="modal-footer">
    <button *ngIf="isButtonVisible" (click)=onTabChange(tabSet) [hidden]="hiddenButton" type="button"
            class="btn btn-primary"
            style="background:#41905c;border:1px solid #41905c" data-dismiss="modal" aria-label="Close" translate>page.inventaire-verger.modals.add-bloc.buttonSuivant
      <fa-icon icon="arrow-right" class="primary-icon"></fa-icon>
    </button>
    <button (click)="submit()" *ngIf="!isButtonVisible" type="submit" class="btn btn-primary"
            style="background:#41905c;border:1px solid #41905c" data-dismiss="modal" aria-label="Close"translate>page.inventaire-verger.buttonEnregistrer
    </button>
  </div>
</div>
