import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { RefSoustypeUtilisateur } from '../../droits/models/ref-soustype-utilisateur.model';

@JsonObject
export class EntrepriseBase {

  @JsonProperty('id', Number)
  @Optional
  public id: number = undefined;

  @JsonProperty('raisonSociale', String)
  public raisonSociale: string = undefined;

  @JsonProperty('estAdherent', Boolean)
  @Optional
  public estAdherent: boolean = undefined;

  @JsonProperty('refSousType', RefSoustypeUtilisateur)
  @Optional
  public refSousType: RefSoustypeUtilisateur = undefined;

  @JsonProperty('telephone', String)
  @Optional
  public telephone: string = undefined;

  @JsonProperty('mail', String)
  @Optional
  public mail: string = undefined;

}
