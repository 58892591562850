import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, flatMap, tap } from 'rxjs/operators';

import { PaginationService, PaginateResponseStream } from 'src/app/shared/services/pagination/pagination.service';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { PressionResultat } from '../../models/pression-resultat.model';
import { ReferentielService } from '../referentiel/referentiel.service';
import { Referentiel } from '../../models/referentiel.model';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { ProduitsService } from 'src/app/data/habilitation/services/produits/produits.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PressionResultatsService {

  constructor(
    private readonly mapper: JsonMapperService,
    private readonly paginationService: PaginationService,
    private readonly refService: ReferentielService,
    private readonly operateurService: OperateursService,
    private readonly produitService: ProduitsService
  ) { }

  getResultatsPression(idPression: number, annee: number, raisonSociale: string, non_controlable: string, showLoader = true): Observable<PaginateResponseStream<PressionResultat>> {
    const params = new HttpParams()
  .set('rs', raisonSociale)
  .set('nc', non_controlable);
    
    return this.paginationService.paginateGetAsStream<object>(`/api/declaration/private/pressions/${idPression}/resultats/${annee}`, undefined, undefined, {params}, showLoader).pipe(
      flatMap(response => {
        return this.refService.getReferentiel().pipe(
          map(ref => [response, ref] as [PaginateResponseStream<object>, Referentiel])
        );
      }),
      map(([response, ref]) => {
        response.response = this.mapper.deserializeObject(response.response, PressionResultat, ref);
        return response as PaginateResponseStream<PressionResultat>;
      }),
      tap(response => {
        response.response.pressionResultatList.forEach(item => {
          this.operateurService.getOperateur(item.idOperateur)
            .subscribe(operateur => item.operateur = operateur);
        });
      }),
      tap(response => {
        response.response.pressionResultatList.forEach(item => {
          this.operateurService.getInformationsDomaine(item.idOperateur)
            .subscribe(infos => item.infosOperateur = infos);
        });
      }),
      tap(response => {
        response.response.pressionResultatList.forEach(item => {
          this.produitService.getProduitByCode(item.codeProduit)
            .subscribe(produit => item.produit = produit);
        });
      })
    );
  }
}
