<div class="row mt-4 pt-4">

	<div class="col-lg-12">
		<div class="row">
			<div class="col-lg-8">
				<div class="cards  border-radius text-center" style="background:#f8faff">
					<div class="row">
						<div class="col-lg-6 text-left">
							<h4 class="text-grey pl-4 pt-4 pb-0  m-0"> Mon tableau de Bord </h4>

								<div class=" border-radius pt-4 text-center">

									<fa-icon icon="users" style="color:#55418e;font-size:50px"></fa-icon><br />
									<strong style="font-size:30px">{{nbOperateurs}} </strong>
									<h5 style="margin-top:-5px"> Opérateur(s)</h5>
								</div>

							<div class="input-group pl-4  p-4">
								<input type="text" (keydown.enter)="searchUser()" class="form-control" [(ngModel)]="userSearch"
									placeholder="Rechercher un opérateur" aria-label="" aria-describedby="basic-addon2">
								<div class="input-group-append">
									<button (click)="searchUser()" type="submit" class="btn btn-outline-secondary text-white bg-blue">
										<fa-icon icon="search"></fa-icon>
									</button>
								</div>
							</div>
						</div>
						<div class="col-lg-6 text-center pt-2">

							<div id="main" style="height:300px;" echarts [options]="chartHabilitation"></div>
							<strong class="mt-4"> Habilitations</strong>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-4">
				<div class="cards  border-radius p-2">
					<fa-icon icon="calendar-alt" class="mt-2 p-2" style="font-size:20px"></fa-icon><strong>
						Commissions</strong>
					<p class="text-center"> <small>En construction</small></p>
				</div>
			</div>
		</div>

		<div class="row mt-4">


			<div class="col-lg-4">
				<div class="cards  border-radius p-2 pb-4 text-center">
					<fa-icon icon="file-contract" class="mt-2 p-2" style="font-size:20px"></fa-icon><strong>
						Télédéclarations</strong>
					<div class="row">
						<div class="col-lg-6">
							<div class=" border-radius p-2 pointer" (click)="goToSuivi()">
								<div class=" badge-light  badge-light-red">
									{{nbDeclarationsNonLues}}
								</div>
								<p><small style="display:block;">nouvelle(s)</small></p>
							</div>
						</div>
						<div class="col-lg-6">
							<div class=" border-radius p-2 pointer" (click)="goToManquantes()">
								<div class=" badge-light  badge-light-red">
									{{nbDrevManquantes}}
								</div>
								<p><small style="display:block;">manquante(s)</small></p>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div class="col-lg-8">
				<div class="cards  border-radius  p-2 pb-2 text-center">
					<fa-icon icon="clipboard-check" class="mt-2 p-2" style="font-size:20px"></fa-icon><strong>
						Contrôles</strong>
					<div class="row">
						<div class="col-lg-3">
							<div class=" border-radius p-2">
								<div class=" badge-light  badge-light-vert">
									{{nbControlesPrevus}}
								</div>
								<p><small style="display:block;">contrôle(s)<br />  programmé(s)</small></p>
							</div>
						</div>
						<div class="col-lg-3">
							<div class=" border-radius p-2">
								<div class=" badge-light  badge-light-blue">
									{{nbEchantillons[1]}}
								</div>
								<p><small style="display:block;">échantillon(s) <br />à prélever</small></p>
							</div>
						</div>


						<div class="col-lg-3">
							<div class=" border-radius p-2">
								<div class=" badge-light  badge-light-orange">
									{{nbEchantillons[0]}}
								</div>
								<p><small style="display:block;">échantillon(s) <br />prélevé(s)</small></p>
							</div>
						</div>

						<div class="col-lg-3">
							<div class=" border-radius p-2">
								<div class=" badge-light  badge-light-red">
									{{nbManquement}}
								</div>
								<p><small style="display:block;">manquement(s)<br /> à échéance</small></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>