import { Component, OnInit } from '@angular/core';
import { Declarations } from 'src/app/data/declaration/models/declarations.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { DeclarationsService } from 'src/app/data/declaration/services/declarations/declarations.service';
import { DREVSaisie } from 'src/app/data/declaration/models/drevsaisie.model';
import { DeclarationRevendication } from 'src/app/data/declaration/models/declaration-revendication.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SessionContext, NavigationContext } from 'src/app/core/services/config/app.settings';
import * as moment from 'moment';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';

@Component({
  selector: 'app-consultation-drev',
  templateUrl: './consultation-drev.component.html',
  styleUrls: ['./consultation-drev.component.scss']
})
export class ConsultationDrevComponent implements OnInit {
  static routes: Route[] = [
    { path: '', component: ConsultationDrevComponent, data: { menu: { libelle: 'Consultation des DREV' } } },
    { path: ':commission', component: ConsultationDrevComponent }
  ];

  public declarations: Declarations;
  public annee: number;
  public formGroup: FormGroup;
  get commission() { return this.formGroup.get('commission'); }

  constructor(
    private readonly declarationsService: DeclarationsService,
    private readonly referentielService: ReferentielService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly modalService: NgbModal,
    private readonly fb: FormBuilder
  ) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      commission: [undefined, [Validators.required, Validators.pattern(/(19|20)\d\d/)]]
    });

    const annees: number[] = [];
    this.referentielService.getReferentiel().subscribe(campagnes => {
      campagnes.campagnes.forEach(c => {
        annees.push(c.annee);
      });


      this.route.params.subscribe(vals => {
        let commission = vals.commission;
        if (commission) {
          this.commission.setValue(commission);
        } else {
          commission = annees.reduce((a, b) => Math.max(a, b)).toString();
          this.commission.setValue(annees.reduce((a, b) => Math.max(a, b)).toString());
        }
        this.loadData(commission);
      });

      this.commission.valueChanges.subscribe(commission => {
        if (this.commission.valid) {
          this.router.navigate([commission], { relativeTo: this.route.parent });
        }
      });

    });

    // this.annee = 2019; // TODO
    // setTimeout(() => this.loadData());
  }

  get idIntervenant(): number {
    if (NavigationContext.has('idOperateur')) {
      return NavigationContext.get('idOperateur');
    } else {
      return SessionContext.get('idIntervenant');
    }
  }

  private loadData(annee: string) {
    // Récupérer les declarations AOC
    this.declarationsService.getConsultationDREV(this.idIntervenant, annee).subscribe(
      declarations => this.declarations = declarations,
      error => {
        try {
          if (error.error.code === 'BACCHUS_BUSINESS_NO_DR') {
            this.declarations.aocs = [];
            this.declarations.igps = [];
            return;
          }
        } catch (e) { }
        throw error;
      });
  }
}
