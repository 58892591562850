import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { StatutEchantillon } from 'src/app/data/declaration/models/statut-echantillon.model';
import { EchantillonsService } from 'src/app/data/declaration/services/echantillons/echantillons.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { Echantillon } from 'src/app/data/declaration/models/echantillon.model';
import * as moment from 'moment';
import { AnimateursService } from 'src/app/data/commission/services/animateurs/animateurs.service';
import { forkJoin } from 'rxjs';
import { Animateur } from 'src/app/data/commission/models/animateur.model';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SaisirPrelevementComponent } from './modals/saisir-prelevement/saisir-prelevement.component';
import { CategorieEnum } from 'src/app/data/edition/models/enums/categorie.enum.model';
import { TelechargerDocumentComponent } from '../../../main/components/telecharger-document/telecharger-document.component';
import { Fichier } from 'src/app/data/edition/models/fichier.model';
import { FileDownloadTools } from 'src/app/shared/file-download-tools/file-download-tools';

@Component({
  selector: 'app-list-echantillons',
  templateUrl: './list-echantillons.component.html',
  styleUrls: ['./list-echantillons.component.scss']
})
export class ListEchantillonsComponent implements OnInit {
  private idOrganisme: number;
  public animateurs: Animateur[];
  public echantillons: Echantillon[];
  public idmouvements: number[];
  public statuts: StatutEchantillon[];
  public formSearchEchantillons: FormGroup;
  pagination: {
    loadPages: (() => {})[];
    loadPrev: () => {};
    loadNext: () => {};
    length: number;
    start: number;
    end: number;
    maxRange: number;
    currentPage: number;
  };
  get statut() { return this.formSearchEchantillons.get('statut'); }
  get agentPrevu() { return this.formSearchEchantillons.get('agentPrevu'); }
  get debutDateButoir() { return this.formSearchEchantillons.get('debutDateButoir'); }
  get finDateButoir() { return this.formSearchEchantillons.get('finDateButoir'); }
  get debutDatePrelevement() { return this.formSearchEchantillons.get('debutDatePrelevement'); }
  get finDatePrelevement() { return this.formSearchEchantillons.get('finDatePrelevement'); }
  get debutDateCommission() { return this.formSearchEchantillons.get('debutDateCommission'); }
  get finDateCommission() { return this.formSearchEchantillons.get('finDateCommission'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly animateursService: AnimateursService,
    private readonly echantillonsService: EchantillonsService,
    private readonly referentielService: ReferentielService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalService: NgbModal
  ) { }

  ngOnInit() {
    // Récupérer l'ID organisme
    this.idOrganisme = SessionContext.get('idOrganisme');

    // Instancier le formulaire de recherche
    this.formSearchEchantillons = this.fb.group({
      statut: undefined,
      agentPrevu: undefined,
      debutDateButoir: undefined,
      finDateButoir: undefined,
      debutDatePrelevement: undefined,
      finDatePrelevement: undefined,
      debutDateCommission: undefined,
      finDateCommission: undefined
    });

    // Charger les données pour les listes déroulantes
    this.loadData();
  }

  /** Charge la liste des statuts et la liste des agents */
  private loadData(): void {
    forkJoin(
      this.animateursService.getAnimateurs(this.idOrganisme),
      this.referentielService.getReferentiel()
    ).subscribe(([animateurs, referentiel]) => {
      this.animateurs = animateurs;
      this.statuts = referentiel.echantillonStatuts;
      // Lancer recherche par défaut
      this.onSubmit();
    });
  }

  /**
   * Transforme une date provenant d'un Datepicker en date momentJS
   * @param date La date provenant d'un Datepicker
   * @see moment.Moment
   */
  private getMomentDate(date: {
    year: number,
    month: number,
    day: number
  }): moment.Moment {
    return (date) ? moment([date.year, date.month - 1, date.day, 0, 0, 0]) : null;
  }

  /**
   * Récupère la liste des échantillons en fonction des critères de
   * recherche du formulaire
   */
  public onSubmit(): void {
    const debutDateButoir = this.debutDateButoir.value;
    const finDateButoir = this.finDateButoir.value;
    const debutDatePrelevement = this.debutDatePrelevement.value;
    const finDatePrelevement = this.finDatePrelevement.value;
    const debutDateCommission = this.debutDateCommission.value;
    const finDateCommission = this.finDateCommission.value;
    const statuts = (this.statut.value) ? [this.statut.value] : [];
    const agents = (this.agentPrevu.value) ? [this.agentPrevu.value] : [];

    this.echantillonsService.getFilteredEchantillons(
      this.idOrganisme,
      statuts,
      agents,
      this.getMomentDate(debutDateButoir),
      this.getMomentDate(finDateButoir),
      this.getMomentDate(debutDatePrelevement),
      this.getMomentDate(finDatePrelevement),
      this.getMomentDate(debutDateCommission),
      this.getMomentDate(finDateCommission)
    ).subscribe(echantillons => {
      this.pagination = echantillons.pagination;
      this.echantillons = echantillons.response;
    });
  }

  /**
   * Permet d'ouvrir la fenêtre surgissante d'ajout d'un prélèvement à un échantillon
   * @param echantillon L'échantillon auquel on veut ajouter un prélèvement
   * @see Echantillon
   */
  public onAddPrelevement(echantillon: Echantillon): void {
    const modal = this.modalService.open(SaisirPrelevementComponent, { backdrop: 'static' });
    modal.componentInstance.idEchantillon = echantillon.id;

    modal.result.then(() => this.pagination.loadPages[this.pagination.currentPage]());
  }

  public telechargerDocument(echantillon: Echantillon) {
    const modalRef = this.modalService.open(TelechargerDocumentComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.echantillon = echantillon;
    modalRef.componentInstance.categorie = CategorieEnum.ECHANTILLON;
    modalRef.result.then(
      () => { },
      error => { throw error; }
    );
  }

  etiquettesPdf(): void {
    this.idmouvements = [];
    this.echantillons.forEach(ech => {      
      this.idmouvements.push(ech.idMouvement);
    });


    this.echantillonsService.getEtiquette(
      this.idmouvements
    ).subscribe(etiquettes => {
      
      this.echantillonsService.etiquettesPdf(SessionContext.get('idOrganisme'),etiquettes).subscribe((fichier: Fichier): void => {
      const fileURL = URL.createObjectURL(FileDownloadTools.b64ToBlob(fichier.base64, 'application/pdf'));
      window.open(fileURL);  
});
    });

    

  }
}
;   