import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { CommissionEchantillonOperateurEdition } from './commission-echantillon-operateur-edition.model';

@JsonObject
export class CommissionEchantillonEdition {
    @JsonProperty('id', Number)
    @Optional
    id: number;

    @JsonProperty('numero', String)
    @Optional
    numero: string;

    @JsonProperty('numeroLot', String)
    @Optional
    numeroLot: string;

    @JsonProperty('numeroAnonymat', String)
    @Optional
    numeroAnonymat: string;

    @JsonProperty('campagne', String)
    @Optional
    campagne: string;

    @JsonProperty('cahier', String)
    @Optional
    cahier: string;

    @JsonProperty('produit', String)
    @Optional
    produit: string;

    @JsonProperty('millesime', String)
    @Optional
    millesime: string;

    @JsonProperty('numeroJury', String)
    @Optional
    numeroJury: string;

    @JsonProperty('datePrelevement', String)
    @Optional
    datePrelevement: string;

    @JsonProperty('volume', String)
    @Optional
    volume: string;

    @JsonProperty('estConforme', String)
    @Optional
    estConforme: string;

    @JsonProperty('motifNonConformite', String)
    @Optional
    motifNonConformite: string;

    @JsonProperty('observation', String)
    @Optional
    observation: string;

    @JsonProperty('operateur', CommissionEchantillonOperateurEdition)
    @Optional
    operateur: CommissionEchantillonOperateurEdition;
}
