import { JsonProperty, JsonObject, Optional } from '@thorolf/json-ts-mapper';
import { Leurre } from './leurre.model';
import { Echantillon } from './echantillon.model';

@JsonObject
export class EchantillonJury {
  @JsonProperty('leurre', Leurre)
  @Optional
  leurre: Leurre;

  @JsonProperty('echantillon', Echantillon)
  @Optional
  echantillon: Echantillon;

  @JsonProperty('ordre', Number)
  ordre: number;

  @JsonProperty('numeroAnonymat', String)
  numeroAnonymat: string;

  @JsonProperty('degre', Number)
  @Optional
  degre: number;

  numeroJury: number;
}
