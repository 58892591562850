<div class="content">
  <div class="content-panel" *ngIf="monCompte">
    <div id="mesInformations">
      <h2 class="section-titles" translate><fa-icon icon="id-card" class="mt-2 p-2"></fa-icon>page.mon-compte.title.informations</h2>
      <div class="flex">
        <div class="section">
          <div *ngIf="monCompte.nom"><label><span translate>page.mon-compte.nom</span><span> : </span></label> {{monCompte.nom}}</div>
        </div>
        <div class="section">
          <div *ngIf="monCompte.prenom"><label><span translate>page.mon-compte.prenom</span><span> : </span></label> {{monCompte.prenom}}</div>
        </div>
        <div class="section">
          <div *ngIf="monCompte.email"><label><span translate>page.mon-compte.email</span><span> : </span></label> {{monCompte.email}}</div>
        </div>
      </div>
      <div class="flex">
        <div class="section">
        <button class="btnpwd" (click)="openModal()" translate>page.mon-compte.modifier-mot-passe.bouton</button>
      </div>
      <div class="section flex" *ngIf="showNotificationDocumentaire">
        <label>Abonnement notifications documentaire</label>
        &nbsp;&nbsp;
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="customCheck12" name="example1" (change)="changeNotificationDocumentaire()" [checked]="monCompte.notificationDocumentaire">
          <label class="custom-control-label" for="customCheck12" ></label>
        </div>
      </div>
      <div class="section"></div>
    </div>
    </div>
    <div class="margin-top" >
      <h2 class="section-titles" translate><fa-icon icon="id-card" class="mt-2 p-2"></fa-icon>page.mon-compte.title.entreprise</h2>
      <div class="flex">
        <div class="section">
          <div *ngIf="monCompte.raisonSociale"><label><span translate>page.mon-compte.entreprise.raisonSociale</span><span> : </span></label> {{monCompte.raisonSociale}}</div>
          <div *ngIf="monCompte.structureJuridique"><label><span translate>page.mon-compte.entreprise.structureJuridique</span><span> : </span></label> {{monCompte.structureJuridique}}</div>
          <div *ngIf="monCompte.siret"><label><span translate>page.mon-compte.entreprise.siret</span><span> : </span></label> {{monCompte.siret}}</div>
        </div>
        <div class="section">
          <div *ngIf="monCompte.emailEntreprise"><label class="entreprise-label"><span translate>page.mon-compte.entreprise.email</span><span> : </span></label> {{monCompte.emailEntreprise}}</div>
          <div *ngIf="monCompte.telephoneFixe"><label class="entreprise-label"><span translate>page.mon-compte.entreprise.telephoneFixe</span><span> : </span></label> {{monCompte.telephoneFixe | phoneFormat}}</div>
          <div *ngIf="monCompte.telephoneMobile"><label class="entreprise-label"><span translate>page.mon-compte.entreprise.telephoneMobile</span><span> : </span></label> {{monCompte.telephoneMobile | phoneFormat}}</div>
          <div *ngIf="monCompte.fax"><label class="entreprise-label"><span translate>page.mon-compte.entreprise.fax</span><span> : </span></label> {{monCompte.fax| phoneFormat}}</div>
        </div>
        <div class="section">
          <div *ngIf="monCompte.adresse"><label class="entreprise-label"><span translate>page.mon-compte.entreprise.adresse</span><span> : </span></label> {{monCompte.adresse}}</div>
          <div *ngIf="monCompte.codePostal"><label class="entreprise-label"><span translate>page.mon-compte.entreprise.codePostal</span><span> : </span></label> {{monCompte.codePostal}}</div>
          <div *ngIf="monCompte.ville"><label class="entreprise-label"><span translate>page.mon-compte.entreprise.ville</span><span> : </span></label> {{monCompte.ville}}</div>
          <div *ngIf="monCompte.cedex"><label class="entreprise-label"><span translate>page.mon-compte.entreprise.cedex</span><span> : </span></label> {{monCompte.cedex}}</div>
          <div *ngIf="monCompte.pays"><label class="entreprise-label"><span translate>page.mon-compte.entreprise.pays</span><span> : </span></label> {{monCompte.pays?.libelle}}</div>
        </div>
      </div>
    </div>
    <div class="margin-top" *ngIf="monCompte.groupementProducteurs">
      <h2 class="section-titles" translate><fa-icon icon="id-card" class="mt-2 p-2"></fa-icon>Mon groupement</h2>
      <div class="flex">
        <div class="section">
          <div *ngIf="monCompte.groupementProducteurs.raisonSociale"><label><span translate>page.mon-compte.entreprise.raisonSociale</span><span> : </span></label> {{monCompte.groupementProducteurs.raisonSociale}}</div>
          <div *ngIf="monCompte.groupementProducteurs.structureJuridique"><label><span translate>page.mon-compte.entreprise.structureJuridique</span><span> : </span></label> {{monCompte.groupementProducteurs.structureJuridique.libelle}}</div>
          <div *ngIf="monCompte.groupementProducteurs.siret"><label><span translate>page.mon-compte.entreprise.siret</span><span> : </span></label> {{monCompte.groupementProducteurs.siret}}</div>
        </div>
        <div class="section">
          <div *ngIf="monCompte.groupementProducteurs.mail"><label class="entreprise-label"><span translate>page.mon-compte.entreprise.email</span><span> : </span></label> {{monCompte.groupementProducteurs.mail}}</div>
          <div *ngIf="monCompte.groupementProducteurs.telephone"><label class="entreprise-label"><span translate>page.mon-compte.entreprise.telephoneFixe</span><span> : </span></label> {{monCompte.groupementProducteurs.telephone | phoneFormat}}</div>
          <div *ngIf="monCompte.groupementProducteurs.fax"><label class="entreprise-label"><span translate>page.mon-compte.entreprise.fax</span><span> : </span></label> {{monCompte.groupementProducteurs.fax| phoneFormat}}</div>
        </div>
        <div class="section">
          <div *ngIf="monCompte.groupementProducteurs.adresse"><label class="entreprise-label"><span translate>page.mon-compte.entreprise.adresse</span><span> : </span></label> {{monCompte.groupementProducteurs.adresse}}</div>
          <div *ngIf="monCompte.groupementProducteurs.codePostal"><label class="entreprise-label"><span translate>page.mon-compte.entreprise.codePostal</span><span> : </span></label> {{monCompte.groupementProducteurs.codePostal}}</div>
          <div *ngIf="monCompte.groupementProducteurs.commune"><label class="entreprise-label"><span translate>page.mon-compte.entreprise.ville</span><span> : </span></label> {{monCompte.groupementProducteurs.commune}}</div>
          <div *ngIf="monCompte.groupementProducteurs.cedex"><label class="entreprise-label"><span translate>page.mon-compte.entreprise.cedex</span><span> : </span></label> {{monCompte.groupementProducteurs.cedex}}</div>
          <div *ngIf="monCompte.groupementProducteurs.pays"><label class="entreprise-label"><span translate>page.mon-compte.entreprise.pays</span><span> : </span></label> {{monCompte.groupementProducteurs.pays?.libelle}}</div>
        </div>
      </div>
    </div>
    <div class="margin-top" *ngIf="monCompte.contacts">
      <h2 class="section-titles" translate><fa-icon icon="users" class="mt-2 p-2"></fa-icon>page.mon-compte.title.contacts</h2>
      <div id="liste-operateur-organismes">
        <div class="tableScroll">
        <table class="table table-striped" style="color: #212529;">
          <thead style="background: none; border: none;" class="sticky-top">
            <tr>
              <th translate>page.mon-compte.contact.table-header.civilite</th>
              <th translate>page.mon-compte.contact.table-header.nom</th>
              <th translate>page.mon-compte.contact.table-header.prenom</th>
              <th translate>page.mon-compte.contact.table-header.email</th>
              <th translate>page.mon-compte.contact.table-header.entreprise</th>
              <th translate>page.mon-compte.contact.table-header.referents</th>
              <th translate>page.mon-compte.contact.table-header.fonction</th>
              <th>Détail</th>
             </tr>
          </thead>
          <tbody>
            <tr *ngIf="monCompte.contacts.length === 0">
							<td colspan="10" translate class="p-4">
								page.mon-compte.noContact
							</td>
						</tr>
            <tr *ngFor="let contact of monCompte.contacts">
              <td>{{contact.civilite}}</td>
              <td>{{contact.nom}}</td>
              <td>{{contact.prenom}}</td>
              <td>{{contact.mail}}</td>
              <td>{{contact.entreprise.raisonSociale}}</td>
              <td>
                <div *ngFor="let ref of contact.referents">
                  <span class="badge badge-secondary badge-green"  [ngbTooltip]="ref.libelle">{{ref.code}} </span>
                </div>
                
              </td>
              <td>{{contact.fonction ? contact.fonction.libelle : ''}}</td>
              <td>
                <fa-icon icon="list" class="mt-2 p-2 cursor" style="font-size:20px" (click)="openModalContact(contact)"></fa-icon>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>
  </div>
</div>