import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, Routes } from "@angular/router";

import { forkJoin } from "rxjs";

import { AuthService } from "src/app/core/services/auth/auth.service";
import { SessionContext } from "src/app/core/services/config/app.settings";
import { UtilisateurTypeCode } from "src/app/data/intervenant/models/enums/type-utilisateur.enum";
import { AdministrationComponent } from "../administration/administration.component";
import { HabilitationsComponent } from "../habilitations/habilitations.component";
import { HomeComponent } from "../home/home.component";
import { OperateursComponent } from "../operateurs/operateurs.component";
import { DomainesService } from "src/app/data/intervenant/services/domaines/domaines.service";
import { OrganismesService } from "src/app/data/intervenant/services/organismes/organismes.service";
import { OperateurOrganismesComponent } from "../operateur-organismes/operateur-organismes.component";
import { DeclarationsComponent } from "../declarations/declarations.component";
import { DrevManquantesComponent } from "../drev-manquantes/drev-manquantes/drev-manquantes.component";
import { SuiviDeclarationsComponent } from "../suivi-declarations/suivi-declarations.component";
import { ControlesComponent } from "../controles/controles.component";
import { EditMdpComponent } from "../edit-mdp/edit-mdp.component";
import { FacturationComponent } from "../facturation/facturation.component";
import { EditionComponent } from "../edition/edition.component";
import { UtilisateursService } from "src/app/data/intervenant/services/utilisateurs/utilisateurs.service";
import { Utilisateur } from "src/app/data/intervenant/models/utilisateur.model";
import { OperateursService } from "src/app/data/intervenant/services/operateurs/operateurs.service";
import { SuivisComponent } from "../suivis/suivis.component";
import { MonCompteComponent } from "../mon-compte/mon-compte.component";
import { DocumentsComponent } from "../documents/documents.component";
import { EngagementsComponent } from "../engagements/engagements.component";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  static routes: Routes = [
    { path: "", component: MainComponent },
    { path: "home", children: HomeComponent.routes },
    { path: "operateurs", children: OperateursComponent.routes },
    //{ path: 'commissions', children: CommissionsComponent.routes },
    { path: "suivi-declarations", children: SuiviDeclarationsComponent.routes },
    { path: "controles", children: ControlesComponent.routes },
    { path: "facturation", children: FacturationComponent.routes },
    { path: "contacts", children: OperateurOrganismesComponent.routes },
    { path: "edition", children: EditionComponent.routes },
    { path: "habilitations", children: HabilitationsComponent.routes },
    { path: "declarations", children: DeclarationsComponent.routes },
    { path: "declarations-pp", children: DeclarationsComponent.routesAnpp },
    { path: "engagements", children: EngagementsComponent.routes },
    { path: "suivi", children: SuivisComponent.routesAnpp },
    { path: "drev-manquantes", children: DrevManquantesComponent.routes },
    { path: "edit-mdp", component: EditMdpComponent },
    { path: "admin", children: AdministrationComponent.routes },
    { path: "mon-compte", component: MonCompteComponent },
    { path: "ged", children: DocumentsComponent.routes },
  ];

  public canChangeScope = false;
  public canModifyPwd = false;
  isMaskedMenu: boolean = false;
  isAnpp: boolean = false;
  utilisateur: Utilisateur;
  public get displayPage() {
    return window.location.pathname !== "/main";
  }

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly domainesService: DomainesService,
    private readonly organismesService: OrganismesService,
    private readonly utilisateursService: UtilisateursService,
    private readonly operateurService: OperateursService
  ) {}

  ngOnInit() {
    this.isAnpp = SessionContext.get("codeDomaine") === "ANPP";
    if (window.location.pathname === "/main") {
      if (SessionContext.get("utilisateurType") === UtilisateurTypeCode.ADMIN) {
        this.router.navigate(["admin", "organismes"], {
          relativeTo: this.route,
        });
      } else if (
        SessionContext.get("utilisateurType") ===
          UtilisateurTypeCode.ORGANISME ||
        SessionContext.get("utilisateurType") === UtilisateurTypeCode.PI ||
        SessionContext.get("utilisateurType") === UtilisateurTypeCode.PO ||
        SessionContext.get("utilisateurType") === UtilisateurTypeCode.GP
      ) {
        this.router.navigate(["home"], { relativeTo: this.route });
      } else if (
        SessionContext.get("utilisateurType") === UtilisateurTypeCode.OC ||
        SessionContext.get("utilisateurType") === UtilisateurTypeCode.CT
      ) {
        this.router.navigate(["engagements/vergers-ecoresponsables"], {
          relativeTo: this.route,
        });
      } else {
        this.router.navigate(["operateurs/op/home"], {
          relativeTo: this.route,
        });
      }
    }
    if (
      SessionContext.get("utilisateurType") === UtilisateurTypeCode.ORGANISME
    ) {
      forkJoin(
        this.domainesService.getDomaines(),
        this.organismesService.getOrganismes()
      ).subscribe(([domaines, organismes]) => {
        this.canChangeScope = domaines.length > 1 || organismes.length > 1;
      });
      this.canModifyPwd = true;

      this.utilisateursService
        .getUtilisateur(SessionContext.get("idIntervenant"))
        .subscribe((utilisateur) => {
          this.utilisateur = utilisateur;
        });
    } else if (
      SessionContext.get("utilisateurType") === UtilisateurTypeCode.PI ||
      SessionContext.get("utilisateurType") === UtilisateurTypeCode.GP ||
      SessionContext.get("utilisateurType") === UtilisateurTypeCode.PO ||
      SessionContext.get("utilisateurType") === UtilisateurTypeCode.CT ||
      SessionContext.get("utilisateurType") === UtilisateurTypeCode.OC
    ) {
      this.canModifyPwd = true;
      this.utilisateursService
        .getUtilisateur(SessionContext.get("idIntervenant"))
        .subscribe((utilisateur) => {
          this.utilisateur = utilisateur;
        });
    }
  }

  public logOut(): void {
    this.authService.logOut();
  }
  public changeScope(): void {
    this.authService.changeScope();
  }
  public isAdmin(): boolean {
    return SessionContext.get("utilisateurType") === UtilisateurTypeCode.ADMIN;
  }

  editPwd() {
    this.router.navigate(["/main/edit-mdp"]);
  }

  goToMonCompte() {
    this.router.navigate(["/main/mon-compte"]);
  }

  showMenu(): void {
    this.isMaskedMenu = !this.isMaskedMenu;
  }
}
