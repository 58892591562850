import { AdvBootstrapLoaderService } from "@adv/bootstrap-loader";
import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Espece } from "src/app/data/declaration/models/espece.model";
import { RefCultureBio } from "src/app/data/declaration/models/ref-culture-bio.model";
import { RefForme } from "src/app/data/declaration/models/ref-forme.model";
import { RefPorteGreffe } from "src/app/data/declaration/models/ref-porte-greffe.model";
import { RefProtectionGel } from "src/app/data/declaration/models/ref-protection-gel.model";
import { RefProtectionGrele } from "src/app/data/declaration/models/ref-protection-grele.model";
import { RefSituation } from "src/app/data/declaration/models/ref-situation.model";
import { RefSystemeIrrigation } from "src/app/data/declaration/models/ref-systeme-irrigation.model";
import { Variete } from "src/app/data/declaration/models/variete.model";
import { EspeceService } from "src/app/data/declaration/services/parcellaire/espece.service";
import { RefCultureBioService } from "src/app/data/declaration/services/parcellaire/ref-culture-bio.service";
import { RefFormeService } from "src/app/data/declaration/services/parcellaire/ref-forme.service";
import { RefPorteGreffeService } from "src/app/data/declaration/services/parcellaire/ref-porte-greffe.service";
import { RefProtectionGelService } from "src/app/data/declaration/services/parcellaire/ref-protection-gel.service";
import { RefProtectionGreleService } from "src/app/data/declaration/services/parcellaire/ref-protection-grele.service";
import { RefSituationService } from "src/app/data/declaration/services/parcellaire/ref-situation.service";
import { RefSystemeIrrigationService } from "src/app/data/declaration/services/parcellaire/ref-systeme-irrigation.service";
import { VarieteService } from "src/app/data/declaration/services/parcellaire/variete.service";
import { ReferencesService } from "src/app/data/intervenant/services/references/references.service";

@Component({
  selector: "app-aide-import",
  templateUrl: "./aide-import.component.html",
  styleUrls: ["./aide-import.component.scss"],
})
export class AideImportComponent implements OnInit {
  constructor(
    private readonly refService: ReferencesService,
    public readonly modal: NgbActiveModal,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly refCultureBioService: RefCultureBioService,
    private readonly refFormeService: RefFormeService,
    private readonly refProtectionGelService: RefProtectionGelService,
    private readonly refProtectionGreleService: RefProtectionGreleService,
    private readonly refSituationService: RefSituationService,
    private readonly refSystemeIrrigationService: RefSystemeIrrigationService,
    private readonly refPorteGreffeService: RefPorteGreffeService,
    private readonly especeService: EspeceService,
    private readonly varieteService: VarieteService
  ) {}

  especes: Espece[];
  varietes: Variete[];
  varietesClone: Variete[];
  refCultureBios: RefCultureBio[];
  refFormes: RefForme[];
  refProtectionGels: RefProtectionGel[];
  refProtectionGreles: RefProtectionGrele[];
  refSituations: RefSituation[];
  refSystemeIrrigations: RefSystemeIrrigation[];
  refPorteGreffes: RefPorteGreffe[];

  ngOnInit() {
    this.refCultureBioService
      .getRefCultureBio()
      .pipe(this.loaderService.operator())
      .subscribe((refCultureBios) => {
        this.refCultureBios = refCultureBios;
        this.refCultureBios.sort((a, b) => {
          return a.libelle.localeCompare(b.libelle);
        });
      });
    this.refFormeService
      .getRefForme()
      .pipe(this.loaderService.operator())
      .subscribe((refFormes) => {
        this.refFormes = refFormes;
        this.refFormes.sort((a, b) => {
          return a.libelle.localeCompare(b.libelle);
        });
      });
    this.refProtectionGelService
      .getRefProtectionGel()
      .pipe(this.loaderService.operator())
      .subscribe((refProtectionGels) => {
        this.refProtectionGels = refProtectionGels;
        this.refProtectionGels.sort((a, b) => {
          return a.libelle.localeCompare(b.libelle);
        });
      });
    this.refProtectionGreleService
      .getRefProtectionGrele()
      .pipe(this.loaderService.operator())
      .subscribe((refProtectionsGreles) => {
        this.refProtectionGreles = refProtectionsGreles;
        this.refProtectionGreles.sort((a, b) => {
          return a.libelle.localeCompare(b.libelle);
        });
      });
    this.refSituationService
      .getRefSituation()
      .pipe(this.loaderService.operator())
      .subscribe((refSituations) => {
        this.refSituations = refSituations;
        this.refSituations.sort((a, b) => {
          return a.libelle.localeCompare(b.libelle);
        });
      });
    this.refSystemeIrrigationService
      .getRefSystemeIrrigation()
      .pipe(this.loaderService.operator())
      .subscribe((refSystemeIrrigations) => {
        this.refSystemeIrrigations = refSystemeIrrigations;
        this.refSystemeIrrigations.sort((a, b) => {
          return a.libelle.localeCompare(b.libelle);
        });
      });
    this.refPorteGreffeService
      .getRefPorteGreffe()
      .pipe(this.loaderService.operator())
      .subscribe((refPorteGreffes) => {
        this.refPorteGreffes = refPorteGreffes;
        this.refPorteGreffes.sort((a, b) => {
          return a.libelle.localeCompare(b.libelle);
        });
      });
    this.especeService
      .getEspeces()
      .pipe(this.loaderService.operator())
      .subscribe((especes) => {
        this.especes = especes.filter(
          (esp) => esp.code == "POIRE" || esp.code == "POMME"
        );
        this.especes.sort((a, b) => {
          return a.libelle.localeCompare(b.libelle);
        });
      });
    this.varieteService
      .getVarietes()
      .pipe(this.loaderService.operator())
      .subscribe((varietes) => {
        this.varietes = varietes;
        this.varietes.sort((a, b) => {
          return a.libelle.localeCompare(b.libelle);
        });
      });
    this.varieteService
      .getVarietesClone()
      .pipe(this.loaderService.operator())
      .subscribe((varietesClone) => {
        this.varietesClone = varietesClone;
        this.varietesClone.sort((a, b) => {
          return a.libelle.localeCompare(b.libelle);
        });
      });
  }

  cancel() {
    this.modal.dismiss();
  }
}
