import { Injectable } from '@angular/core';

import { CahiersService as HabCahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';

@Injectable({
  providedIn: 'root'
})

export class CahiersService extends HabCahiersService {
  protected BASE_URL = '/api/declaration';
}
