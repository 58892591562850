import { CustomConverter, JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";
import { DateConverter } from "src/app/core/services/mapper/converters";

@JsonObject
export class DeclarationExport {

    
    @JsonProperty('campagne', String)
    @Optional
    campagne: string;

    @JsonProperty('raisonSociale', String)
    @Optional
    raisonSociale: string;

    @JsonProperty('numeroDossier', String)
    @Optional
    numeroDossier: string;
    
    @JsonProperty('numeroCVI', String)
    @Optional
    numeroCVI: string;
    
    @JsonProperty('dateMvt', String)
    @CustomConverter(DateConverter)
    @Optional
    dateMvt: moment.Moment;
    
    @JsonProperty('logement', String)
    @Optional
    logement: string;
    
    @JsonProperty('numero', String)
    @Optional
    numero: string;
    
    @JsonProperty('cahierDesCharges', String)
    @Optional
    cahierDesCharges: string;
    
    @JsonProperty('produit', String)
    @Optional
    produit: string;
    
    @JsonProperty('couleur', String)
    @Optional
    couleur: string;
    
    @JsonProperty('mouvement', String)
    @Optional
    mouvement: string;
    
    @JsonProperty('volume', Number)
    @Optional
    volume: number;

}