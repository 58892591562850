import { JsonProperty, JsonObject, CustomConverter } from '@thorolf/json-ts-mapper';
import { TypeLeurre } from './type-leurre.model';
import { Defaut } from './defaut.model';
import { Conditionnement } from '../../declaration/models/conditionnement.model';
import { Produit } from '../../habilitation/models/produit.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';

@JsonObject
export class Leurre {
  @JsonProperty('codeProduit', String)
  codeProduit: string;

  produit: Produit;

  @JsonProperty('millesime', Number)
  millesime: number;

  @JsonProperty('idType', Number)
  @CustomConverter(DataIdConverterFactory.build(TypeLeurre, 'typesLeurres'))
  type: TypeLeurre;

  @JsonProperty('idsDefauts', [Number])
  @CustomConverter(DataIdConverterFactory.build(Defaut, 'defauts'))
  defauts: Defaut[];

  @JsonProperty('idConditionement', Number)
  @CustomConverter(DataIdConverterFactory.build(Conditionnement, 'conditionnements'))
  conditionnement: Conditionnement;
}
