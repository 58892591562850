import { Injectable } from '@angular/core';
import { Produit } from '../../models/produit.model';
import { HttpClient } from '@angular/common/http';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Observable, MonoTypeOperatorFunction, OperatorFunction } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ClearCache, Cache, CacheKey } from 'src/app/core/services/cache/cache.service';

interface ObjectWithProduitsList { codesProduit: string[]; produits: Produit[]; }
interface ObjectWithProduit { codeProduit: string; produit: Produit; }

@Injectable({
  providedIn: 'root'
})
export class ProduitsService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
  ) { }

  @ClearCache
  clearCache() { }

  @Cache()
  getProduitByCode(@CacheKey codeProduit: string): Observable<Produit> {
    // return this.http.get<object>(`/assets/mocks/produit-1.json`).pipe(
    return this.http.get<object>(`/api/habilitation/private/produits/${btoa(codeProduit)}`).pipe(
      map(produit => this.mapper.deserializeObject(produit, Produit))
    );
  }

  @Cache()
  getProduitsByCdcs(@CacheKey idOrganisme: number, @CacheKey idsCdcs: number[]): Observable<Produit[]> {
    return this.http.get<object[]>(`/api/habilitation/private/organismes/${idOrganisme}/produits?cdc=${idsCdcs.join(',')}`).pipe(
      map(produits => this.mapper.deserializeArray(produits, Produit))
    );
  }

  getProduitPipe<T extends ObjectWithProduit | ObjectWithProduit[]>(): OperatorFunction<T, T> {
    return tap((list: T) => {
      const liste: ObjectWithProduit[] = [].concat(list);

      liste.forEach(item => {
        this.getProduitByCode(item.codeProduit)
          .subscribe(produit => item.produit = produit);
      });
    });
  }

  getProduitsPipe<T extends ObjectWithProduitsList | ObjectWithProduitsList[]>(): OperatorFunction<T, T> {
    return tap((list: T) => {
      const liste: ObjectWithProduitsList[] = [].concat(list);

      liste.forEach(item => {
        item.codesProduit.forEach(codeProduit => {
          this.getProduitByCode(codeProduit).subscribe(
            produit => item.produits.push(produit)
          );
        });
      });
    });
  }
}
