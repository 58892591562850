import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Site } from '../../models/site.model';
import { map } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { SitesActivitesService } from '../sites-activites/sites-activites.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';

@Injectable({
  providedIn: 'root'
})
export class OperateurSitesService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly sitesActivitesService: SitesActivitesService
  ) { }

  public getSitesOperateur(idOperateur: number): Observable<Site[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/declaration/private/operateurs/${idOperateur}/sites`),
      this.sitesActivitesService.getSitesAcitivtes(SessionContext.get('idDomaine'))
    ).pipe(
      map(([sites, sitesActivites]) => this.mapper.deserializeArray(sites, Site, { sitesActivites }))
    );
  }

  public creerSiteOperateur(idOperateur: number, site: Site): Observable<number> {
    return this.http.post(`/api/declaration/private/operateurs/${idOperateur}/sites`, this.mapper.serialize(site), { observe: 'response' }).pipe(
      map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
    );
  }

  public modifierSiteOperateur(idOperateur: number, site: Site): Observable<void> {
    return this.http.put(`/api/declaration/private/operateurs/${idOperateur}/sites/${site.id}`, this.mapper.serialize(site)).pipe(
      map(() => { })
    );
  }

  public supprimerSiteOperateur(idOperateur: number, idSite: number): Observable<void> {
    return this.http.delete(`/api/declaration/private/operateurs/${idOperateur}/sites/${idSite}`).pipe(
      map(() => { })
    );
  }
}
