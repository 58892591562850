import {Component, OnInit} from '@angular/core';
import {Routes, ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddBlocComponent} from './modals/add-bloc/add-bloc.component';
import {AdvBootstrapLoaderService} from '@adv/bootstrap-loader';
import {forkJoin, from, Observable, of} from 'rxjs';
import {UniteCulturale} from '../../../../../data/declaration/models/unite-culturale.model';
import {SelectFieldsColumnComponent} from './modals/select-fields-column/select-fields-column.component';
import {Localisation} from '../../../../../data/declaration/models/localisation.model';
import {Espece} from '../../../../../data/declaration/models/espece.model';
import {Variete} from '../../../../../data/declaration/models/variete.model';
import {UniteCulturaleService} from '../../../../../data/declaration/services/parcellaire/unite-culturale.service';
import {CaracteristiqueParcelle} from '../../../../../data/declaration/models/caracteristique-parcelle.model';
import {TranslateService} from '@ngx-translate/core';
import {RefCultureBio} from '../../../../../data/declaration/models/ref-culture-bio.model';
import {RefCultureBioService} from '../../../../../data/declaration/services/parcellaire/ref-culture-bio.service';
import {RefFormeService} from '../../../../../data/declaration/services/parcellaire/ref-forme.service';
import {RefProtectionGelService} from '../../../../../data/declaration/services/parcellaire/ref-protection-gel.service';
import {
  RefProtectionGreleService
} from '../../../../../data/declaration/services/parcellaire/ref-protection-grele.service';
import {RefSituationService} from '../../../../../data/declaration/services/parcellaire/ref-situation.service';
import {
  RefSystemeIrrigationService
} from '../../../../../data/declaration/services/parcellaire/ref-systeme-irrigation.service';
import {RefForme} from '../../../../../data/declaration/models/ref-forme.model';
import {RefProtectionGel} from '../../../../../data/declaration/models/ref-protection-gel.model';
import {RefProtectionGrele} from '../../../../../data/declaration/models/ref-protection-grele.model';
import {RefSituation} from '../../../../../data/declaration/models/ref-situation.model';
import {RefSystemeIrrigation} from '../../../../../data/declaration/models/ref-systeme-irrigation.model';
import {RefPorteGreffeService} from '../../../../../data/declaration/services/parcellaire/ref-porte-greffe.service';
import {RefPorteGreffe} from '../../../../../data/declaration/models/ref-porte-greffe.model';
import {EspeceService} from '../../../../../data/declaration/services/parcellaire/espece.service';
import {VarieteService} from '../../../../../data/declaration/services/parcellaire/variete.service';
import {ValidInventaireComponent} from './modals/valid-inventaire/valid-inventaire.component';
import {SurgreffageComponent} from './modals/surgreffage/surgreffage.component';
import {ArrachageComponent} from './modals/arrachage/arrachage.component';
import {remove} from 'lodash';
import {CultureBioComponent} from './modals/culture-bio/culture-bio.component';
import {AdvBootstrapModalService} from '@adv/bootstrap-modal';
import {ChangerProducteurComponent} from './modals/changer-producteur/changer-producteur.component';
import {ExportVergerComponent} from './modals/export-verger/export-verger.component';
import {DeclarationsService} from 'src/app/data/declaration/services/declarations/declarations.service';
import {UtilisateursService} from 'src/app/data/intervenant/services/utilisateurs/utilisateurs.service';
import {SessionContext} from 'src/app/core/services/config/app.settings';
import {Entreprise} from 'src/app/data/intervenant/models/entreprise.model';
import {ToastrService} from 'ngx-toastr';
import {OperateursService} from '../../../../../data/intervenant/services/operateurs/operateurs.service';
import {UtilisateurTypeCode} from '../../../../../data/intervenant/models/enums/type-utilisateur.enum';
import {LocalisationsComponent} from './modals/localisations/localisations.component';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { RecoltesService } from 'src/app/data/declaration/services/recoltes/recoltes.service';
import { RefCampagnesService } from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';
import { map } from 'rxjs/operators';
import { SyntheseInventaireEdition } from 'src/app/data/edition/models/synthese-inventaire-edition';
import { DatePipe } from '@angular/common';
import { SyntheseInventaireEspeceEdition } from 'src/app/data/edition/models/synthese-inventaire-espece-edition';
import { SyntheseInventaireUniteEdition } from 'src/app/data/edition/models/synthese-inventaire-unite-edition';

@Component({
  selector: 'app-parcellaires',
  templateUrl: './parcellaires.component.html',
  styleUrls: ['./parcellaires.component.scss']
})
export class ParcellairesComponent implements OnInit {

  constructor(
    private readonly modal: NgbModal,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly uniteCulturaleService: UniteCulturaleService,
    private readonly operateursService: OperateursService,
    private readonly refCultureBioService: RefCultureBioService,
    private readonly refFormeService: RefFormeService,
    private readonly refProtectionGelService: RefProtectionGelService,
    private readonly refProtectionGreleService: RefProtectionGreleService,
    private readonly refSituationService: RefSituationService,
    private readonly refSystemeIrrigationService: RefSystemeIrrigationService,
    private readonly refPorteGreffeService: RefPorteGreffeService,
    private readonly especeService: EspeceService,
    private readonly varieteService: VarieteService,
    private readonly translate: TranslateService,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly declarationService: DeclarationsService,
    private readonly route: ActivatedRoute,
    private readonly utilisateursService: UtilisateursService,
    private readonly toastr: ToastrService,
    private readonly entrepriseService: EntreprisesService,
    private readonly recoltesService: RecoltesService,
    private readonly refCampagneService: RefCampagnesService,
    public datepipe: DatePipe
  ) {
    this.loadHeader();
  }

  static routes: Routes = [
    {
      path: '', component: ParcellairesComponent, data: {
        role: 'ORGANISME || DECLARATION_INVENTAIRE_VERGER_LECTURE',
        domaines: ['ANPP'],
        menu: {
          libelle: 'menu.parcellaires',
          icon: 'key'
        }
      }
    },
    {
      path: ':idEntreprise', component: ParcellairesComponent, data: {
        role: 'ORGANISME',
        domaines: ['ANPP'],
      }
    }

  ];

  uniteCulturalesSelectionnees: UniteCulturale[] = [];
  unitesCulturales: UniteCulturale[];
  uniteCulturaleFilter: UniteCulturale[];
  localisations: Localisation[];
  especes: Espece[];
  varietes: Variete[];
  varietesClone: Variete[];
  refCultureBios: RefCultureBio[];
  refFormes: RefForme[];
  refProtectionGels: RefProtectionGel[];
  refProtectionGreles: RefProtectionGrele[];
  refSituations: RefSituation[];
  refSystemeIrrigations: RefSystemeIrrigation[];
  refPorteGreffes: RefPorteGreffe[];
  caracteristiquesParcelles: CaracteristiqueParcelle[];
  filtrer = true;
  numBlocFilter: string;
  infosTracabiliteFilter: string;
  codeParcelleFilter: string;
  refCadastraleFilter: string;
  numTracabiliteFilter: string;
  lieuDitFilter: string;
  communeFilter: string;
  especeFilter: Espece;
  varieteFilter: Variete;
  cloneFilter: Variete;
  porteGreffeFilter: RefPorteGreffe;
  surfaceCalculeFilter: number;
  anneeFilter: number;
  anneeSurgreffageFilter: number;
  anneePlantationFilter: number;
  anneePremiereFeuilleFilter: number;
  cultureBioFilter: RefCultureBio;
  departementFilter: string;
  formeFilter: RefForme;
  distanceInterRangFilter: number;
  distanceIntraRangFilter: number;
  nbArbreFilter: number;
  systemeIrrigationFilter: RefSystemeIrrigation;
  protectionGelFilter: RefProtectionGel;
  protectionGreleFilter: RefProtectionGrele;
  situationFilter: RefSituation;
  confusionSexuelFilter: boolean;

  uniteCulturaleCheck: UniteCulturale;
  surface: number;
  dateLastMaj: Date;
  auteurLastModif: string;
  surgreffagePartielBool: boolean;
  modifierBlocBool: boolean;

  headers = [];

  adherent: Entreprise;
  producteur: Entreprise;
  showProducteurBarre: boolean;

  typeUtilisateurConnecte: UtilisateurTypeCode;

  warningRecolte:boolean = false;
  warningRecolteToolTip:string = undefined;

  /**
   * chargement du groupement et/ou entreprise passés en paramètre (depuis suivi inventaires vergers)
   * @param idGp
   * @param idEntreprise
   * @returns
   */
   loadFromUrlParams(idGp:string, idEntreprise:string): Observable<[Entreprise, Entreprise]> {
    if (idGp && idEntreprise) {
      return forkJoin([this.entrepriseService.getEntreprise(Number.parseInt(idGp)),
                      this.entrepriseService.getEntreprise(Number.parseInt(idEntreprise))]);
    } else if (idEntreprise) {
      return forkJoin([of(undefined),
                      this.entrepriseService.getEntreprise(Number.parseInt(idEntreprise))
      ]);
    } else {
      return forkJoin([of(undefined), of(undefined)]);
    }
  }



  ngOnInit() {
    this.typeUtilisateurConnecte = SessionContext.get('utilisateurType');
    this.declarationService.setActiveTabId('vergers');


    // récupération des paramètres de l'url (si depuis suivi inventaire vergers)
    let gp = this.route.snapshot.queryParamMap.get('gp');
    let id = this.route.snapshot.queryParamMap.get('id');

    this.loadFromUrlParams(gp, id).subscribe(([gp, entreprise]) => {
      if (gp && entreprise) {
        // si GP + PO
        this.declarationService.entrepriseSelectionnee = gp;
        this.showProducteurBarre = true;
        this.selectItem(entreprise);
      } else if (entreprise) {
        // si PI
        if (entreprise.refSousType.code === 'GP_DEFAUT' || entreprise.refSousType.code === 'PI_DEFAUT') {
          this.declarationService.entrepriseSelectionnee = entreprise;
        }
      }

      if (this.declarationService.entrepriseSelectionnee) {
        this.adherent = this.declarationService.entrepriseSelectionnee;

        if (this.adherent.refSousType.code === 'PI_DEFAUT') {
          this.producteur = this.adherent;
          this.loadHeader();
          this.uniteCulturalesSelectionnees = [];
          this.getUnitesCulturales();
          this.loadDataRef();
        } else {
          this.showProducteurBarre = true;
        }
      } else {
        this.utilisateursService.getUtilisateur(SessionContext.get('idIntervenant')).subscribe(utilisateur => {
          if (!utilisateur || (utilisateur && !utilisateur.entreprise)) {
            this.adherent = null;
            this.declarationService.entrepriseSelectionnee = null;
            this.producteur = null;
          } else {

            if (utilisateur.entreprise.refSousType.code === 'PI_DEFAUT' || utilisateur.entreprise.refSousType.code === 'PO_DEFAUT') {
              this.declarationService.entrepriseSelectionnee = utilisateur.entreprise;
              this.adherent = utilisateur.entreprise;
              this.producteur = utilisateur.entreprise;
              if (this.adherent) {
                this.loadHeader();
                this.uniteCulturalesSelectionnees = [];
                this.getUnitesCulturales();
                this.loadDataRef();
              }

            } else {
              this.adherent = utilisateur.entreprise;
              this.showProducteurBarre = true;
            }

          }
        });
      }
    });
  }

  getUnitesCulturales() {
    this.uniteCulturalesSelectionnees = [];
    this.uniteCulturaleService.getUniteCulturale(this.producteur.id).pipe(this.loaderService.operator()).subscribe((unitesCulturales) => {
      this.unitesCulturales = unitesCulturales.sort((a, b) => a.numBlocFruitier > b.numBlocFruitier ? 1 : -1);
      this.uniteCulturaleFilter = unitesCulturales.sort((a, b) => a.numBlocFruitier > b.numBlocFruitier ? 1 : -1);
      this.calculerSurface();
      if (this.unitesCulturales.length > 0) {
        this.getLastDateMaJInventaire();

      }
      this.getRecoltesInfos();
    });
  }

  getLastDateMaJInventaire() {
    this.uniteCulturaleService.getDateLastModifInventaireVerger(this.producteur.id).subscribe((infos) => {
      this.dateLastMaj = infos.dateDerniereMajInventaire;
      this.operateursService.getOperateur(infos.idAuteur)
        .subscribe(operateur => this.auteurLastModif = operateur.prenom + ' ' + operateur.nom);
    });
  }

  loadDataRef() {
    this.refCultureBioService.getRefCultureBio().pipe(this.loaderService.operator()).subscribe((refCultureBios) => {
      this.refCultureBios = refCultureBios;
      this.refCultureBios.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
    });
    this.refFormeService.getRefForme().pipe(this.loaderService.operator()).subscribe((refFormes) => {
      this.refFormes = refFormes;
      this.refFormes.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
    });
    this.refProtectionGelService.getRefProtectionGel().pipe(this.loaderService.operator()).subscribe((refProtectionGels) => {
      this.refProtectionGels = refProtectionGels;
      this.refProtectionGels.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
    });
    this.refProtectionGreleService.getRefProtectionGrele().pipe(this.loaderService.operator()).subscribe((refProtectionsGreles) => {
      this.refProtectionGreles = refProtectionsGreles;
      this.refProtectionGreles.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
    });
    this.refSituationService.getRefSituation().pipe(this.loaderService.operator()).subscribe((refSituations) => {
      this.refSituations = refSituations;
      this.refSituations.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
    });
    this.refSystemeIrrigationService.getRefSystemeIrrigation().pipe(this.loaderService.operator()).subscribe((refSystemeIrrigations) => {
      this.refSystemeIrrigations = refSystemeIrrigations;
      this.refSystemeIrrigations.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
    });
    this.refPorteGreffeService.getRefPorteGreffe().pipe(this.loaderService.operator()).subscribe((refPorteGreffes) => {
      this.refPorteGreffes = refPorteGreffes;
      this.refPorteGreffes.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
    });
    this.especeService.getEspeces().pipe(this.loaderService.operator()).subscribe((especes) => {
      this.especes = especes;
      this.especes.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
    });
    this.varieteService.getVarietes().pipe(this.loaderService.operator()).subscribe((varietes) => {
      this.varietes = varietes;
      this.varietes.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
    });
    this.varieteService.getVarietesClone().pipe(this.loaderService.operator()).subscribe((varietesClone) => {
      this.varietesClone = varietesClone;
      this.varietesClone.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
    });
  }

  /**
   * récupération des donnnées récoltes pour affichage warning bio et inventaire non validé
   */
  getRecoltesInfos(){

    this.refCampagneService.getCampagneEnCours().subscribe(campagne =>{
      let campagneEnCours = campagne;
      forkJoin([
      this.recoltesService.getRecoltes(this.declarationService.entrepriseSelectionnee.id, campagneEnCours.id),
      this.uniteCulturaleService.getDateLastModifInventaireVergerEntreprisesLiees(this.declarationService.entrepriseSelectionnee.id)
      ]).subscribe(([declaRecolte, infos ]) => {
        let alerteBioDeclaRecolte = declaRecolte.recoltes.filter(r => r.qteBio && r.qteBio > 0).length > 0 && declaRecolte.recoltes.filter(r => r.surfaceBio && r.surfaceBio > 0).length === 0;
        let messagesWarning = [];
        this.warningRecolte =false;
        if (alerteBioDeclaRecolte){
          messagesWarning.push(this.translate.instant(`page.recoltesPp.alert.alerteSaisieBio`));
          this.warningRecolte = true;
        }
        if (!infos || !infos.dateDerniereMajInventaire){
          messagesWarning.push(this.translate.instant(`page.recoltesPp.alert.erreurInventaireVerger`));
          this.warningRecolte = true;
        }
        this.warningRecolteToolTip = messagesWarning.join("<br/>");
      })
    });

  }

  showFilter() {
    this.filtrer = !this.filtrer;
  }

  addBloc() {
    const modalRef = this.modal.open(AddBlocComponent, {backdrop: 'static', windowClass: 'app-modal-1001px'});
    modalRef.componentInstance.refProtectionGels = this.refProtectionGels;
    modalRef.componentInstance.refSystemeIrrigations = this.refSystemeIrrigations;
    modalRef.componentInstance.refSituations = this.refSituations;
    modalRef.componentInstance.refProtectionGreles = this.refProtectionGreles;
    modalRef.componentInstance.refFormes = this.refFormes;
    modalRef.componentInstance.refCultureBios = this.refCultureBios;
    modalRef.componentInstance.especes = this.especes;
    // modalRef.componentInstance.varietes = this.varietes;
    modalRef.componentInstance.varietesClone = this.varietesClone;
    modalRef.componentInstance.refPorteGreffes = this.refPorteGreffes;
    modalRef.componentInstance.idEntreprise = this.producteur.id;

    from(modalRef.result).subscribe(() => {
      this.getUnitesCulturales();
    });
  }

  showList() {
    const modalRef = this.modal.open(SelectFieldsColumnComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.headers = this.headers;

    from(modalRef.result).subscribe(headers => {
      this.headers = headers;
    });
  }

  show(obj) {
    return (this.headers.find(h => h.name === obj)).checked;
  }

  filter() {
    if (this.unitesCulturales && this.unitesCulturales.length) {
      this.uniteCulturaleFilter = this.unitesCulturales.filter(item => {
        if ((this.show('infosTracabilite') && this.infosTracabiliteFilter)) {
          if (item.getInfosTracabilite()) {
            if (item.getInfosTracabilite().toLowerCase().indexOf(this.infosTracabiliteFilter.toLowerCase()) === -1) {
              return false;
            }
          } else {
            return false;
          }
        }
        if ((this.show('numBloc') && this.numBlocFilter)) {
          if (item.numBlocFruitier) {
            if (item.numBlocFruitier.toLowerCase().indexOf(this.numBlocFilter.toLowerCase()) === -1) {
              return false;
            }
          } else {
            return false;
          }
        }
        if ((this.show('codeParcelle') && this.codeParcelleFilter)) {
          if (item.codeParcelle) {
            if (item.codeParcelle.toLowerCase().indexOf(this.codeParcelleFilter.toLowerCase()) === -1) {
              return false;
            }
          } else {
            return false;
          }
        }
        if ((this.show('refCadastrale') && this.refCadastraleFilter)) {
          if (item.localisation.referenceCadastrale) {
            if (item.localisation.referenceCadastrale.toLowerCase().indexOf(this.refCadastraleFilter.toLowerCase()) === -1) {
              return false;
            }
          } else {
            return false;
          }
        }

        if ((this.show('numTracabilite') && this.numTracabiliteFilter)) {
          if (item.numTracabilite) {
            if (item.numTracabilite.toLowerCase().indexOf(this.numTracabiliteFilter.toLowerCase()) === -1) {
              return false;
            }
          } else {
            return false;
          }
        }
        if ((this.show('lieuDit') && this.lieuDitFilter)) {
          if (item.localisation.lieuDit) {
            if (item.localisation.lieuDit.toLowerCase().indexOf(this.lieuDitFilter.toLowerCase()) === -1) {
              return false;
            }
          } else {
            return false;
          }
        }
        if ((this.show('commune') && this.communeFilter)) {
          if (item.localisation.commune) {
            if (item.localisation.commune.toLowerCase().indexOf(this.communeFilter.toLowerCase()) === -1) {
              return false;
            }
          } else {
            return false;
          }
        }
        if ((this.show('espece')) && this.especeFilter && ( item.espece.id !== this.especeFilter.id)) {
          return false;
        }
        if ((this.show('variete')) && this.varieteFilter && (
          item.variete.varieteClone ? item.variete.varieteClone.id !== this.varieteFilter.id : item.variete.id !== this.varieteFilter.id)) {
          return false;
        }
        if ((this.show('clone')) && (this.cloneFilter)) {
          if (item.variete == null || item.variete.id !== this.cloneFilter.id) {
          return false;
        }
        }
        if ((this.show('porteGreffe')) && (this.porteGreffeFilter)) {
          if (item.porteGreffe == null || item.porteGreffe.id !== this.porteGreffeFilter.id) {
            return false;
          }
        }
        if ((this.show('surfaceCalcule')) && (this.surfaceCalculeFilter && +item.surfaceCalculee !== +this.surfaceCalculeFilter)) {
          return false;
        }
        if ((this.show('annee') && this.anneeFilter)) {
          if (item.getInfosAnnee()) {
            if (+item.getInfosAnnee() !== +this.anneeFilter) {
              return false;
            }
          } else {
            return false;
          }
        }
        if ((this.show('anneeSurgreffage')) && (this.anneeSurgreffageFilter && +item.anneeSurgreffage !== +this.anneeSurgreffageFilter)) {
          return false;
        }
        if ((this.show('anneePlantation')) && (this.anneePlantationFilter && +item.anneePlantation !== +this.anneePlantationFilter)) {
          return false;
        }
        if ((this.show('anneePremiereFeuille')) && (this.anneePremiereFeuilleFilter && +item.anneePremiereFeuille !== +this.anneePremiereFeuilleFilter)) {
          return false;
        }
        if ((this.show('cultureBio')) && (this.cultureBioFilter)) {
          if (item.caracteristiqueParcelle.refCultureBio == null || item.caracteristiqueParcelle.refCultureBio.id !== this.cultureBioFilter.id) {
            return false;
          }
        }
        if ((this.show('departement') && this.departementFilter)) {
          if (item.localisation.departement) {
            if (item.localisation.departement.toLowerCase().indexOf(this.departementFilter.toLowerCase()) === -1) {
              return false;
            }
          } else {
            return false;
          }
        }

        if ((this.show('forme')) && (this.formeFilter)) {
          if (item.caracteristiqueParcelle.refForme == null || item.caracteristiqueParcelle.refForme.id !== this.formeFilter.id) {
            return false;
          }
        }
        if ((this.show('distanceInterRang')) && (this.distanceInterRangFilter && +item.distanceInterRang !== +this.distanceInterRangFilter)) {
          return false;
        }
        if ((this.show('distanceIntraRang')) && (this.distanceIntraRangFilter && +item.distanceIntraRang !== +this.distanceIntraRangFilter)) {
          return false;
        }
        if ((this.show('nbArbre')) && (this.nbArbreFilter && +item.nbArbre !== +this.nbArbreFilter)) {
          return false;
        }
        if ((this.show('systemeIrrigation')) && (this.systemeIrrigationFilter)) {
          if (item.caracteristiqueParcelle.refSystemeIrrigation == null || item.caracteristiqueParcelle.refSystemeIrrigation.id !== this.systemeIrrigationFilter.id) {
            return false;
          }
        }
        if ((this.show('protectionGel')) && (this.protectionGelFilter)) {
          if (item.caracteristiqueParcelle.refProtectionGel == null || item.caracteristiqueParcelle.refProtectionGel.id !== this.protectionGelFilter.id) {
            return false;
          }
        }
        if ((this.show('protectionGrele')) && (this.protectionGreleFilter)) {
          if (item.caracteristiqueParcelle.refProtectionGrele == null || item.caracteristiqueParcelle.refProtectionGrele.id !== this.protectionGreleFilter.id) {
            return false;
          }
        }
        if ((this.show('situation')) && (this.situationFilter)) {
          if (item.caracteristiqueParcelle.refSituation == null || item.caracteristiqueParcelle.refSituation.id !== this.situationFilter.id) {
            return false;
          }
        }
        if ((this.show('confusion')) && (this.confusionSexuelFilter && item.confusion !== this.confusionSexuelFilter)) {
          return false;
        }
        return true;
      });
    }
  }


  onCheckboxChange(uniteCulturale: UniteCulturale): void {

    if (this.uniteCulturalesSelectionnees.includes(uniteCulturale)) {
      remove(this.uniteCulturalesSelectionnees, uniteCulturale);
    } else {
      this.uniteCulturalesSelectionnees.push(uniteCulturale);
    }
  }

  supprimerUniteCulturale(uniteCulturale: UniteCulturale) {
    this.modalsService.confirm(
      this.translate.instant(`page.inventaire-verger.modalConfirmDeleteText`),
      this.translate.instant(`page.inventaire-verger.modalConfirmDeleteTitle`), {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.valider`),
       windowClass: 'anpp'
    }
    ).then(() => {
      this.uniteCulturaleService.supprimerUniteCulturale(this.producteur.id, uniteCulturale.id).pipe(this.loaderService.operator()).subscribe(() => {
        this.getUnitesCulturales();
      });
    });
  }

  arrachageTotal(uniteCulturale: UniteCulturale) {
    this.modalsService.confirm(
      this.translate.instant(`page.inventaire-verger.modals.arrachage.modalConfirmArrachageText`),
      this.translate.instant(`page.inventaire-verger.modals.arrachage.modalConfirmArrachageTitle`), {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.valider`),
        windowClass: 'anpp'
    }
    ).then(() => {
      this.uniteCulturaleService.supprimerUniteCulturale(this.producteur.id, uniteCulturale.id).pipe(this.loaderService.operator()).subscribe(() => {
        this.getUnitesCulturales();
      });
    });
  }

  validInventaire() {
    const modalRef = this.modal.open(ValidInventaireComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.idEntreprise = this.producteur.id;
  }


  surgreffageTotalPartiel(uniteCulturale: UniteCulturale, partiel: boolean) {
    this.surgreffagePartielBool = partiel;
    const modalRef = this.modal.open(SurgreffageComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.uniteCulturale = uniteCulturale;
    modalRef.componentInstance.varietes = this.varietes;
    modalRef.componentInstance.surgreffagePartielBool = this.surgreffagePartielBool;
    from(modalRef.result).subscribe(() => {
      this.getUnitesCulturales();
    });

  }

  arrachagePartiel(uniteCulturale: UniteCulturale) {
    const modalRef = this.modal.open(ArrachageComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.uniteCulturale = uniteCulturale;
    from(modalRef.result).subscribe(() => {
      this.getUnitesCulturales();
    });

  }

  dupliquerUniteCulturale(uniteCulturale: UniteCulturale) {
    this.uniteCulturaleService.dupliquerUniteCulturale(uniteCulturale.id).pipe(this.loaderService.operator()).subscribe(() => {
      this.getUnitesCulturales();
    });
  }

  modifierUniteCulturale(uniteCulturale: UniteCulturale) {
    this.modifierBlocBool = true;
    const modalRef = this.modal.open(AddBlocComponent, {backdrop: 'static', windowClass: 'app-modal-1001px'});
    modalRef.componentInstance.refProtectionGels = this.refProtectionGels;
    modalRef.componentInstance.refSystemeIrrigations = this.refSystemeIrrigations;
    modalRef.componentInstance.refSituations = this.refSituations;
    modalRef.componentInstance.refProtectionGreles = this.refProtectionGreles;
    modalRef.componentInstance.refFormes = this.refFormes;
    modalRef.componentInstance.refCultureBios = this.refCultureBios;
    modalRef.componentInstance.especes = this.especes;
    this.varieteService.getVarietesEspece(uniteCulturale.espece.id).pipe(this.loaderService.operator()).subscribe((varietes) => {
      varietes.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
      modalRef.componentInstance.varietes = varietes;
    });

    this.refPorteGreffeService.getRefPorteGreffeByIdEspece(uniteCulturale.espece.id).pipe(this.loaderService.operator()).subscribe((refPorteGreffes) => {
      refPorteGreffes.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
      modalRef.componentInstance.refPorteGreffes = refPorteGreffes;
    });
    modalRef.componentInstance.varietesClone = this.varietesClone;
    modalRef.componentInstance.uniteCulturale = uniteCulturale;
    modalRef.componentInstance.modifierBlocBool = this.modifierBlocBool;
    modalRef.componentInstance.idEntreprise = this.producteur.id;

    from(modalRef.result).subscribe(() => {
      this.getUnitesCulturales();
    });

  }

  selectAllUniteCulturale(event: any): void {
    this.uniteCulturalesSelectionnees = [];
    if (event.target.checked) {
      this.uniteCulturaleFilter.forEach(uniteCulturale => {
        this.uniteCulturalesSelectionnees.push(uniteCulturale);
      });
    }
  }

  supprimerEnMasseUC() {
    if (this.uniteCulturalesSelectionnees.length > 0) {
      this.modalsService.confirm(
        this.translate.instant(`page.inventaire-verger.modalConfirmDeleteMasseText`),
        this.translate.instant(`page.inventaire-verger.modalConfirmDeleteMasseTitle`), {
        cancelText: this.translate.instant(`label.annuler`),
        submitText: this.translate.instant(`label.valider`),
          windowClass: 'anpp'
      }
      ).then(() => {
        this.uniteCulturaleService.supprimerListeUniteCulturale(this.producteur.id, this.uniteCulturalesSelectionnees).pipe(this.loaderService.operator()).subscribe(() => {
          this.getUnitesCulturales();
        });
      });
    } else {
      this.modalsService.error(this.translate.instant(`page.inventaire-verger.actionMasseError`), null , {windowClass: 'anpp'}).then(() => {
      });
    }
  }

  cultureBioEnMasseUC() {
    if (this.uniteCulturalesSelectionnees.length > 0) {
      const modalRef = this.modal.open(CultureBioComponent, {size: 'lg', backdrop: 'static'});
      modalRef.componentInstance.refCultureBios = this.refCultureBios;
      modalRef.componentInstance.uniteCulturalesSelectionnees = this.uniteCulturalesSelectionnees;

      from(modalRef.result).subscribe(() => {
        this.getUnitesCulturales();
      });
    } else {
      this.modalsService.error(this.translate.instant(`page.inventaire-verger.actionMasseError`), null , {windowClass: 'anpp'}).then(() => {
      });
    }
  }

  changerProducteurUniteCulturale(uniteCulturale: UniteCulturale) {
    this.changerProducteur([uniteCulturale]);
  }

  changerProducteurMasseUC() {
    this.changerProducteur(this.uniteCulturalesSelectionnees);
  }

  private changerProducteur(ucs: UniteCulturale[]) {
    if (ucs.length > 0) {
      const modalRef = this.modal.open(ChangerProducteurComponent, {size: 'lg', backdrop: 'static'});
      modalRef.componentInstance.uniteCulturalesSelectionnees = ucs;
      modalRef.componentInstance.adherentConnecte = this.adherent;
      modalRef.componentInstance.producteurSelectionne = this.producteur;

      from(modalRef.result).subscribe(nouveauProducteur => {
        this.uniteCulturaleService.changerProducteurList(ucs, nouveauProducteur.id).subscribe(() => {
          this.getUnitesCulturales();
        });
      });
    } else {
      this.modalsService.error(this.translate.instant(`page.inventaire-verger.actionMasseError`), null , {windowClass: 'anpp'}).then(() => {
      });
    }
  }

  calculerSurface() {
    this.surface = 0;
    if (this.uniteCulturaleFilter != null && this.uniteCulturaleFilter.length > 0) {
      for (const uniteCulturaleElement of this.uniteCulturaleFilter) {
        this.surface = this.surface + uniteCulturaleElement.surfaceCalculee;
      }
    }
  }

  private loadHeader() {
    this.headers = [
      {name: 'numBloc', label: this.translate.instant('page.inventaire-verger.numBloc'), checked: true},
      {name: 'infosTracabilite', label: this.translate.instant('page.inventaire-verger.infosTracabilite'), checked: true},
      {name: 'codeParcelle', label: this.translate.instant('page.inventaire-verger.codeParcelle'), checked: false},
      {name: 'refCadastrale', label: this.translate.instant('page.inventaire-verger.refCadastrale'), checked: false},
      {name: 'numTracabilite', label: this.translate.instant('page.inventaire-verger.numDeTracabilite'), checked: false},
      {name: 'lieuDit', label: this.translate.instant('page.inventaire-verger.lieuDit'), checked: false},
      {name: 'commune', label: this.translate.instant('page.inventaire-verger.commune'), checked: false},
      {name: 'espece', label: this.translate.instant('page.inventaire-verger.espece'), checked: true},
      {name: 'variete', label: this.translate.instant('page.inventaire-verger.variete'), checked: true},
      {name: 'clone', label: this.translate.instant('page.inventaire-verger.clone'), checked: false},
      {name: 'porteGreffe', label: this.translate.instant('page.inventaire-verger.porteGreffe'), checked: false},
      {name: 'surfaceCalcule', label: this.translate.instant('page.inventaire-verger.surfaceCalcule'), checked: true},
      {
        name: 'annee',
        label: this.translate.instant('page.inventaire-verger.annee'),
        checked: true
      },
      {
        name: 'anneeSurgreffage',
        label: this.translate.instant('page.inventaire-verger.anneeSurgreffage'),
        checked: false
      },
      {name: 'anneePlantation', label: this.translate.instant('page.inventaire-verger.anneePlantation'), checked: false},
      {
        name: 'anneePremiereFeuille',
        label: this.translate.instant('page.inventaire-verger.anneePremiereFeuille'),
        checked: false
      },

      {name: 'cultureBio', label: this.translate.instant('page.inventaire-verger.cultureBio'), checked: true},
      {name: 'departement', label: this.translate.instant('page.inventaire-verger.departement'), checked: false},
      {name: 'forme', label: this.translate.instant('page.inventaire-verger.forme'), checked: false},
      {
        name: 'distanceInterRang',
        label: this.translate.instant('page.inventaire-verger.distanceInterRang'),
        checked: false
      },
      {
        name: 'distanceIntraRang',
        label: this.translate.instant('page.inventaire-verger.distanceIntraRang'),
        checked: false
      },
      {name: 'nbArbre', label: this.translate.instant('page.inventaire-verger.nbArbre'), checked: false},
      {
        name: 'systemeIrrigation',
        label: this.translate.instant('page.inventaire-verger.systemeIrrigation'),
        checked: false
      },
      {name: 'protectionGel', label: this.translate.instant('page.inventaire-verger.protectionGel'), checked: false},
      {
        name: 'protectionGrele',
        label: this.translate.instant('page.inventaire-verger.protectionGrele'),
        checked: false
      },
      {name: 'situation', label: this.translate.instant('page.inventaire-verger.situation'), checked: false},
      {name: 'confusion', label: this.translate.instant('page.inventaire-verger.confusion'), checked: false}
    ];

  }

  exportInventaire() {
    this.entrepriseService.getEntreprise(this.producteur.id).subscribe(producteur => {
      const modalRef = this.modal.open(ExportVergerComponent, {size: 'lg', backdrop: 'static'});
      modalRef.componentInstance.uniteCulturalesSelectionnees = this.uniteCulturalesSelectionnees;
      modalRef.componentInstance.idEntreprise = this.producteur.id;
      modalRef.componentInstance.syntheseInventaireEdition = this.genererSyntheseEdition(producteur);
  
      from(modalRef.result).subscribe(() => {
        this.getUnitesCulturales();
      });
    });
  }

  genererSyntheseEdition(producteur: Entreprise): SyntheseInventaireEdition {
    let syntheseInventaireEdition: SyntheseInventaireEdition = new SyntheseInventaireEdition();
    syntheseInventaireEdition.raisonExploitation = this.producteur.raisonSociale;
    syntheseInventaireEdition.raisonGp = this.adherent.raisonSociale;
    syntheseInventaireEdition.dateInventaire = this.datepipe.transform(this.dateLastMaj, 'dd/MM/yyyy');
    syntheseInventaireEdition.adresse = producteur.adresse;
    syntheseInventaireEdition.commune = producteur.codePostal + ' ' + producteur.commune;
    syntheseInventaireEdition.siret = producteur.siret;
    syntheseInventaireEdition.telephone = producteur.telephone;
    syntheseInventaireEdition.mail = producteur.mail;
    syntheseInventaireEdition.tva = producteur.numeroTVA;
    syntheseInventaireEdition.estEcoResp = producteur.estEngageeVer;
    syntheseInventaireEdition.estBio = producteur.cultureBio;
    syntheseInventaireEdition.syntheseInventaireEspeceEditionList = [];
    syntheseInventaireEdition.surfaceTotale = 0;
    syntheseInventaireEdition.surfaceIrriguee = 0;
    syntheseInventaireEdition.surfaceGel = 0;
    syntheseInventaireEdition.surfaceBio = 0;
    syntheseInventaireEdition.surfaceGrele = 0;

    this.unitesCulturales.forEach((uniteCulturale)=> {
      let especeExistante = syntheseInventaireEdition.syntheseInventaireEspeceEditionList.find((espece)=> espece.libelleEspece === uniteCulturale.espece.libelle);
      let uniteAcreer: SyntheseInventaireUniteEdition = new SyntheseInventaireUniteEdition();
      uniteAcreer.numBlocFruitier = uniteCulturale.numBlocFruitier;
      uniteAcreer.codeParcelle = uniteCulturale.codeParcelle;
      if( uniteCulturale.localisation &&  uniteCulturale.localisation.lieuDit){
        uniteAcreer.lieuDit = uniteCulturale.localisation.lieuDit;
      }
      if( uniteCulturale.localisation &&  uniteCulturale.localisation.commune){
        uniteAcreer.commune = uniteCulturale.localisation.commune;
      }
      if( uniteCulturale.localisation &&  uniteCulturale.localisation.referenceCadastrale){
        uniteAcreer.referenceCadastrale = uniteCulturale.localisation.referenceCadastrale;
      }
      
      uniteAcreer.numTracabilite = uniteCulturale.numTracabilite;
      uniteAcreer.variete = uniteCulturale.variete.varieteClone?uniteCulturale.variete.varieteClone.libelle:uniteCulturale.variete.libelle;
      uniteAcreer.clone = uniteCulturale.variete.varieteClone?uniteCulturale.variete.libelle:null
      if(uniteCulturale.porteGreffe){
        uniteAcreer.porteGreffe = uniteCulturale.porteGreffe.libelle;
      }
      if(uniteCulturale.caracteristiqueParcelle && uniteCulturale.caracteristiqueParcelle.refForme) {
        uniteAcreer.forme = uniteCulturale.caracteristiqueParcelle.refForme.libelle;
      }
      uniteAcreer.distanceInterRang = Math.round(uniteCulturale.distanceInterRang * 10000) / 10000;
      uniteAcreer.distanceIntraRang = Math.round(uniteCulturale.distanceIntraRang * 10000) / 10000;
      uniteAcreer.nbArbre = uniteCulturale.nbArbre;
      uniteAcreer.surfaceCalculee = Math.round(uniteCulturale.surfaceCalculee * 10000) / 10000;
      uniteAcreer.anneePlantation = uniteCulturale.anneePlantation;
      uniteAcreer.anneePremiereFeuille = uniteCulturale.anneePremiereFeuille;
      uniteAcreer.anneeSurgreffage = uniteCulturale.anneeSurgreffage;
      uniteAcreer.refSystemeIrrigation = (uniteCulturale.caracteristiqueParcelle.refSystemeIrrigation && uniteCulturale.caracteristiqueParcelle.refSystemeIrrigation.code != 'Pas d\'équipement');
      uniteAcreer.refProtectionGel = (uniteCulturale.caracteristiqueParcelle.refProtectionGel && uniteCulturale.caracteristiqueParcelle.refProtectionGel.code != 'Pas d\'équipement');
      uniteAcreer.refProtectionGrele = (uniteCulturale.caracteristiqueParcelle.refProtectionGrele && uniteCulturale.caracteristiqueParcelle.refProtectionGrele.code != 'Pas d\'équipement');
      uniteAcreer.refCultureBio = (uniteCulturale.caracteristiqueParcelle.refCultureBio && uniteCulturale.caracteristiqueParcelle.refCultureBio.code != 'NB');
      if (especeExistante) {
        especeExistante.uniteCulturaleEditionList.push(uniteAcreer);
        especeExistante.surface = Math.round((especeExistante.surface + uniteAcreer.surfaceCalculee)*10000)/10000;
        if(uniteAcreer.refSystemeIrrigation == true) {
          especeExistante.surfaceIrriguee = Math.round((especeExistante.surfaceIrriguee + uniteAcreer.surfaceCalculee) * 10000) / 10000;
        }
        if(uniteAcreer.refProtectionGel == true) {
          especeExistante.surfaceGel = Math.round((especeExistante.surfaceGel + uniteAcreer.surfaceCalculee) * 10000) / 10000;
        }
        if(uniteAcreer.refProtectionGrele == true) {
          especeExistante.surfaceGrele = Math.round((especeExistante.surfaceGrele + uniteAcreer.surfaceCalculee) * 10000) / 10000;
        }
        if(uniteAcreer.refCultureBio == true) {
          especeExistante.surfaceBio = Math.round((especeExistante.surfaceBio + uniteAcreer.surfaceCalculee) * 10000) / 10000;
        }
     } else {
      let especeACreee: SyntheseInventaireEspeceEdition = new SyntheseInventaireEspeceEdition();
      especeACreee.libelleEspece = uniteCulturale.espece.libelle;
      especeACreee.uniteCulturaleEditionList = [];
      especeACreee.uniteCulturaleEditionList.push(uniteAcreer);
      especeACreee.surface =  Math.round(uniteAcreer.surfaceCalculee * 10000) / 10000;
      especeACreee.surfaceIrriguee = 0;
      especeACreee.surfaceGel = 0;
      especeACreee.surfaceBio = 0;
      especeACreee.surfaceGrele = 0;
        if(uniteAcreer.refSystemeIrrigation == true) {
          especeACreee.surfaceIrriguee = Math.round(uniteAcreer.surfaceCalculee * 10000) / 10000;
        }
        if(uniteAcreer.refProtectionGel == true) {
          especeACreee.surfaceGel = Math.round(uniteAcreer.surfaceCalculee * 100000) / 10000;
        }
        if(uniteAcreer.refProtectionGrele == true) {
          especeACreee.surfaceGrele = Math.round(uniteAcreer.surfaceCalculee * 10000) / 10000;
        }
        if(uniteAcreer.refCultureBio == true) {
          especeACreee.surfaceBio = Math.round(uniteAcreer.surfaceCalculee * 10000) / 10000;
        }
      syntheseInventaireEdition.syntheseInventaireEspeceEditionList.push(especeACreee);

     }
    })
    syntheseInventaireEdition.syntheseInventaireEspeceEditionList.forEach(espece => {
      syntheseInventaireEdition.surfaceTotale = Math.round((syntheseInventaireEdition.surfaceTotale + espece.surface)* 10000) / 10000;
      syntheseInventaireEdition.surfaceIrriguee = Math.round((syntheseInventaireEdition.surfaceIrriguee + espece.surfaceIrriguee)* 10000) / 10000;
      syntheseInventaireEdition.surfaceGel = Math.round((syntheseInventaireEdition.surfaceGel + espece.surfaceGel)* 10000) / 10000;
      syntheseInventaireEdition.surfaceBio = Math.round((syntheseInventaireEdition.surfaceBio + espece.surfaceBio)* 10000) / 10000;
      syntheseInventaireEdition.surfaceGrele = Math.round((syntheseInventaireEdition.surfaceGrele + espece.surfaceGrele)* 10000) / 10000;
    })
    return syntheseInventaireEdition;
    }
  

  selectItem(newItem: Entreprise) {
    this.producteur = newItem;
    this.loadHeader();
    this.uniteCulturalesSelectionnees = [];
    this.getUnitesCulturales();
    this.loadDataRef();
  }

  addLocalisation() {
    const modalRef = this.modal.open(LocalisationsComponent, {backdrop: 'static', windowClass: 'app-modal-1001px'});
    modalRef.componentInstance.refProtectionGels = this.refProtectionGels;
    modalRef.componentInstance.refSystemeIrrigations = this.refSystemeIrrigations;
    modalRef.componentInstance.refSituations = this.refSituations;
    modalRef.componentInstance.refProtectionGreles = this.refProtectionGreles;
    modalRef.componentInstance.refFormes = this.refFormes;
    modalRef.componentInstance.refCultureBios = this.refCultureBios;
    modalRef.componentInstance.idEntreprise = this.producteur.id;

    from(modalRef.result).subscribe(() => {
      this.getUnitesCulturales();
    });
  }


}
