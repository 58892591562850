import { JsonProperty, JsonObject, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class CommissionEchantillonOperateurEdition {
    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('raisonSociale', String)
    @Optional
    raisonSociale: string;

    @JsonProperty('codePostal', String)
    @Optional
    codePostal: string;

    @JsonProperty('ville', String)
    @Optional
    ville: string;

    @JsonProperty('telephone', String)
    @Optional
    telephone: string;

    @JsonProperty('portable', String)
    @Optional
    portable: string;

    @JsonProperty('fax', String)
    @Optional
    fax: string;
}
