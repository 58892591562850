import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { DateTimeConverter } from 'src/app/core/services/mapper/converters';

@JsonObject
export class CommissionCahierEdition {
    @JsonProperty('id', Number)
    @Optional
    id: number;

    @JsonProperty('libelle', String)
    @Optional
    libelle: string;

    @JsonProperty('nbEchantillons', String)
    @Optional
    nbEchantillons: string;

    @JsonProperty('volumeTotal', String)
    @Optional
    volumeTotal: string;

    @JsonProperty('nbOperateurs', String)
    @Optional
    nbOperateurs: string;

    @JsonProperty('nbNonConformes', String)
    @Optional
    nbNonConformes: string;
}
