import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {Localisation} from '../../../../../../../data/declaration/models/localisation.model';
import {Departement} from '../../../../../../../shared/models/departement.model';
import {Commune} from '../../../../../../../shared/models/commune.model';
import {RefCultureBio} from '../../../../../../../data/declaration/models/ref-culture-bio.model';
import {RefForme} from '../../../../../../../data/declaration/models/ref-forme.model';
import {RefProtectionGel} from '../../../../../../../data/declaration/models/ref-protection-gel.model';
import {RefProtectionGrele} from '../../../../../../../data/declaration/models/ref-protection-grele.model';
import {RefSituation} from '../../../../../../../data/declaration/models/ref-situation.model';
import {RefSystemeIrrigation} from '../../../../../../../data/declaration/models/ref-systeme-irrigation.model';
import {Espece} from '../../../../../../../data/declaration/models/espece.model';
import {Variete} from '../../../../../../../data/declaration/models/variete.model';
import {RefPorteGreffe} from '../../../../../../../data/declaration/models/ref-porte-greffe.model';
import {UniteCulturale} from '../../../../../../../data/declaration/models/unite-culturale.model';
import {NgbAccordion, NgbActiveModal, NgbModal, NgbPanelChangeEvent, NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import {AdvBootstrapLoaderService} from '@adv/bootstrap-loader';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {LocalisationService} from '../../../../../../../data/declaration/services/parcellaire/localisation.service';
import {DepartementService} from '../../../../../../../shared/services/dep-commune/departement.service';
import {
  UniteCulturaleService
} from '../../../../../../../data/declaration/services/parcellaire/unite-culturale.service';
import {VarieteService} from '../../../../../../../data/declaration/services/parcellaire/variete.service';
import {CommuneService} from '../../../../../../../shared/services/dep-commune/commune.service';
import {AdvBootstrapModalService} from '@adv/bootstrap-modal';
import {SelectFieldsColumnComponent} from '../select-fields-column/select-fields-column.component';
import {from} from 'rxjs';
import {CaracteristiqueParcelle} from '../../../../../../../data/declaration/models/caracteristique-parcelle.model';

@Component({
  selector: 'app-localisations',
  templateUrl: './localisations.component.html',
  styleUrls: ['./localisations.component.scss']
})
export class LocalisationsComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  formGroup: FormGroup;
  controleFormEquipementConduite: FormGroup;
  controleFormLocalisationGeo: FormGroup;
  localisations: Localisation[];
  departements: Departement[];
  communes: Commune[];
  @Input() refCultureBios: RefCultureBio[];
  @Input() refFormes: RefForme[];
  @Input() refProtectionGels: RefProtectionGel[];
  @Input() refProtectionGreles: RefProtectionGrele[];
  @Input() refSituations: RefSituation[];
  @Input() refSystemeIrrigations: RefSystemeIrrigation[];
  @Input() especes: Espece[];
  @Input() varietes: Variete[];
  @Input() varietesClone: Variete[];
  @Input() refPorteGreffes: RefPorteGreffe[];
  @Input() uniteCulturale: UniteCulturale;
  @Input() modifierBlocBool: boolean;
  @Input() idEntreprise: number;


  surfaceHa: number;
  idTab: number;
  idTabMAX: number;
  isButtonVisible: boolean;
  hiddenButton = false;
  localisationCheck: Localisation;
  manualChange = false;
  updateLocalisation = false;
  @ViewChild('shadow') shadow: any;
  headers = [];
  private tabSet: NgbTabset;

  get departement() {
    return this.controleFormLocalisationGeo.get('departement');
  }

  get codePostal() {
    return this.controleFormLocalisationGeo.get('codePostal');
  }

  get commune() {
    return this.controleFormLocalisationGeo.get('commune');
  }

  get lieuDit() {
    return this.controleFormLocalisationGeo.get('lieuDit');
  }

  get refCadastrale() {
    return this.controleFormLocalisationGeo.get('refCadastrale');
  }

  get situationLoca() {
    return this.controleFormLocalisationGeo.get('situation');
  }

  get systemeIrrigationLoca() {
    return this.controleFormEquipementConduite.get('systemeIrrigation');
  }

  get protectionGelLoca() {
    return this.controleFormEquipementConduite.get('protectionGel');
  }

  get protectionGreleLoca() {
    return this.controleFormEquipementConduite.get('protectionGrele');
  }

  get cultureBioLoca() {
    return this.controleFormEquipementConduite.get('cultureBio');
  }

  get formeLoca() {
    return this.controleFormEquipementConduite.get('forme');
  }
  constructor(
    public readonly modal: NgbActiveModal,
    public readonly modalSelectField: NgbModal,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly fb: FormBuilder,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly localisationService: LocalisationService,
    private readonly departementService: DepartementService,
    private readonly uniteCulturaleService: UniteCulturaleService,
    private readonly varieteService: VarieteService,
    private readonly communeService: CommuneService,
    private readonly modalsService: AdvBootstrapModalService,
  ) {
  }
  ngOnInit() {
    this.loadHeader();
    this.idTab = 1;
    this.idTabMAX = 5;
    this.isButtonVisible = true;
    this.initForms();
    this.loadData();
  }

  initForms() {
    this.controleFormLocalisationGeo = this.fb.group({
      departement: [undefined, Validators.required],
      codePostal: [undefined, Validators.required],
      commune: [undefined, Validators.required],
      lieuDit: [undefined, this.lieuDitValidator.bind(this)],
      refCadastrale: [undefined, this.refCadastraleValidator.bind(this)],
      situation: [undefined, Validators.required]
    });
    this.controleFormEquipementConduite = this.fb.group({
      systemeIrrigation: [undefined],
      protectionGel: [undefined],
      protectionGrele: [undefined],
      forme: [undefined],
      cultureBio: [undefined]
    });
  }

  private lieuDitValidator(control: FormControl) {
    return (this.controleFormLocalisationGeo && !this.refCadastrale.value && !control.value) ?
      {invalid: true} : null;
  }

  private refCadastraleValidator(control: FormControl) {
    return (this.controleFormLocalisationGeo && !this.lieuDit.value && !control.value) ?
      {invalid: true} : null;
  }
  changeLocalisationGeo() {
    if (this.controleFormLocalisationGeo) {
      this.lieuDit.setErrors(null);
      this.lieuDit.updateValueAndValidity();
      this.refCadastrale.setErrors(null);
      this.refCadastrale.updateValueAndValidity();
    }
  }

  loadData() {
    this.localisationService.getLocalisationsEntreprise(this.idEntreprise).pipe(this.loaderService.operator()).subscribe((localisations) => {
      this.localisations = localisations;
      if (this.modifierBlocBool) {
        this.manualChange = true;
      }
    });
    this.departementService.getLocalisationDepartements().pipe(this.loaderService.operator()).subscribe((departements) => {
      this.departements = departements;
    });
  }
  cancel() {
    this.modal.close();
  }

  panelShadow($event: NgbPanelChangeEvent, shadow: NgbAccordion) {
    const activePanelId = $event.panelId;
    const activePanelElem = document.getElementById(activePanelId);
    if (activePanelId === 'panelAjouterLocalisation') {
      this.hiddenButton = true;
    } else {
      this.hiddenButton = false;
      this.isButtonVisible = true;
    }
    if (!shadow.isExpanded(activePanelId)) {
      activePanelElem.parentElement.classList.add('open');
    }
    if (shadow.isExpanded('panelAjouterLocalisation')) {
      this.isButtonVisible = true;
    }

  }

  changeValueCodePostal(event): void {
    const codePostal = event.target.value;
    if (codePostal.length === 2) {
      this.departement.setValue(this.departements[this.departements.findIndex(dep => dep.code === this.codePostal.value)]);
    }
    if (codePostal.length === 5) {
      this.communeService.getCommunes(codePostal).pipe(this.loaderService.operator()).subscribe((communes) => {
        this.communes = communes;
      });
    }
    if (codePostal.length < 5) {
     this.communes = [];
    }
  }

  modifierLocalisation(localisation: Localisation) {
    this.updateLocalisation = true;
    this.localisationCheck = localisation;
    this.departement.setValue(this.departements[this.departements.findIndex(dep => dep.code === this.localisationCheck.departement)]);
    this.codePostal.setValue(this.localisationCheck.codePostal);
    this.communeService.getCommunes(this.localisationCheck.codePostal).pipe(this.loaderService.operator()).subscribe((communes) => {
      this.communes = communes;
      this.commune.setValue(this.communes.find(com => com.nom === this.localisationCheck.commune));
    });

    // this.commune.setValue(this.communes[this.communes.findIndex(com => com.nom === this.localisationCheck.commune)]);
    this.lieuDit.setValue(this.localisationCheck.lieuDit);
    this.refCadastrale.setValue(this.localisationCheck.referenceCadastrale);
    if (this.localisationCheck.caracteristiqueParcelle.refSystemeIrrigation != null) {
      this.systemeIrrigationLoca.setValue(this.refSystemeIrrigations.find(iri => iri.id === this.localisationCheck.caracteristiqueParcelle.refSystemeIrrigation.id));
    }
    if (this.localisationCheck.caracteristiqueParcelle.refProtectionGel != null) {
      this.protectionGelLoca.setValue(this.refProtectionGels.find(refgel => refgel.id === this.localisationCheck.caracteristiqueParcelle.refProtectionGel.id));
    }
    if (this.localisationCheck.caracteristiqueParcelle.refProtectionGrele != null) {
      this.protectionGreleLoca.setValue(this.refProtectionGreles.find(refGrele => refGrele.id === this.localisationCheck.caracteristiqueParcelle.refProtectionGrele.id));
    }
    if (this.localisationCheck.caracteristiqueParcelle.refCultureBio != null) {
      this.cultureBioLoca.setValue(this.refCultureBios.find(refCultBio => refCultBio.id === this.localisationCheck.caracteristiqueParcelle.refCultureBio.id));
    }
    if (this.localisationCheck.caracteristiqueParcelle.refForme != null) {
      this.formeLoca.setValue(this.refFormes.find(refForme => refForme.id === this.localisationCheck.caracteristiqueParcelle.refForme.id));
    }
    if (this.localisationCheck.caracteristiqueParcelle.refSituation != null) {
      this.situationLoca.setValue(this.refSituations.find(refSituation => refSituation.id === this.localisationCheck.caracteristiqueParcelle.refSituation.id));
    }
    this.shadow.toggle('panelAjouterLocalisation');

  }

  supprimerLocalisation(localisation: Localisation) {
    if (localisation.nbBloc > 0) {
      this.modalsService.error(this.translate.instant(`page.inventaire-verger.modals.add-bloc.deleteLocalisationError`)).then(() => {
      });
    } else {
      this.localisationService.supprimerLocalisationEntreprise(this.idEntreprise, localisation.id).pipe(this.loaderService.operator()).subscribe(() => {
        this.loadData();
      });
    }
  }

  private loadHeader() {
    this.headers = [
      {name: 'departement', label: this.translate.instant('page.inventaire-verger.departement'), checked: true},
      {name: 'commune', label: this.translate.instant('page.inventaire-verger.commune'), checked: true},
      {name: 'lieuDit', label: this.translate.instant('page.inventaire-verger.lieuDit'), checked: true},
      {name: 'refCadastrale', label: this.translate.instant('page.inventaire-verger.refCadastrale'), checked: true},
      {name: 'situation', label: this.translate.instant('page.inventaire-verger.situation'), checked: true},
      {
        name: 'systemeIrrigation',
        label: this.translate.instant('page.inventaire-verger.systemeIrrigation'),
        checked: true
      },
      {name: 'protectionGrele', label: this.translate.instant('page.inventaire-verger.protectionGrele'), checked: true},
      {name: 'protectionGel', label: this.translate.instant('page.inventaire-verger.protectionGel'), checked: false},
      {name: 'forme', label: this.translate.instant('page.inventaire-verger.forme'), checked: false},
      {name: 'cultureBio', label: this.translate.instant('page.inventaire-verger.cultureBio'), checked: true},
      {
        name: 'nbBloc',
        label: this.translate.instant('page.inventaire-verger.modals.add-bloc.nbBlocsLoca'),
        checked: true
      },
    ];
  }

  show(obj) {
    return (this.headers.find(h => h.name === obj)).checked;
  }

  showList() {
    const modalRef = this.modalSelectField.open(SelectFieldsColumnComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.headers = this.headers;

    from(modalRef.result).subscribe(headers => {
      this.headers = headers;
    });
  }

  onTabAddLocaChange(tabSet: NgbTabset) {
    tabSet.select('equipementsConduite');
  }

  changeTabLoca(tabSet: NgbTabset) {
    this.tabSet = tabSet;
  }

  submit() {
    if (this.updateLocalisation) {
      if (this.controleFormLocalisationGeo.valid) {
        const localisation = new Localisation();
        const caracteristiqueParcelle = new CaracteristiqueParcelle();
        localisation.departement = this.departement.value.code;
        localisation.codePostal = this.codePostal.value;
        localisation.commune = this.commune.value.nom;
        if (this.lieuDit.value) {
          localisation.lieuDit = this.lieuDit.value;
        }
        if (this.refCadastrale.value) {
          localisation.referenceCadastrale = this.refCadastrale.value;
        }
        caracteristiqueParcelle.refSituation = this.situationLoca.value;
        if (this.systemeIrrigationLoca.value) {
          caracteristiqueParcelle.refSystemeIrrigation = this.systemeIrrigationLoca.value;
        }
        if (this.protectionGelLoca.value) {
          caracteristiqueParcelle.refProtectionGel = this.protectionGelLoca.value;
        }
        if (this.protectionGreleLoca.value) {
          caracteristiqueParcelle.refProtectionGrele = this.protectionGreleLoca.value;
        }
        if (this.cultureBioLoca.value) {
          caracteristiqueParcelle.refCultureBio = this.cultureBioLoca.value;
        }
        if (this.formeLoca.value) {
          caracteristiqueParcelle.refForme = this.formeLoca.value;
        }
        localisation.caracteristiqueParcelle = caracteristiqueParcelle;
        this.localisationService.modifierLocalisationEntreprise(this.idEntreprise, this.localisationCheck.id, localisation).pipe(this.loaderService.operator()).subscribe(() => {
          this.updateLocalisation = false;
          this.ngOnInit();
          this.shadow.toggle('panelListeLocalisation');
        });
      }
    } else {
      if (this.controleFormLocalisationGeo.valid) {
        const localisation = new Localisation();
        const caracteristiqueParcelle = new CaracteristiqueParcelle();
        localisation.departement = this.departement.value.code;
        localisation.codePostal = this.codePostal.value;
        localisation.commune = this.commune.value.nom;
        if (this.lieuDit.value) {
          localisation.lieuDit = this.lieuDit.value;
        }
        if (this.refCadastrale.value) {
          localisation.referenceCadastrale = this.refCadastrale.value;
        }
        caracteristiqueParcelle.refSituation = this.situationLoca.value;
        if (this.systemeIrrigationLoca.value) {
          caracteristiqueParcelle.refSystemeIrrigation = this.systemeIrrigationLoca.value;
        }
        if (this.protectionGelLoca.value) {
          caracteristiqueParcelle.refProtectionGel = this.protectionGelLoca.value;
        }
        if (this.protectionGreleLoca.value) {
          caracteristiqueParcelle.refProtectionGrele = this.protectionGreleLoca.value;
        }
        if (this.cultureBioLoca.value) {
          caracteristiqueParcelle.refCultureBio = this.cultureBioLoca.value;
        }
        if (this.formeLoca.value) {
          caracteristiqueParcelle.refForme = this.formeLoca.value;
        }
        localisation.caracteristiqueParcelle = caracteristiqueParcelle;
        this.localisationService.creerLocalisationEntreprise(this.idEntreprise, localisation).subscribe(() => {
          this.ngOnInit();
          this.shadow.toggle('panelListeLocalisation');
        });
      } else {
        this.tabSet.select('localisationGeo');
        this.controleFormLocalisationGeo.markAsTouched();
      }
    }
  }
}
