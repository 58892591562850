import {JsonObject, JsonProperty, Optional} from '@thorolf/json-ts-mapper';
import {Localisation} from './localisation.model';
import {CaracteristiqueParcelle} from './caracteristique-parcelle.model';
import {Espece} from './espece.model';
import {Variete} from './variete.model';
import {RefPorteGreffe} from './ref-porte-greffe.model';

@JsonObject
export class UniteCulturale {
  @JsonProperty('id', Number)
  public id: number = undefined;

  @JsonProperty('localisation', Localisation)
  public localisation: Localisation = undefined;

  @JsonProperty('caracteristiqueParcelle', CaracteristiqueParcelle)
  public caracteristiqueParcelle: CaracteristiqueParcelle = undefined;

  @JsonProperty('numBlocFruitier', String)
  @Optional
  public numBlocFruitier: string = undefined;

  @JsonProperty('numTracabilite', String)
  @Optional
  public numTracabilite: string = undefined;

  @JsonProperty('codeParcelle', String)
  @Optional
  public codeParcelle: string = undefined;

  @JsonProperty('espece', Espece)
  public espece: Espece = undefined;

  @JsonProperty('variete', Variete)
  public variete: Variete = undefined;

  @JsonProperty('anneePlantation', Number)
  @Optional
  anneePlantation: number;

  @JsonProperty('anneePremiereFeuille', Number)
  @Optional
  anneePremiereFeuille: number;

  @JsonProperty('anneeSurgreffage', Number)
  @Optional
  anneeSurgreffage: number;

  @JsonProperty('distanceIntraRang', Number)
  distanceIntraRang: number;

  @JsonProperty('distanceInterRang', Number)
  distanceInterRang: number;

  @JsonProperty('nbArbre', Number)
  nbArbre: number;

  @JsonProperty('confusion', Boolean)
  @Optional
  confusion: boolean;

  @JsonProperty('surfaceCalculee', Number)
  surfaceCalculee: number;

  @JsonProperty('porteGreffe', RefPorteGreffe)
  @Optional
  public porteGreffe: RefPorteGreffe = undefined;

  public getInfosTracabilite(): string {
    if (this.codeParcelle) {
      return this.codeParcelle;
    } else if (this.localisation.referenceCadastrale) {
      return this.localisation.referenceCadastrale;
    } else {
      return this.numTracabilite;
    }
  }
  public getInfosAnnee(): number {
    if (this.anneeSurgreffage) {
      return this.anneeSurgreffage;
    } else if (this.anneePlantation) {
      return this.anneePlantation;
    } else {
      return this.anneePremiereFeuille;
    }
  }
}
