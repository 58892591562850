import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import * as moment from 'moment';
import { DateConverter } from 'src/app/core/services/mapper/converters';

@JsonObject
export class ControleDetails {
    @JsonProperty('idProduit', Number)
    idProduit: number;

    @JsonProperty('codeProduit', String)
    codeProduit: string;

    @JsonProperty('libelleProduit', String)
    libelleProduit: string;

    @JsonProperty('millesime', Number)
    millesime: number;

    @JsonProperty('numeroLot', String)
    numeroLot: string;

    @JsonProperty('logement', String)
    logement: string;

    @JsonProperty('volume', Number)
    volume: number;

    @JsonProperty('datePrelevement', String)
    @CustomConverter(DateConverter)
    @Optional
    datePrelevement: moment.Moment;

    @JsonProperty('idEchantillon', Number)
    idEchantillon: number;

    @JsonProperty('numeroEchantillon', String)
    numeroEchantillon: string;

    @JsonProperty('numeroExpertise', Number)
    numeroExpertise: number;
}
