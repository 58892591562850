import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { ProduitDestination } from '../../models/produit-destination.model';
import { Cache, ClearCache, CacheKey } from 'src/app/core/services/cache/cache.service';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Observable, of } from 'rxjs';
import { ChangementDenomination } from '../../models/changement-denomination.model';
import { ProduitsService } from 'src/app/data/habilitation/services/produits/produits.service';
import { HttpClient } from '@angular/common/http';
import { SaisieConditionnement } from '../../models/saisie-conditionnement.model';
import { SaisieRevendication } from '../../models/saisie-revendication.model';
import { TypeCahierCode } from 'src/app/data/habilitation/models/enums/type-cahier-code.enum';
import { SaisieTransaction } from '../../models/saisie-transaction.model';
import { HistoriqueMouvement } from '../../models/historique-mouvement.model';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { LotSaisiesRevendications } from '../../models/lot-saisies-revendications.model';
import { ObjetFacture } from 'src/app/data/facturation/models/objet-facture.model';
import { ObjetFactureDTO } from 'src/app/data/facturation/models/objet-facture-dto.model';

@Injectable({
  providedIn: 'root'
})
export class MouvementsProduitsService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly produitsService: ProduitsService,
    private readonly operateurService: OperateursService
  ) { }

  @ClearCache
  clearCache() { }

  @Cache()
  getProduitsDestinationDenomination(@CacheKey codeProduit: string, @CacheKey typeCahier: TypeCahierCode): Observable<ProduitDestination[]> {
    return this.http.get<object[]>(`/api/declaration/private/produits/${btoa(codeProduit)}/destinations-denomination?typeCahier=${typeCahier}`).pipe(
      map(produits => this.mapper.deserializeArray(produits, ProduitDestination)),
      this.produitsService.getProduitPipe()
    );
  }

  setChangementDenomination(idOperateur: number, changementDenomination: ChangementDenomination, campagne: string): Observable<void> {
    return this.http.post(
      `/api/declaration/private/operateurs/${idOperateur}/mouvement-changement-denomination/${campagne}`,
      this.mapper.serialize(changementDenomination))
      .pipe(map(() => { }));
  }

  setConditionnement(idOperateur: number, annee: string, conditionnement: SaisieConditionnement): Observable<void> {
    return this.http.post(
      `/api/declaration/private/operateurs/${idOperateur}/mouvement-conditionnement/${annee}`,
      this.mapper.serialize(conditionnement))
      .pipe(map(() => { }));
  }

  creerRevendication(idOperateur: number, annee: string, revendication: SaisieRevendication): any {
    return this.http.post(
      `/api/declaration/private/operateurs/${idOperateur}/mouvement-revendications/${annee}`,
      this.mapper.serialize(revendication))
      .pipe(map(() => { }));
  }

  /**
   * Enregistre un lot de revendications et retourne un numéro de dossier associé
   * @param lotRevendications Le lot de revendications à enregistrer
   * @see LotSaisiesRevendications
   */
  creerLotRevendications(lotRevendications: LotSaisiesRevendications): Observable<string> {
    return this.http.post<string>(
      `/api/declaration/private/operateurs/${lotRevendications.idOperateur}/mouvement-revendications-groupe/${lotRevendications.campagne}`,
      this.mapper.serialize(lotRevendications.revendications)
    );
  }

    /**
   * Enregistre un lot de revendications et retourne un numéro de dossier associé
   * @param lotRevendications Le lot de revendications à enregistrer
   * @see LotSaisiesRevendications
   */
    creerLotRevendicationsAvantFacture(lotRevendications: LotSaisiesRevendications): Observable<ObjetFactureDTO[]> {
      return this.http.post<ObjetFactureDTO[]>(
        `/api/declaration/private/operateurs/${lotRevendications.idOperateur}/mouvement-revendicationsGroupeAvantFacture/${lotRevendications.campagne}`,
        this.mapper.serialize(lotRevendications.revendications)
      ).pipe(
        map(objetFactures => this.mapper.deserializeArray(objetFactures, ObjetFactureDTO)));
    }

  creerTransaction(idOperateur: number, annee: string, transaction: SaisieTransaction): any {
    return this.http.post(
      `/api/declaration/private/operateurs/${idOperateur}/mouvement-transactions/${annee}`,
      this.mapper.serialize(transaction))
      .pipe(map(() => { }));
  }

  /**
   * Retourne la liste des mouvements d'un opérateur
   * @param idOperateur L'identifiant de l'opérateur concerné
   * @param annee L'année concernée
   * @returns Un observable contenant une colelction d'HistoriqueMouvement
   * @see HistoriqueMouvement
   */
  getHistoriqueMouvement(idOperateur: number, annee: string): Observable<HistoriqueMouvement[]> {
    return this.http.get<object[]>(
      `/api/declaration/private/operateurs/${idOperateur}/historiqueMouvement/${annee}`
      // '/assets/mocks/historique-mouvements.json'
    ).pipe(
      map(historiques => this.mapper.deserializeArray(historiques, HistoriqueMouvement)),
      this.produitsService.getProduitPipe()
    );
  }

  /**
   * Retourne la liste des mouvements non lus d'un organisme
   * @returns Une observable contenant une collection d'HistoriqueMouvement
   * @see HistoriqueMouvement
   */
  getSuiviMouvement(): Observable<HistoriqueMouvement[]> {
    return this.http.get<object[]>(
      `/api/declaration/private/historiqueMouvementNonLu`
      // '/assets/mocks/suivi-mouvements.json'
    ).pipe(
      map(historiques => this.mapper.deserializeArray(historiques, HistoriqueMouvement)),
      this.produitsService.getProduitPipe(),
      this.operateurService.getOperateurPipe(),
      tap(historiques => {
        historiques.forEach(historique =>
          this.operateurService.getInformationsDomaine(historique.idOperateur).subscribe(
            details => {
              const detail = details.find(detail => detail.code === 'VIN_CVI');
              historique.cvi = (detail) ? +detail.valeur : 0;
            }
          )
        );
      })
    );
  }

  countSuiviMouvement(): Observable<number> {
    return this.http.get<number>(`/api/declaration/private/historiqueMouvementNonLu/count`);
  }

  /**
   * Passe une collection d'HistoriqueMouvement à lu
   * @param ids Une collection d'identifiants d'HistoriqueMouvement
   * @see HistoriqueMouvement
   */
  setMouvementLu(ids: number[]): Observable<void> {
    return this.http.patch('/api/declaration/private/mouvement-statut-lu', ids).pipe(
      map(() => { })
    );
  }

/**
   * Passe un mouvement à estNonControlable
   * @param id Un identifiant de Mouvement
   * @see Mouvement
   */
  setMouvementNonControlable(id: number,ischeck: boolean): any {
  return this.http.patch(`/api/declaration/private/mouvement-statut-non-controlable/${id}/${ischeck}`,null).pipe(
    map(() => { })
  );
}

}
