<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>page.operateurs.informations.entreprise.title</h4>
  </div>

  <div class="modal-body">
    <table class="table">
      <tbody>
        <tr *ngIf="operateur.operateur.structureJuridique"><td translate>page.operateurs.informations.entreprise.structureJuridique</td><td>{{ operateur.operateur.structureJuridique }}</td></tr>
        <tr *ngIf="operateur.operateur.raisonSociale"><td translate>page.operateurs.informations.entreprise.raisonSociale</td><td>{{ operateur.operateur.raisonSociale }}</td></tr>
        <tr *ngIf="operateur.operateur.siret"><td translate>page.operateurs.informations.entreprise.siret</td><td>{{ operateur.operateur.siret }}</td></tr>
        <tr *ngIf="operateur.operateur.tvaIntracommunautaire"><td translate>page.operateurs.informations.entreprise.tvaIntracommunautaire</td><td>{{ operateur.operateur.tvaIntracommunautaire }}</td></tr>
        <tr *ngFor="let info of operateur.informations"><td>{{ info.libelle }}</td><td>{{ info.valeur }}</td></tr>
        <tr *ngIf="operateur.operateur.nom"><td translate>page.operateurs.informations.entreprise.nom</td><td>{{ operateur.operateur.nom }}</td></tr>
        <tr *ngIf="operateur.operateur.prenom"><td translate>page.operateurs.informations.entreprise.prenom</td><td>{{ operateur.operateur.prenom }}</td></tr>
        <tr *ngIf="operateur.operateur.adresse"><td translate>page.operateurs.informations.entreprise.adresse</td><td>{{ operateur.operateur.adresse }}</td></tr>
        <tr *ngIf="operateur.operateur.codePostal"><td translate>page.operateurs.informations.entreprise.codePostal</td><td>{{ operateur.operateur.codePostal }}</td></tr>
        <tr *ngIf="operateur.operateur.ville"><td translate>page.operateurs.informations.entreprise.ville</td><td>{{ operateur.operateur.ville }}</td></tr>
        <tr *ngIf="operateur.operateur.cedex"><td translate>page.operateurs.informations.entreprise.cedex</td><td>{{ operateur.operateur.cedex }}</td></tr>
        <tr *ngIf="operateur.operateur.pays"><td translate>page.operateurs.informations.entreprise.pays</td><td>{{ operateur.operateur.pays?.libelle }}</td></tr>
        <tr *ngIf="operateur.operateur.email"><td translate>page.operateurs.informations.entreprise.email</td><td>{{ operateur.operateur.email }}</td></tr>
        <tr *ngIf="operateur.operateur.telephoneFixe"><td translate>page.operateurs.informations.entreprise.telephoneFixe</td><td>{{ operateur.operateur.telephoneFixe }}</td></tr>
        <tr *ngIf="operateur.operateur.telephoneMobile"><td translate>page.operateurs.informations.entreprise.telephoneMobile</td><td>{{ operateur.operateur.telephoneMobile }}</td></tr>
        <tr *ngIf="operateur.operateur.fax"><td translate>page.operateurs.informations.entreprise.fax</td><td>{{ operateur.operateur.fax }}</td></tr>
      </tbody>
    </table>
    

    <div class="actions-bar">
      <button type="button" (click)="close()" class="btn btn-light" translate>label.fermer</button>
    </div>
  </div>
</div>
