import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { MouvementsProduitsService } from 'src/app/data/declaration/services/mouvements/mouvements-produits.service';
import { HistoriqueMouvement } from 'src/app/data/declaration/models/historique-mouvement.model';
import { Referentiel } from 'src/app/data/declaration/models/referentiel.model';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-suivi-declarations',
  templateUrl: './suivi-declarations.component.html',
  styleUrls: ['./suivi-declarations.component.scss']
})
export class SuiviDeclarationsComponent implements OnInit {
  static routes: Routes = [{
    path: '',
    redirectTo: 'liste',
    pathMatch: 'full',
    data: {
      role: 'ORGANISME',
      menu: { icon: 'wine-bottle', libelle: 'menu.suivi-declarations', hide: true }
    }
  }, {
    path: 'liste',
    component: SuiviDeclarationsComponent
  }];
  public historiques: HistoriqueMouvement[];
  public referentiel: Referentiel;

  constructor(
    private readonly loader: AdvBootstrapLoaderService,
    private readonly mouvementsProduitsService: MouvementsProduitsService,
    private readonly referentielService: ReferentielService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  /** Charge la liste des mouvements non lus et le référentiel */
  private loadData() {
    forkJoin(
      this.mouvementsProduitsService.getSuiviMouvement(),
      this.referentielService.getReferentiel()
    ).pipe(this.loader.operator()).subscribe(
      ([historiques, referentiel]) => {
        this.historiques = historiques;
        this.referentiel = referentiel;
      });
  }

  /**
   * Retourne le type d'un mouvement en fonction de son code
   * @param code String représentant le code du mouvement recherché
   */
  public getTypeMouvement(code: string): string {
    let libelle = '';

    if (this.referentiel && this.referentiel.refMouvements) {
      const mouvement = this.referentiel.refMouvements.find(mouvement => mouvement.code === code);
      libelle = (mouvement) ? mouvement.libelle : '';
    }

    return libelle;
  }

  /**
   * Met à jour le statut de tous les mouvements
   * @param lu Booléen représentant l'état auquel passer les mouvements
   */
  public onPasserToutALuOuNonLu(lu: boolean): void {
    if (this.historiques) {
      const idsMouvements: number[] = [];
      this.historiques.forEach((historique) => {
        if (!historique.estLu) {
          historique.estLu = lu;
          idsMouvements.push(historique.idMouvement);
        }
      });
      this.mouvementsProduitsService.setMouvementLu(idsMouvements).subscribe();
    }
  }

  /**
   * Inverse le statut d'un seul mouvement
   * @param historique Un HistoriqueMouvement dont l'état est à modifier
   * @see HistoriqueMouvement
   */
  public onChangerStatut(historique: HistoriqueMouvement): void {
    historique.estLu = !historique.estLu;
    this.mouvementsProduitsService.setMouvementLu([ historique.idMouvement ]).subscribe();
  }
}
