<div class="anpp" style="font-size:14px">
  <div class="row tableau">
    <div class="col-lg-12">
      <div class="cards  border-radius-onglet text-center" style="background:#fff">
        <div *ngIf="this.producteur == null">
          <tr translate>
            <fa-icon icon="exclamation" class="mt-2 p-2"></fa-icon>
            page.inventaire-verger.no_productor_selectionne
          </tr>
        </div>
        <div *ngIf="showProducteurBarre">
        <div class="row">
            <div class="col-lg-4 text-left pl-4 pt-4">
              <app-search-producteur-organise-bar [idAdherent]="adherent.id" [producteur]="this.producteur"
                                                  *ngIf="showProducteurBarre"
                                                  (newItemEvent)="selectItem($event)">
              </app-search-producteur-organise-bar>
            </div>
        </div>
        </div>
        <div *ngIf="this.producteur != null">
          <div class="row">
            <div class="col-lg-4 text-left" style="padding: 18px;">
              <a (click)="addBloc()" class="pl-2 lienMain" data-toggle="modal" data-target="#exampleModal5" >
                <fa-icon icon="plus-square" class="clickable-icon"></fa-icon>
                <span style="text-decoration:underline"> Ajouter un bloc</span></a>
              <a (click)="addLocalisation()" class="pl-2 lienMain" data-toggle="modal"
                 data-target="#exampleModal5">
                <fa-icon icon="plus-square" class="clickable-icon"></fa-icon>
                <span style="text-decoration:underline"> Localisations</span></a>
              <a (click)="showFilter()" class="pl-2 lienMain" data-toggle="modal" data-target="#exampleModal5">
                <fa-icon icon='filter' class="clickable-icon"></fa-icon>
                <span style="text-decoration:underline"> Filtrer</span></a>
            </div>

            <div class="col-lg-4 text-center">
              <div class="  pt-2 m-2"
                   style="width:90%;display:inline-block;border-radius:15px;line-height:17px;border:1px solid  rgba(131,187,38,1); background:rgba(131,187,38,0.15);">
                <b translate>page.inventaire-verger.surfaceTotale <span
                  style="font-size:21px;color:rgba(131,187,38,1)">{{this.surface | number : '1.0-3'}} Ha</span></b><br/>
                <small>Maj le {{this.dateLastMaj | date:'dd/MM/yyyy à HH:mm:ss'}} par {{this.auteurLastModif}}</small>
              </div>
            </div>
            <div class="col-lg-3  pt-2 pr-4 text-center">
              <ng-template #htmlContent ><span [innerHtml]="warningRecolteToolTip"></span></ng-template>
              <fa-icon icon="exclamation-triangle" class="clickable-icon" *ngIf="warningRecolte"  [ngbTooltip]="htmlContent"
                       style="font-size:25px"></fa-icon>
            </div>
            <div class="col-lg-1  pt-2 pr-4 text-right">
              <fa-icon (click)="exportInventaire()" icon="upload" class="clickable-icon"
              style="font-size:25px"></fa-icon>
            </div>
          </div>
          <div class="tableCustom tableScroll">
            <table class="table table-striped overflow">

              <thead class="sticky-top">
              <tr>
                <th scope="col">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck" name="example1"
                           (change)="selectAllUniteCulturale($event)">
                    <label class="custom-control-label" for="customCheck"></label>
                  </div>
                </th>
                <th scope="col">Opérations</th>
                <th *ngIf="show('numBloc');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.numBloc</div>
                  <div *ngIf="!filtrer;">
                    <input type="text" [id]="numBloc" class="form-control bar1" [(ngModel)]="numBlocFilter"
                           (keyup)="filter()">
                  </div>
                </th>
                <th *ngIf="show('infosTracabilite');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.infosTracabilite</div>
                  <div *ngIf="!filtrer;">
                    <input type="text" [id]="infosTracabilite" class="form-control bar1"
                           [(ngModel)]="infosTracabiliteFilter" (keyup)="filter()">
                  </div>
                </th>
                <th *ngIf="show('codeParcelle');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.codeParcelle</div>
                  <div *ngIf="!filtrer;">
                    <input type="text" [id]="codeParcelle" class="form-control bar1" [(ngModel)]="codeParcelleFilter"
                           (keyup)="filter()">
                  </div>
                </th>
                <th *ngIf="show('refCadastrale');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.refCadastrale</div>
                  <div *ngIf="!filtrer;">
                    <input type="text" [id]="refCadastrale" class="form-control bar1" [(ngModel)]="refCadastraleFilter"
                           (keyup)="filter()">
                  </div>
                </th>
                <th *ngIf="show('numTracabilite');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.numDeTracabilite</div>
                  <div *ngIf="!filtrer;">
                    <input type="text" [id]="numTracabilite" class="form-control bar1"
                           [(ngModel)]="numTracabiliteFilter"
                           (keyup)="filter()">
                  </div>
                </th>
                <th *ngIf="show('lieuDit');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.lieuDit</div>
                  <div *ngIf="!filtrer;">
                    <input type="text" [id]="lieuDit" class="form-control bar1" [(ngModel)]="lieuDitFilter"
                           (keyup)="filter()">
                  </div>
                </th>
                <th *ngIf="show('commune');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.commune</div>
                  <div *ngIf="!filtrer;">
                    <input type="text" [id]="commune" class="form-control bar1" [(ngModel)]="communeFilter"
                           (keyup)="filter()">
                  </div>
                </th>
                <th *ngIf="show('espece');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.espece</div>
                  <div *ngIf="!filtrer;">
                    <select id="espece" class="form-control bar1" [(ngModel)]="especeFilter" (change)="filter()">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let espece of especes" [ngValue]="espece">{{espece.libelle}}</option>
                    </select>
                  </div>
                </th>
                <th *ngIf="show('variete');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.variete</div>
                  <div *ngIf="!filtrer;">
                    <select id="variete" class="form-control bar1" [(ngModel)]="varieteFilter" (change)="filter()">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let variete of varietes" [ngValue]="variete">{{variete.libelle}}</option>
                    </select>
                  </div>
                </th>
                <th *ngIf="show('clone');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.clone</div>
                  <div *ngIf="!filtrer;">
                    <select id="clone" class="form-control bar1" [(ngModel)]="cloneFilter" (change)="filter()">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let variete of varietesClone" [ngValue]="variete">{{variete.libelle}}</option>
                    </select>
                  </div>
                </th>
                <th *ngIf="show('porteGreffe');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.porteGreffe</div>
                  <div *ngIf="!filtrer;">
                    <select id="porteGreffe" class="form-control bar1" [(ngModel)]="porteGreffeFilter"
                            (change)="filter()">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let refPorteGreffe of refPorteGreffes "
                              [ngValue]="refPorteGreffe">{{refPorteGreffe.libelle}}</option>
                    </select>
                  </div>
                </th>
                <th *ngIf="show('surfaceCalcule');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.surfaceCalcule</div>
                  <div *ngIf="!filtrer;">
                    <input type="text" [id]="surfaceCalcule" class="form-control bar1"
                           [(ngModel)]="surfaceCalculeFilter"
                           (keyup)="filter()">
                  </div>
                </th>
                <th *ngIf="show('annee');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.annee</div>
                  <div *ngIf="!filtrer;">
                    <input type="text" [id]="annee" class="form-control bar1" [(ngModel)]="anneeFilter"
                           (keyup)="filter()">
                  </div>
                </th>
                <th *ngIf="show('anneeSurgreffage');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.anneeSurgreffage</div>
                  <div *ngIf="!filtrer;">
                    <input type="text" [id]="anneeSurgreffage" class="form-control bar1"
                           [(ngModel)]="anneeSurgreffageFilter"
                           (keyup)="filter()">
                  </div>
                </th>
                <th *ngIf="show('anneePlantation');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.anneePlantation</div>
                  <div *ngIf="!filtrer;">
                    <input type="text" [id]="anneePlantation" class="form-control bar1"
                           [(ngModel)]="anneePlantationFilter"
                           (keyup)="filter()">
                  </div>
                </th>
                <th *ngIf="show('anneePremiereFeuille');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.anneePremiereFeuille</div>
                  <div *ngIf="!filtrer;">
                    <input type="text" [id]="anneePremiereFeuille" class="form-control bar1"
                           [(ngModel)]="anneePremiereFeuilleFilter"
                           (keyup)="filter()">
                  </div>
                </th>
                <th *ngIf="show('cultureBio');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.cultureBio</div>
                  <div *ngIf="!filtrer;">
                    <select id="cultureBio" class="form-control bar1" [(ngModel)]="cultureBioFilter"
                            (change)="filter()">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let refCultureBio of refCultureBios "
                              [ngValue]="refCultureBio">{{refCultureBio.libelle}}</option>
                    </select>
                  </div>
                </th>
                <th *ngIf="show('departement');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.departement</div>
                  <div *ngIf="!filtrer;">
                    <input type="text" [id]="departement" class="form-control bar1" [(ngModel)]="departementFilter"
                           (keyup)="filter()">
                  </div>
                </th>
                <th *ngIf="show('forme');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.forme</div>
                  <div *ngIf="!filtrer;">
                    <select id="forme" class="form-control bar1" [(ngModel)]="formeFilter" (change)="filter()">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let refForme of refFormes " [ngValue]="refForme">{{refForme.libelle}}</option>
                    </select>
                  </div>
                </th>
                <th *ngIf="show('distanceInterRang');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.distanceInterRang</div>
                  <div *ngIf="!filtrer;">
                    <input type="text" [id]="distanceInterRang" class="form-control bar1"
                           [(ngModel)]="distanceInterRangFilter"
                           (keyup)="filter()">
                  </div>
                </th>
                <th *ngIf="show('distanceIntraRang');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.distanceIntraRang</div>
                  <div *ngIf="!filtrer;">
                    <input type="text" [id]="distanceIntraRang" class="form-control bar1"
                           [(ngModel)]="distanceIntraRangFilter"
                           (keyup)="filter()">
                  </div>
                </th>
                <th *ngIf="show('nbArbre');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.nbArbre</div>
                  <div *ngIf="!filtrer;">
                    <input type="text" [id]="nbArbre" class="form-control bar1" [(ngModel)]="nbArbreFilter"
                           (keyup)="filter()">
                  </div>
                </th>
                <th *ngIf="show('systemeIrrigation');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.systemeIrrigation</div>
                  <div *ngIf="!filtrer;">
                    <select id="systemeIrrigation" class="form-control bar1" [(ngModel)]="systemeIrrigationFilter"
                            (change)="filter()">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let refSystemeIrrigation of refSystemeIrrigations "
                              [ngValue]="refSystemeIrrigation">{{refSystemeIrrigation.libelle}}</option>
                    </select>
                  </div>
                </th>
                <th *ngIf="show('protectionGel');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.protectionGel</div>
                  <div *ngIf="!filtrer;">
                    <select id="protectionGel" class="form-control bar1" [(ngModel)]="protectionGelFilter"
                            (change)="filter()">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let refProtectionGel of refProtectionGels "
                              [ngValue]="refProtectionGel">{{refProtectionGel.libelle}}</option>
                    </select>
                  </div>
                </th>
                <th *ngIf="show('protectionGrele');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.protectionGrele</div>
                  <div *ngIf="!filtrer;">
                    <select id="protectionGrele" class="form-control bar1" [(ngModel)]="protectionGreleFilter"
                            (change)="filter()">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let refProtectionGrele of refProtectionGreles "
                              [ngValue]="refProtectionGrele">{{refProtectionGrele.libelle}}</option>
                    </select>
                  </div>
                </th>
                <th *ngIf="show('situation');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.situation</div>
                  <div *ngIf="!filtrer;">
                    <select id="situation" class="form-control bar1" [(ngModel)]="situationFilter" (change)="filter()">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let refSituation of refSituations "
                              [ngValue]="refSituation">{{refSituation.libelle}}</option>
                    </select>
                  </div>
                </th>
                <th *ngIf="show('confusion');" scope="col" style="position:relative">
                  <div translate>page.inventaire-verger.confusion</div>
                  <div *ngIf="!filtrer;">
                    <input type="checkbox" [id]="confusion" class="form-control bar1"
                           [(ngModel)]="confusionSexuelFilter"
                           (change)="filter()">
                  </div>
                </th>
                <th>
                  <fa-icon class="clickable-icon" icon="ellipsis-h" (click)="showList()"></fa-icon>
                </th>
              </tr>
              </thead>
              <tbody *ngIf="uniteCulturaleFilter && unitesCulturales">
              <tr *ngIf="unitesCulturales.length === 0">
                <td colspan="99" class="p-4">
                  <fa-icon icon="exclamation-triangle"></fa-icon>&nbsp;
                  <span translate>page.inventaire-verger.no_data</span>
                </td>
              </tr>
              <tr *ngIf="uniteCulturaleFilter.length === 0 && unitesCulturales.length > 0">
                <td colspan="99" class="p-4" style="text-align:left">
                  <fa-icon icon="exclamation-triangle"></fa-icon>&nbsp;
                  <span translate>page.inventaire-verger.no_uniteC_filtree</span>
                </td>
              </tr>
              <ng-container *ngIf="uniteCulturaleFilter.length > 0 && unitesCulturales.length > 0">
                <tr *ngFor="let uniteCulturale of uniteCulturaleFilter">
                  <td>
                    <div class="custom-control custom-checkbox">
                      <input [checked]="uniteCulturalesSelectionnees.includes(uniteCulturale)" type="checkbox"
                             class="custom-control-input"
                             id="bloc-{{uniteCulturale.id}}" name="example1"
                             (change)="onCheckboxChange(uniteCulturale)">
                      <label class="custom-control-label" for="bloc-{{uniteCulturale.id}}"></label>
                    </div>
                  </td>
                  <td>
                    <div class="btn-group dropright  ">
                      <div ngbDropdown placement="right-start">
                        <button class="btn btn-secondary dropdown-toggle btnprimary btn-sm "
                                id="dropdownConfig" ngbDropdownToggle>
                          <fa-icon icon="cog"></fa-icon>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                          <button (click)="modifierUniteCulturale(uniteCulturale)" ngbDropdownItem
                                  class="dropdown-item">
                            <fa-icon icon="pencil-alt"></fa-icon>
                            Modifier
                          </button>
                          <div class="dropdown-divider"></div>
                          <button (click)="dupliquerUniteCulturale(uniteCulturale)" ngbDropdownItem
                                  class="dropdown-item">
                            <fa-icon icon="copy"></fa-icon>
                            Dupliquer
                          </button>
                          <div class="dropdown-divider"></div>
                          <button (click)="supprimerUniteCulturale(uniteCulturale)" ngbDropdownItem
                                  class="dropdown-item">
                            <fa-icon icon="trash-alt"></fa-icon>
                            Supprimer
                          </button>
                          <div class="dropdown-divider"></div>
                          <button ngbDropdownItem
                                  class="dropdown-item">
                            <img src="/assets/img/arbre.png" width="16px"/>
                            Arrachage
                            <button (click)="arrachageTotal(uniteCulturale)" type="button"
                                    class="btn-sm btn-secondary btnprimary mr-2 ml-2">Total
                            </button>
                            <button (click)="arrachagePartiel(uniteCulturale)" type="button"
                                    class="btn-sm btn-secondary btnprimary">Partiel
                            </button>
                          </button>
                          <div class="dropdown-divider"></div>
                          <button ngbDropdownItem
                                  class="dropdown-item">
                            <img src="/assets/img/secateur.png" width="16px"/>
                            Surgreffage
                            <button (click)="surgreffageTotalPartiel(uniteCulturale,false)" type="button"
                                    class="btn-sm btn-secondary btnprimary mr-2 ml-2">Total
                            </button>
                            <button (click)="surgreffageTotalPartiel(uniteCulturale,true)" type="button"
                                    class="btn-sm btn-secondary btnprimary">Partiel
                            </button>
                          </button>
                          <div *ngIf="this.typeUtilisateurConnecte != 'PI'">
                            <div class="dropdown-divider"></div>
                            <button (click)="changerProducteurUniteCulturale(uniteCulturale)" ngbDropdownItem
                                    class="dropdown-item">
                              <fa-icon icon="user"></fa-icon>
                              Changer producteur
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td *ngIf="show('numBloc');">{{ uniteCulturale.numBlocFruitier}}</td>
                  <td *ngIf="show('infosTracabilite');">{{ uniteCulturale.getInfosTracabilite()}} </td>
                  <td *ngIf="show('codeParcelle');">{{ uniteCulturale.codeParcelle}}</td>
                  <td *ngIf="show('refCadastrale');">{{ uniteCulturale.localisation.referenceCadastrale}}</td>
                  <td *ngIf="show('numTracabilite');">{{ uniteCulturale.numTracabilite}}</td>
                  <td *ngIf="show('lieuDit');">{{ uniteCulturale.localisation.lieuDit}}</td>
                  <td *ngIf="show('commune');">{{ uniteCulturale.localisation.commune}}</td>
                  <td *ngIf="show('espece');">{{ uniteCulturale.espece.libelle}}</td>
                  <td
                    *ngIf="show('variete');">{{ uniteCulturale.variete.varieteClone ? uniteCulturale.variete.varieteClone.libelle : uniteCulturale.variete.libelle}}</td>
                  <td
                    *ngIf="show('clone');">{{ uniteCulturale.variete.varieteClone ? uniteCulturale.variete.libelle : ''}}</td>
                  <td
                    *ngIf="show('porteGreffe');">{{ uniteCulturale.porteGreffe ? uniteCulturale.porteGreffe.libelle : ''}}</td>
                  <td *ngIf="show('surfaceCalcule');">{{ uniteCulturale.surfaceCalculee | number:'1.3-3'}}</td>
                  <td *ngIf="show('annee');">{{ uniteCulturale.getInfosAnnee()}}</td>
                  <td *ngIf="show('anneeSurgreffage');">{{ uniteCulturale.anneeSurgreffage}}</td>
                  <td *ngIf="show('anneePlantation');">{{ uniteCulturale.anneePlantation}}</td>
                  <td *ngIf="show('anneePremiereFeuille');">{{ uniteCulturale.anneePremiereFeuille}}</td>
                  <td
                    *ngIf="show('cultureBio');">{{ uniteCulturale.caracteristiqueParcelle.refCultureBio ? uniteCulturale.caracteristiqueParcelle.refCultureBio.libelle : ''}}</td>
                  <td *ngIf="show('departement');">{{ uniteCulturale.localisation.departement}}</td>
                  <td
                    *ngIf="show('forme');">{{ uniteCulturale.caracteristiqueParcelle.refForme ? uniteCulturale.caracteristiqueParcelle.refForme.libelle : ''}}</td>
                  <td *ngIf="show('distanceInterRang');">{{ uniteCulturale.distanceInterRang | number:'1.2-2'}}</td>
                  <td *ngIf="show('distanceIntraRang');">{{ uniteCulturale.distanceIntraRang | number:'1.2-2'}}</td>
                  <td *ngIf="show('nbArbre');">{{ uniteCulturale.nbArbre}}</td>
                  <td
                    *ngIf="show('systemeIrrigation');">{{ uniteCulturale.caracteristiqueParcelle.refSystemeIrrigation ? uniteCulturale.caracteristiqueParcelle.refSystemeIrrigation.libelle : ''}}</td>
                  <td
                    *ngIf="show('protectionGel');">{{ uniteCulturale.caracteristiqueParcelle.refProtectionGel ? uniteCulturale.caracteristiqueParcelle.refProtectionGel.libelle : ''}}</td>
                  <td
                    *ngIf="show('protectionGrele');">{{ uniteCulturale.caracteristiqueParcelle.refProtectionGrele ? uniteCulturale.caracteristiqueParcelle.refProtectionGrele.libelle : ''}}</td>
                  <td
                    *ngIf="show('situation');">{{ uniteCulturale.caracteristiqueParcelle.refSituation ? uniteCulturale.caracteristiqueParcelle.refSituation.libelle : ''}}</td>
                  <td *ngIf="show('confusion');">{{ uniteCulturale.confusion?.valueOf() ? 'OUI' : 'NON'}}</td>
                  <td></td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="position:fixed; bottom:0px;width:82%">
    <div class="row pb-2">
      <div class="col-lg-3 pt-2">
        <div class="btn-group dropdown">
          <div ngbDropdown placement="top-start">
            <button class="btn  btn-secondary dropdown-toggle btnprimary  " type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" ngbDropdownToggle aria-haspopup="true" aria-expanded="true">
              <fa-icon icon="cogs"></fa-icon>
              Opérations groupées
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
              <button (click)="supprimerEnMasseUC()" ngbDropdownItem
                      class="dropdown-item">
                <fa-icon icon="trash-alt"></fa-icon>
                Supprimer
              </button>
              <div *ngIf="this.typeUtilisateurConnecte != 'PI'">
                <div class="dropdown-divider"></div>
                <button (click)="changerProducteurMasseUC()" ngbDropdownItem
                        class="dropdown-item">
                  <fa-icon icon="user"></fa-icon>
                  Changer producteur
                </button>
              </div>
              <div class="dropdown-divider"></div>
              <button (click)="cultureBioEnMasseUC()" ngbDropdownItem
                      class="dropdown-item">
                <fa-icon icon="leaf"></fa-icon>
                Modifier Culture bio
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-9 pt-2 text-right">

        <button (click)=validInventaire() type="button"
                class="btn btn-lg btn-primary"
                style="background:#41905c;border:1px solid #41905c" data-dismiss="modal" aria-label="Close">Valider
          l'inventaire
          <fa-icon icon="arrow-right" class="primary-icon"></fa-icon>
        </button>

      </div>
    </div>

  </div>

</div>

