import { Injectable } from '@angular/core';
import { Router, Route } from '@angular/router';

export class MenuItem {
  constructor(path: string, menuData: any, roles: string[] = [], domaines: string[] = []) {
    this.path = path;
    this.icon = menuData.icon;
    this.libelle = menuData.libelle;
    this.roles = roles;
    this.hide = menuData.hide;
    this.domaines = domaines
  }

  public path: string;
  public icon: string;
  public libelle: string;
  public children: MenuItem[];
  public roles: string[];
  public domaines: string[];
  public hide: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private readonly routes: MenuItem[];

  constructor(
    private readonly router: Router
  ) {
    this.routes = this.parseRoutes(this.router.config);
  }

  public getRoutes(): MenuItem[] {
    return this.routes;
  }

  private parseRoutes(route: Route | Route[], prefix: string = '', parentRoles: string[] = [], parentDomaines: string[] = []): MenuItem[] {
    if (!route) {
      return [];
    }

    if (Array.isArray(route)) {
      let items: MenuItem[] = [];
      for (const r of route) {
        items = items.concat(this.parseRoutes(r, prefix, parentRoles, parentDomaines));
      }

      return items;
    }

    let path = prefix + '/' + route.path;
    path = path.replace(/\/+$/g, ''); // remove trailing slashs

    let routeRoles = [];
    let routeDomaines = [];
    try {
      routeRoles = routeRoles.concat(route.data.role);
      routeDomaines = routeDomaines.concat(route.data.domaines);
    } catch (e) { }
    routeRoles = routeRoles.concat(parentRoles).filter(role => !!role);
    routeDomaines = routeDomaines.concat(parentDomaines).filter(domaine => !!domaine);

    if (route.data && route.data.menu) {
      const item = new MenuItem(path, route.data.menu, routeRoles, routeDomaines);

      item.children = this.loadChildren(route, path, routeRoles, routeDomaines);

      return [item];
    }

    return this.loadChildren(route, path, routeRoles, routeDomaines);
  }

  loadChildren(route: Route, path: string, parentRoles: string[] = [], parentDomaines: string[] = []): MenuItem[] {
    if (route.children) {
      return this.parseRoutes(route.children, path, parentRoles);
      // } else if (route._loadedConfig) {
      //   return this.parseRoutes(route._loadedConfig.routes, path);
    } else {
      return [];
    }
  }
}
