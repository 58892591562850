import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { ComplementPoPdfVer } from '../../models/complementPoPdfVer.model';
import { EntrepriseBase } from '../../models/entreprise-base.model';
import { EntrepriseDroit } from '../../models/entreprise-droit.model';
import { EntrepriseReferent } from '../../models/entreprise-referent.model';
import { Entreprise } from '../../models/entreprise.model';
import { EspeceEntreprise } from '../../models/espece-entreprise.model';
import { Structure } from '../../models/structure.model';
import { SyntheseEspece } from '../../models/synthese-espece.model';

@Injectable({
  providedIn: 'root'
})
export class EntreprisesService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService
    ) { }

    getEntreprisesBase(): Observable<EntrepriseBase[]> {
      return this.http.get<Object[]>(`/api/intervenant/private/entreprises/recherche`)
        .pipe(
          map(entreprises => this.mapper.deserializeArray(entreprises, EntrepriseBase))
        );
    }

    getEntrepriseBase(idEntreprise: number): Observable<EntrepriseBase> {
      return this.http.get<any>(`/api/intervenant/private/entreprises/entreprise/${idEntreprise}/simplifie`).pipe(
        map(entreprise => this.mapper.deserializeObject(entreprise, EntrepriseBase))
      ); 
    }

    getEntreprises(): Observable<Entreprise[]> {
      return this.http.get<Object[]>(`/api/intervenant/private/entreprises`)
        .pipe(
          map(entreprises => this.mapper.deserializeArray(entreprises, Entreprise))
        );
    }

    getEntreprisesInactives(): Observable<Entreprise[]> {
      return this.http.get<Object[]>(`/api/intervenant/private/entreprises-inactives`)
        .pipe(
          map(entreprises => this.mapper.deserializeArray(entreprises, Entreprise))
        );
    }

    getEntreprisesBySousRefType(refCode:string[]): Observable<Entreprise[]> {      
      return this.http.get<Object[]>(`/api/intervenant/private/entreprises?refSousTypeCodes=${refCode}`)
        .pipe(
          map(entreprises => this.mapper.deserializeArray(entreprises, Entreprise))
        );
    }

    getEntreprisesReferents(): Observable<EntrepriseReferent[]>{
      let params = new HttpParams().set('adherentAnpp', 'true'); // recherche des adherents Anpp
      return this.http.get<Object[]>(`/api/intervenant/private/entreprises-referents`, { params: params }).pipe(
        map(entreprises => this.mapper.deserializeArray(entreprises, EntrepriseReferent))
      );
    }
    
    getEntreprisesReferentsPourSuiviInventaire(): Observable<EntrepriseReferent[]>{
      let refCode =['PI_DEFAUT', 'PO_DEFAUT'];
      return this.http.get<Object[]>(`/api/intervenant/private/entreprises-referents?refSousTypeCodes=${refCode}`).pipe(
        map(entreprises => this.mapper.deserializeArray(entreprises, EntrepriseReferent))
      );
    }

    getEntreprisesReferentsPourParamCampagne(): Observable<EntrepriseReferent[]>{
      let refCode =['PI_DEFAUT', 'GP_DEFAUT', 'EX_DEFAUT'];
      const params = new HttpParams()
      .set('adherentAnpp', 'true')
      .set('refSousTypeCode', refCode.join(','));
      return this.http.get<Object[]>(`/api/intervenant/private/entreprises-referents`, { params: params }).pipe(
        map(entreprises => this.mapper.deserializeArray(entreprises, EntrepriseReferent))
      );
    }

    getEntreprisesByIdEntrepriseLiee(idEntreprise: number): Observable<Entreprise[]> {
      return this.http.get<Object[]>(`/api/intervenant/private/entreprises/${idEntreprise}/po`)
        .pipe(
          map(entreprises => this.mapper.deserializeArray(entreprises, Entreprise))
        );
    }

    getEntreprisesByIdEntrepriseLieeAndRefSousType(idEntreprise: number, idRefSousType: number): Observable<Entreprise[]> {
      return this.http.get<Object[]>(`/api/intervenant/private/entreprises/${idEntreprise}/soustype/${idRefSousType}`)
        .pipe(
          map(entreprises => this.mapper.deserializeArray(entreprises, Entreprise))
        );
    }

    enregistrerDroits(droitList: EntrepriseDroit[]): Observable<EntrepriseDroit[]> {
      return this.http.post<EntrepriseDroit[]>(`/api/intervenant/private/entreprises-droits`, droitList)
    }
    
    getEntreprisesByIdRefSousType(idRefSousType: number): Observable<EntrepriseBase[]> {
      return this.http.get<Object[]>(`/api/intervenant/private/entreprises/soustype/${idRefSousType}`)
        .pipe(
          map(entreprises => this.mapper.deserializeArray(entreprises, EntrepriseBase))
        );
    }

    getEntreprise(idEntreprise: number): Observable<Entreprise> {
      return this.http.get<any>(`/api/intervenant/private/entreprises/${idEntreprise}`).pipe(
        map(entreprise => this.mapper.deserializeObject(entreprise, Entreprise))
      );        
    }  
    
    getStructureJuriques(): Observable<Structure[]>{
      return this.http.get<Object[]>(`/api/intervenant/private/structureJuridiques`).pipe(
        map(refStructure => this.mapper.deserializeArray(refStructure, Structure))
      );
    }
  

    public modifierEntreprise(entreprise: Entreprise): Observable<void> {
      
      return this.http.put<void>(`/api/intervenant/private/entreprises/${entreprise.id}`,this.mapper.serialize(entreprise));
    }

    public creerEntreprise(entreprise: Entreprise): Observable<number> {
      return this.http.post(`/api/intervenant/private/entreprises`, this.mapper.serialize(entreprise), { observe: 'response' }).pipe(
        map(response => parseInt(response.headers.get('location').split('/').pop(), 10))
      );
    }

    public supprimerEntreprise(idEntreprise: number): Observable<void> {
      return this.http.delete<void>(`/api/intervenant/private/entreprises/${idEntreprise}`);
    }

    public reactiverEntreprise(idEntreprise: number): Observable<void> {
      return this.http.put<void>(`/api/intervenant/private/entreprises/${idEntreprise}/reactiver`, {});
    }

    public codeComptableExiste(codeComptable : String): Observable<boolean>{
       return this.http.get<boolean>(`/api/intervenant/private/entreprises/codecomptable/${codeComptable}`);
    }

    getEntreprisesAdherentesActivesSearch(term: string): Observable<EntrepriseBase[]> {
      return this.http.get<EntrepriseBase[]>(`/api/intervenant/private/entreprises-search?search=${term}`);
    }

    getEntreprisesByIdEntrepriseLieeSearch(idEntreprise: number, term: string): Observable<EntrepriseBase[]> {
      return this.http.get<Object[]>(`/api/intervenant/private/entreprises/${idEntreprise}/po-search?search=${term}`)
        .pipe(
          map(entreprises => this.mapper.deserializeArray(entreprises, Entreprise))
        );
    }

    getSurfaceEspeceByEntrepriseAndCampagne(idEntreprise: number, idRefCampagne: number): Observable<SyntheseEspece[]> {
      return this.http.get<SyntheseEspece[]>(`/api/declaration/private/entreprises/${idEntreprise}/surfaces?idRefCampagne=${idRefCampagne}`) ;
    }

    getEspecesByEntreprise(): Observable<EspeceEntreprise[]> {
      return this.http.get<EspeceEntreprise[]>(`/api/declaration/private/entreprises/especes`) ;
    }


    getInformationsComplementairesPdfVer(idsEntreprise: Number[]): Observable<ComplementPoPdfVer[]> {
      return this.http.get<Object[]>(`/api/intervenant/private/entreprises/infos-pdf-ver?ids=${idsEntreprise.join(',')}`)
      .pipe(
        map(values => this.mapper.deserializeArray(values, ComplementPoPdfVer))
      );
    }

}
