import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { OrganismesComponent } from './pages/organismes/organismes.component';
import { UtilisateursComponent } from './pages/utilisateurs/utilisateurs.component';
import { ReferencesResolver } from './resolvers/references.resolver';
import { DroitsComponent } from './pages/droits/droits.component';
import { ProfilsComponent } from './pages/profils/profils.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { CampagnesComponent } from './pages/campagnes/campagnes.component';
import { EntreprisesComponent } from './pages/entreprises/entreprises.component';
import { ValeursParametrablesComponent } from './pages/valeurs-parametrables/valeurs-parametrables.component';
import { InteractionsComponent } from './pages/interactions/interactions.component';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {

  static routeData = {
    role: '(ORGANISME || ADMIN) && (INTERVENANT_ORGANISME_LISTE_LECTURE || INTERVENANT_UTILISATEUR_LECTURE)',
    menu: {
      icon: 'cog',
      libelle: 'menu.administration'
    }
  };
  static routes: Routes = [
    // { path: 'utilisateurs', data: UtilisateursComponent.routeData, children: UtilisateursComponent.routes },
    { path: '', redirectTo: 'params', pathMatch: 'full' },
    {
      path: 'params',
      data: AdministrationComponent.routeData,
      resolve: {
        references: ReferencesResolver
      },
      children: [
        { path: '', component: AdministrationComponent },
        { path: 'organismes', data: OrganismesComponent.routeData, children: OrganismesComponent.routes },
        { path: 'utilisateurs', data: UtilisateursComponent.routeData, children: UtilisateursComponent.routes },
        { path: 'droits', children: DroitsComponent.routes },
        { path: 'profils', children: ProfilsComponent.routes },
        { path: 'entreprises', children: EntreprisesComponent.routes, data: EntreprisesComponent.routeData },
        { path: 'contacts', data: ContactsComponent.routeData, children : ContactsComponent.routes },
        { path: 'interactions', data: InteractionsComponent.routeData, children : InteractionsComponent.routes },
        { path: 'campagnes', children: CampagnesComponent.routes },
        { path: 'listes', data: ValeursParametrablesComponent.routeData, children : ValeursParametrablesComponent.routes },
      ],
    },
  ];

  constructor() { }

  ngOnInit() {
  }

}
