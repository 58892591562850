import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { ReferentielService } from '../referentiel/referentiel.service';
import { CahiersService } from 'src/app/data/commission/services/cahiers/cahiers.service';
import { ActivitesService } from 'src/app/data/habilitation/services/activites/activites.service';
import { Controle } from '../../models/controle.model';
import { Observable, forkJoin } from 'rxjs';
import { map, tap, flatMap } from 'rxjs/operators';
import { ClearCache, Cache, CacheKey } from 'src/app/core/services/cache/cache.service';
import { PaginateResponseStream, PaginationService } from 'src/app/shared/services/pagination/pagination.service';
import { Referentiel } from '../../models/referentiel.model';
import { ControleResultat } from '../../models/controle-resultat.model';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { DateConverter } from 'src/app/core/services/mapper/converters';
import * as moment from 'moment';
import { AnimateursService } from 'src/app/data/commission/services/animateurs/animateurs.service';
import { Cahier } from 'src/app/data/habilitation/models/cahier.model';
import { Animateur } from 'src/app/data/commission/models/animateur.model';
import { Activite } from 'src/app/data/habilitation/models/activite.model';
import { ProduitsService } from 'src/app/data/habilitation/services/produits/produits.service';
import { ControleDetails } from '../../models/controle-details.model';

@Injectable({
  providedIn: 'root'
})
export class ControlesService {

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly refService: ReferentielService,
    private readonly cahiersServices: CahiersService,
    private readonly activitesService: ActivitesService,
    private readonly paginationService: PaginationService,
    private readonly operateurService: OperateursService,
    private readonly animateurService: AnimateursService,
    private readonly produitService: ProduitsService
  ) { }

  @ClearCache
  clearCache() { }

  @Cache()
  getControles(@CacheKey idOrganisme: number): Observable<Controle[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/declaration/private/organismes/${idOrganisme}/controles`),
      this.refService.getReferentiel(),
      this.cahiersServices.getCahiers(),
      this.activitesService.getActivites(),
    ).pipe(
      map(([controles, ref, cahiers, activites]) => this.mapper.deserializeArray(controles, Controle, Object.assign({ cahiers, activites }, ref)))
    );
  }

  @Cache()
  getControle(@CacheKey idOrganisme: number, @CacheKey idControle: number): Observable<Controle> {
    return forkJoin(
      this.http.get<object>(`/api/declaration/private/organismes/${idOrganisme}/controles/${idControle}`),
      this.refService.getReferentiel(),
      this.cahiersServices.getCahiers(),
      this.activitesService.getActivites(),
    ).pipe(
      map(([controle, ref, cahiers, activites]) => this.mapper.deserializeObject(controle, Controle, Object.assign({ cahiers, activites }, ref)))
    );
  }

  creerControle(idOrganisme: number, controle: Controle): Observable<number> {
    return this.http.post(`/api/declaration/private/organismes/${idOrganisme}/controles`, this.mapper.serialize(controle), { observe: 'response' }).pipe(
      tap(() => this.clearCache()),
      map(response => parseInt(response.headers.get('location').split('/').pop(), 10)),
    );
  }

  modifierControle(idOrganisme: number, controle: Controle): Observable<void> {
    return this.http.put(`/api/declaration/private/organismes/${idOrganisme}/controles/${controle.id}`, this.mapper.serialize(controle)).pipe(
      tap(() => this.clearCache()),
      map(() => { }),
    );
  }

  modifierStatutControle(idOrganisme: number, controle: Controle): Observable<void> {
    return this.http.patch(`/api/declaration/private/organismes/${idOrganisme}/controles/${controle.id}`, this.mapper.serialize(controle)).pipe(
      tap(() => this.clearCache()),
      map(() => { }),
    );
  }

  getControleDetails(controle: Controle): Observable<ControleDetails> {
    return this.http.get(`/api/declaration/private/controles/${controle.id}`
      // return this.http.get<object>(`/assets/mocks/controle-details.json`).pipe(
    ).pipe(
      map(controleDetails => this.mapper.deserializeObject(controleDetails, ControleDetails, Object.assign(controleDetails)))
    );
  }

  /**
 * Recherche une collection dde contrôles selon des critères de recherche
 * @param idOrganisme L'ID organisme des contrôles
 * @param nature La nature des contrôles
 * @see Controle
 */
  getFilteredControles(
    idOrganisme: number,
    nature: number,
    statuts: number,
    types: number,
    agents: number,
    laboratoires: number,
    debutDateControle: moment.Moment,
    finDateControle: moment.Moment,
    numeroControle: string,
    numeroEchantillon: string,
    numeroCommission: string,
    numeroCVI: string,
    showLoader = true): Observable<PaginateResponseStream<ControleResultat>> {
    const dateConverter = new DateConverter();
    let params = new HttpParams();
    params = (nature) ? params.set('idNature', nature.toString()) : params;
    params = (statuts) ? params.set('idControleStatutList', statuts.toString()) : params;
    params = (types) ? params.set('idControleTypeList', types.toString()) : params;
    params = (agents) ? params.set('idAgentList', agents.toString()) : params;
    params = (laboratoires) ? params.set('idLaboratoireList', laboratoires.toString()) : params;
    params = (debutDateControle) ? params.set('dateControleMin', dateConverter.serialize(debutDateControle)) : params;
    params = (finDateControle) ? params.set('dateControleMax', dateConverter.serialize(finDateControle)) : params;
    params = (numeroControle) ? params.set('numeroControle', numeroControle) : params;
    params = (numeroEchantillon) ? params.set('numeroEchantillon', numeroEchantillon) : params;
    params = (numeroCommission) ? params.set('numeroCommission', numeroCommission) : params;
    params = (numeroCVI) ? params.set('numeroCVI', numeroCVI) : params;
    return this.paginationService.paginateGetAsStream<object>(`/api/declaration/private/organismes/${idOrganisme}/controles`, undefined, undefined, { params }, showLoader).pipe(
      flatMap(response => {
        return forkJoin(
          this.refService.getReferentiel(),
          this.cahiersServices.getCahiers(),
          this.animateurService.getAnimateurs(idOrganisme),
          this.activitesService.getActivites()
        ).pipe(
          map(([ref, cahiers, agents, activites]) => [response, ref, cahiers, agents, activites] as [PaginateResponseStream<object>, Referentiel, Cahier[], Animateur[], Activite[]])
        );
        /*return this.refService.getReferentiel().pipe(
          map(ref => [response, ref] as [PaginateResponseStream<object>, Referentiel])
        )*/
      }),
      map(([response, ref, cahiers, agents, activites]) => {
        response.response = this.mapper.deserializeObject(response.response, ControleResultat, Object.assign({ cahiers, agents, activites }, ref));
        return response as PaginateResponseStream<ControleResultat>;
      }),
      tap(response => {
        response.response.controles.forEach(item => {
          this.operateurService.getOperateur(item.idOperateur)
            .subscribe(operateur => item.operateur = operateur);
        });
      }),
      tap(response => {
        response.response.controles.forEach(item => {
          this.operateurService.getInformationsDomaine(item.idOperateur)
            .subscribe(infos => item.infosOperateur = infos);
        });
      }),
      tap(response => {
        response.response.controles.forEach(controle => {
          controle.codeProduitList.forEach(codeProduit => {
            this.produitService.getProduitByCode(codeProduit)
              .subscribe(produit => controle.produits.push(produit));
          });
        });
      })
    );
  }

  public countControlesPrevus(): Observable<number> {
    return this.http.get<number>(`/api/declaration/private/controles/prevu/count`);
  }
}
