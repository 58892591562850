import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { Pays } from './pays.model';
import { DataIdConverterFactory } from 'src/app/core/services/mapper/data-id.converter';

@JsonObject
export class Operateur {
  @JsonProperty('id', Number)
  @Optional
  public id: number = undefined;

  @JsonProperty('email', String)
  @Optional
  email: string = undefined;

  @JsonProperty('nom', String)
  @Optional
  nom: string = undefined;

  @JsonProperty('prenom', String)
  @Optional
  prenom: string = undefined;

  @JsonProperty('raisonSociale', String)
  @Optional
  raisonSociale: string = undefined;

  @JsonProperty('siret', String)
  @Optional
  siret: string = undefined;

  @JsonProperty('structureJuridique', String)
  @Optional
  structureJuridique: string = undefined;

  @JsonProperty('adresse', String)
  @Optional
  adresse: string = undefined;

  @JsonProperty('codePostal', String)
  @Optional
  codePostal: string = undefined;

  @JsonProperty('ville', String)
  @Optional
  ville: string = undefined;

  @JsonProperty('idPays', Number)
  @Optional
  @CustomConverter(DataIdConverterFactory.build(Pays, 'pays'))
  pays: Pays = undefined;

  @JsonProperty('cedex', String)
  @Optional
  cedex: string = undefined;

  @JsonProperty('tvaIntracommunautaire', String)
  @Optional
  tvaIntracommunautaire: string = undefined;

  @JsonProperty('telephoneFixe', String)
  @Optional
  telephoneFixe: string = undefined;

  @JsonProperty('telephoneMobile', String)
  @Optional
  telephoneMobile: string = undefined;

  @JsonProperty('fax', String)
  @Optional
  fax: string = undefined;

  public getCustomLabel(): string {
    return `${this.prenom} ${this.nom} ${(this.raisonSociale ? `(${this.raisonSociale})` : '')}`;
  }

  public toString(): string {
    // return `${this.prenom} ${this.nom}`;
    return this.raisonSociale;
  }
}
