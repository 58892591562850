import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { CommissionCahierEdition } from './commission-cahier-edition.model';
import { CommissionEchantillonEdition } from './commission-echantillon-edition.model';
import { CommissionJureEdition } from './commission-jure-edition.model';
import { OrganismeEdition } from './organisme-edition.model';

@JsonObject
export class CommissionEdition {
    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('date', String)
    @Optional
    date: string;

    @JsonProperty('adresse', String)
    @Optional
    adresse: string;

    @JsonProperty('numero', String)
    @Optional
    numero: string;

    @JsonProperty('heure', String)
    @Optional
    heure: string;

    @JsonProperty('nbEchantillons', String)
    @Optional
    nbEchantillons: string;

    @JsonProperty('nbOperateurs', String)
    @Optional
    nbOperateurs: string;

    @JsonProperty('nbConformes', String)
    @Optional
    nbConformes: string;

    @JsonProperty('nbNonConformes', String)
    @Optional
    nbNonConformes: string;

    @JsonProperty('nbJures', String)
    @Optional
    nbJures: string;

    @JsonProperty('nbJuresPresents', String)
    @Optional
    nbJuresPresents: string;

    @JsonProperty('responsable', String)
    @Optional
    responsable: string;

    @JsonProperty('idResponsable', Number)
    @Optional
    idResponsable: number;


    @JsonProperty('cahiers', [CommissionCahierEdition])
    @Optional
    cahiers: CommissionCahierEdition[];

    @JsonProperty('echantillons', [CommissionEchantillonEdition])
    @Optional
    echantillons: CommissionEchantillonEdition[];

    @JsonProperty('jures', [CommissionJureEdition])
    @Optional
    jures: CommissionJureEdition[];

    @JsonProperty('organisme', [OrganismeEdition])
    @Optional
    organisme: OrganismeEdition;
}
