import { JsonProperty, JsonObject } from '@thorolf/json-ts-mapper';

@JsonObject
export class ControleEchantillonEdition {

    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('numero', String)
    numero: string;

    @JsonProperty('cahier', String)
    cahier: string;
}
