import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';
import { UtilisateurTypeCode } from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';

@JsonObject
export class ApiCredentials {

  @JsonProperty('token', String)
  token: string = undefined;

  @JsonProperty('idOrganisme', Number)
  @Optional
  idOrganisme: number = undefined;

  @JsonProperty('idDomaine', Number)
  @Optional
  idDomaine: number = undefined;

  @JsonProperty('codeDomaine', String)
  @Optional
  codeDomaine: string = undefined;

  @JsonProperty('idIntervenant', Number)
  @Optional
  idIntervenant: number = undefined;

  @JsonProperty('mailIntervenant', String)
  @Optional
  mailIntervenant: string = undefined;

  @JsonProperty('idPortail', Number)
  @Optional
  idPortail: number = undefined;

  @JsonProperty('codePortail', String)
  @Optional
  codePortail: string = undefined;

  @JsonProperty('utilisateurType', String)
  utilisateurType: UtilisateurTypeCode = UtilisateurTypeCode.NONE;

  @JsonProperty('roles', [String])
  roles: string[] = [];
}
