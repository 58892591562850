<div class="modal-content anpp">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      <fa-icon icon="plus-square" class="primary-icon"></fa-icon>
      <b translate> page.inventaire-verger.modals.localisations.localisationTitle</b></h5>
    <button (click)="cancel()" type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngb-tabset justify="justified" #tabSet="ngbTabset">
      <ngb-tab id="1" title="{{'page.inventaire-verger.modals.add-bloc.localisationTitle'|translate }}">
        <ng-template ngbTabContent>
          <ngb-accordion [destroyOnHide]='false' [closeOthers]="true" (panelChange)='panelShadow($event, shadow)'
                         activeIds="panelListeLocalisation" #shadow>
            <ngb-panel id="panelListeLocalisation" title="{{'page.inventaire-verger.modals.add-bloc.panelTitleListeLoca'|translate }}">

              <ng-template ngbPanelContent>
                <div class="form-group">
                  <table  class="table table-striped" *ngIf="localisations">
                    <thead>
                    <tr>
                      <th translate>page.inventaire-verger.modals.add-bloc.operation</th>
                      <th *ngIf="show('departement')" translate>page.inventaire-verger.departement</th>
                      <th *ngIf="show('commune')" translate>page.inventaire-verger.commune</th>
                      <th *ngIf="show('lieuDit')" translate>page.inventaire-verger.lieuDit</th>
                      <th *ngIf="show('refCadastrale')" translate>page.inventaire-verger.refCadastrale</th>
                      <th *ngIf="show('situation')" translate>page.inventaire-verger.situation</th>
                      <th *ngIf="show('systemeIrrigation')" translate>page.inventaire-verger.systemeIrrigation</th>
                      <th *ngIf="show('protectionGrele')" translate>page.inventaire-verger.protectionGrele</th>
                      <th *ngIf="show('protectionGel')" translate>page.inventaire-verger.protectionGel</th>
                      <th *ngIf="show('forme')" translate>page.inventaire-verger.forme</th>
                      <th *ngIf="show('cultureBio')" translate>page.inventaire-verger.cultureBio</th>
                      <th *ngIf="show('nbBloc')" translate>page.inventaire-verger.modals.add-bloc.nbBlocsLoca</th>
                      <th>
                        <fa-icon class="clickable-icon" icon="ellipsis-h" (click)="showList()"></fa-icon>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="localisations && localisations.length === 0">
                      <td colspan="99" class="p-4"><fa-icon icon="exclamation-triangle"></fa-icon>&nbsp;
                        <span translate>page.inventaire-verger.modals.localisations.no_localisations</span>
                      </td>
                    </tr>
                    <ng-container *ngIf="localisations && localisations.length > 0">
                    <tr *ngFor="let localisation of localisations; let i=index;">
                      <td>
                        <div class="btn-group dropright  ">
                          <div ngbDropdown placement="right-start">
                            <button class="btn btn-secondary dropdown-toggle btnprimary btn-sm "
                                    id="dropdownConfig" ngbDropdownToggle>
                              <fa-icon icon="cog"></fa-icon>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                              <button (click)="modifierLocalisation(localisation)" ngbDropdownItem
                                      class="dropdown-item" translate>
                                <fa-icon icon="pencil-alt"></fa-icon>
                                page.inventaire-verger.modals.add-bloc.buttonModifierLocalisation
                              </button>
                              <button (click)="supprimerLocalisation(localisation)" ngbDropdownItem
                                      class="dropdown-item" translate>
                                <fa-icon icon="trash-alt"></fa-icon>
                                page.inventaire-verger.modals.add-bloc.buttonSupprimerLocalisation
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td *ngIf="show('departement')">{{ localisation.departement}}</td>
                      <td *ngIf="show('commune')">{{ localisation.commune}}</td>
                      <td *ngIf="show('lieuDit')">{{ localisation.lieuDit}}</td>
                      <td *ngIf="show('refCadastrale')">{{ localisation.referenceCadastrale}}</td>
                      <td *ngIf="show('situation')">{{ localisation.caracteristiqueParcelle.refSituation ? localisation.caracteristiqueParcelle.refSituation.libelle : ''}}</td>
                      <td *ngIf="show('systemeIrrigation')">{{localisation.caracteristiqueParcelle.refSystemeIrrigation ? localisation.caracteristiqueParcelle.refSystemeIrrigation.libelle : ''}}</td>
                      <td *ngIf="show('protectionGrele')">{{localisation.caracteristiqueParcelle.refProtectionGrele ? localisation.caracteristiqueParcelle.refProtectionGrele.libelle : ''}}</td>
                      <td *ngIf="show('protectionGel')">{{ localisation.caracteristiqueParcelle.refProtectionGel ?localisation.caracteristiqueParcelle.refProtectionGel.libelle : '' }}</td>
                      <td *ngIf="show('forme')">{{ localisation.caracteristiqueParcelle.refForme ? localisation.caracteristiqueParcelle.refForme.libelle : '' }}</td>
                      <td *ngIf="show('cultureBio')">{{ localisation.caracteristiqueParcelle.refCultureBio ? localisation.caracteristiqueParcelle.refCultureBio.libelle : '' }}</td>
                      <td *ngIf="show('nbBloc')">{{ localisation.nbBloc}}</td>
                    </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </ng-template>

            </ngb-panel>
            <ngb-panel id="panelAjouterLocalisation" title="{{'page.inventaire-verger.modals.add-bloc.panelTitleAjouterLoca'|translate }}">
              <ng-template ngbPanelContent>
                <ngb-tabset (tabChange)="changeTabLoca(tabSet)" #tabSet="ngbTabset">
                  <ngb-tab id="localisationGeo" title="{{'page.inventaire-verger.modals.add-bloc.panelTitleLocaGeo'|translate }}">
                    <ng-template ngbTabContent>
                      <ng-container *ngIf="controleFormLocalisationGeo">
                        <form [formGroup]="controleFormLocalisationGeo" #form="ngForm" (ngSubmit)="submit()" novalidate>
                          <div class="row">
                            <div class="col-lg-4">
                              <div class="form-group">
                                <label translate>page.inventaire-verger.modals.add-bloc.codePostal</label>
                                <input [ngClass]="{'is-invalid': (form.submitted || form.touched) && codePostal.invalid || (communes && communes.length === 0)}" (keyup)="changeValueCodePostal($event)" type="text" maxlength="5" formControlName="codePostal"
                                       class="form-control">
                                <ng-container *ngIf="form.submitted || form.touched">
                                  <div *ngIf="codePostal.errors?.required" class="form-error" translate>label.obligatoire</div>
                                </ng-container>
                                <ng-container >
                                  <div *ngIf="communes && communes.length === 0" class="form-error" translate>Aucune commune avec ce code postal</div>
                                </ng-container>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="form-group">
                                <label translate>page.inventaire-verger.departement</label>
                                <select [ngClass]="{'is-invalid': (form.submitted || form.touched) && departement.invalid}" formControlName="departement" class="form-control">

                                  <option *ngFor="let departement of departements"
                                          [ngValue]="departement">{{departement.code}} - {{departement.nom}}</option>
                                </select>
                                <ng-container *ngIf="form.submitted || form.touched">
                                  <div *ngIf="departement.errors?.required" class="form-error" translate>label.obligatoire</div>
                                </ng-container>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="form-group">
                                <label translate>page.inventaire-verger.commune</label>
                                <select [ngClass]="{'is-invalid': (form.submitted || form.touched) && commune.invalid}" formControlName="commune" class="form-control">
                                  <option *ngFor="let commune of communes" [ngValue]="commune">{{commune.nom}}</option>
                                </select>
                                <ng-container *ngIf="form.submitted || form.touched">
                                  <div *ngIf="commune.errors?.required" class="form-error" translate>label.obligatoire</div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-4">
                              <div class="form-group">
                                <label translate>page.inventaire-verger.lieuDit</label>
                                <input [ngClass]="{'is-invalid': (form.submitted || form.touched) && lieuDit.invalid}" (keyup)="changeLocalisationGeo()" type="text" formControlName="lieuDit"
                                       class="form-control">
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="form-group">
                                <label translate>page.inventaire-verger.refCadastrale</label>
                                <input [ngClass]="{'is-invalid': (form.submitted || form.touched) && refCadastrale.invalid}" (keyup)="changeLocalisationGeo()" type="text" formControlName="refCadastrale"
                                       class="form-control">
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="form-group">
                                <label translate>page.inventaire-verger.situation</label>
                                <select [ngClass]="{'is-invalid': (form.submitted || form.touched) && situationLoca.invalid}"  formControlName="situation" class="form-control">
                                  <option *ngFor="let situation of refSituations"
                                          [ngValue]="situation">{{situation.libelle}}</option>
                                </select>
                                <ng-container *ngIf="form.submitted || form.touched">
                                  <div *ngIf="situationLoca.errors?.required" class="form-error" translate>label.obligatoire</div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                          <button  (click)=onTabAddLocaChange(tabSet) type="button"
                                   class="btn btn-primary"
                                   style="background:#41905c;border:1px solid #41905c" data-dismiss="modal" aria-label="Close" translate>page.inventaire-verger.modals.add-bloc.buttonSuivant
                            <fa-icon icon="arrow-right" class="primary-icon"></fa-icon>
                          </button>
                        </form>
                      </ng-container>
                    </ng-template>
                  </ngb-tab>
                  <ngb-tab id="equipementsConduite" title="{{'page.inventaire-verger.modals.add-bloc.panelEquipementsConduites'|translate }}">
                    <ng-template ngbTabContent>
                      <form [formGroup]="controleFormEquipementConduite" #form="ngForm" (ngSubmit)="submit()"
                            novalidate>
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label translate>page.inventaire-verger.systemeIrrigation</label>
                              <select formControlName="systemeIrrigation" class="form-control">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let systemeIrrigation of refSystemeIrrigations"
                                        [ngValue]="systemeIrrigation">{{systemeIrrigation.libelle}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label translate>page.inventaire-verger.protectionGel</label>
                              <select formControlName="protectionGel" class="form-control">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let protectionGel of refProtectionGels"
                                        [ngValue]="protectionGel">{{protectionGel.libelle}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label translate>page.inventaire-verger.protectionGrele</label>
                              <select formControlName="protectionGrele" class="form-control">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let protectionGrele of refProtectionGreles"
                                        [ngValue]="protectionGrele">{{protectionGrele.libelle}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label translate>page.inventaire-verger.cultureBio</label>
                              <select formControlName="cultureBio" class="form-control">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let cultureBio of refCultureBios"
                                        [ngValue]="cultureBio">{{cultureBio.libelle}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label translate>page.inventaire-verger.forme</label>
                              <select formControlName="forme" class="form-control">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let forme of refFormes" [ngValue]="forme">{{forme.libelle}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <button type="submit" *ngIf="!this.updateLocalisation"
                                class="btn btn-primary"
                                style="background:#41905c;border:1px solid #41905c" data-dismiss="modal" aria-label="Close" translate>
                          page.inventaire-verger.modals.add-bloc.buttonAjouterLocalisation
                        </button>
                        <button type="submit"  *ngIf="this.updateLocalisation" class="btn btn-primary"
                                style="background:#41905c;border:1px solid #41905c" data-dismiss="modal" aria-label="Close" translate>
                          page.inventaire-verger.modals.add-bloc.buttonModifierLocalisation
                        </button>
                      </form>
                    </ng-template>
                  </ngb-tab>
                </ngb-tabset>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
</div>
