import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, OperatorFunction } from 'rxjs';
import { map, tap, flatMap, toArray } from 'rxjs/operators';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Operateur } from '../../models/operateur.model';
import { DomaineSpec } from '../../models/domaine-spec.model';
import { OperateurInformationsDomaine } from '../../models/operateur-informations-domaine.model';
import { ReferencesService } from '../references/references.service';
import { OperateurDrevManquante } from '../../interfaces/operateur-drev-manquante.interface';
import { ResponseDrevManquantes } from '../../interfaces/response-drev-manquantes.interface';
import { OperateurExport } from 'src/app/data/edition/models/operateur-export.model';

interface ObjectWithOperateur { idOperateur: number; operateur: Operateur; }
interface ObjectWithCvi { idOperateur; cvi: string; }

@Injectable({
  providedIn: 'root'
})
export class OperateursService {
  constructor(
    protected readonly http: HttpClient,
    protected readonly mapper: JsonMapperService,
    private readonly referencesService: ReferencesService
  ) { }

  public getOperateursAffilies(search: string): Observable<Operateur[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/intervenant/private/operateurs?search=${search}`),
      this.referencesService.getReferences(),
    ).pipe(
      map(([operateurs, ref]) => this.mapper.deserializeArray(operateurs, Operateur, ref))
    );
  }

  public getNombreOperateursAffilies(): Observable<number> {
    
      return this.http.get<number>(`/api/intervenant/private/operateurs/count`)
  }

  public getAutresOperateurs(spec: DomaineSpec, search: string): Observable<Operateur[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/intervenant/private/operateurs?spec=${spec.code}&search=${search}`),
      this.referencesService.getReferences()
    ).pipe(
      map(([operateur, ref]) => this.mapper.deserializeArray(operateur, Operateur, ref))
    );
  }

  public getOperateur(idOperateur: number): Observable<Operateur> {
    return forkJoin(
      this.http.get<object>(`/api/intervenant/private/details-operateurs/${idOperateur}`),
      this.referencesService.getReferences()
    ).pipe(
      map(([operateur, ref]) => this.mapper.deserializeObject(operateur, Operateur, ref))
    );
  }

  public getOperateursByOrganisme(idOrganisme: number): Observable<OperateurExport[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/intervenant/private/organismes/${idOrganisme}/operateurs`),
      this.referencesService.getReferences()
    ).pipe(
      map(([operateur, ref]) => this.mapper.deserializeArray(operateur, OperateurExport, ref))
    );
  }

  public getOperateurPipe<T extends ObjectWithOperateur | ObjectWithOperateur[]>() {
    return tap((list: T[]) => {
      const liste: ObjectWithOperateur[] = [].concat(list);

      liste.forEach(item => {
        this.getOperateur(item.idOperateur)
          .subscribe(operateur => item.operateur = operateur);
      });
    });
  }

  public getInformationsDomaine(idOperateur: number): Observable<OperateurInformationsDomaine[]> {
    return this.http.get<object[]>(`/api/intervenant/private/details-operateurs/${idOperateur}/informations-domaine`).pipe(
      map(infos => this.mapper.deserializeArray(infos, OperateurInformationsDomaine))
    );
  }

  /** Retourne le CVI d'un objet qui contient un ID d'opérateur */
  public getCviPipe<T extends ObjectWithCvi | ObjectWithCvi[]>() {
    return tap((list: T[]) => {
      const liste: ObjectWithCvi[] = [].concat(list);

      liste.forEach(item => {
        this.getInformationsDomaine(item.idOperateur).subscribe(infos => {
          const cvi = infos.find(info => info.code === 'VIN_CVI');
          item.cvi = (cvi) ? cvi.valeur : '';
        });
      });
    });
  }

  public getOperateursDREVManquantes(idOrganisme: number): Observable<OperateurDrevManquante[]> {
    // return this.http.get<ResponseDrevManquantes>(`/assets/mocks/operateurs-drev-manquantes.json`).pipe(
    return this.http.get<ResponseDrevManquantes>(`/api/declaration/private/drev/${idOrganisme}/manquantes`).pipe(
      flatMap(result => {
        return forkJoin(result.intervenantIds.map(id => {
          return forkJoin(
            this.getOperateur(id),
            this.getInformationsDomaine(id)
          ).pipe(
            map(result => ({ operateur: result[0], informations: result[1] }))
          );
        }));
      })
    );
  }

  public countOperateursDREVManquantes(idOrganisme: number): Observable<number> {
    return this.http.get<number>(`/api/declaration/private/drev/${idOrganisme}/manquantes/count`);
  }

  public getOperateursFacturePanel(idOperateur: number, idOrganisme: number): Observable<string> {
      return this.http.get<string>(`/api/facturation/private/organismes/${idOrganisme}/operateurs/${idOperateur}/panel`, {responseType: 'text' as 'json'})
  }
}
