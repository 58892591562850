<div>
  <div class="content" >
    <div class="row min-height" *ngIf="!masquageOngletsEtRecherche">
      <div class="col-lg-4">
        <h4><b>Déclarations</b></h4>
      </div>
      <div class="col-lg-4" *ngIf="isAnpp()">
        <app-search-adherent-actif-bar (newItemEvent)="addItem($event)"></app-search-adherent-actif-bar>
        <div>
          <strong>Adhérent : </strong><span *ngIf="declarationService.entrepriseSelectionnee && isAnpp()" class="col-lg-4">{{declarationService.entrepriseSelectionnee.raisonSociale}}</span>
        </div>
      </div>

    </div>

    <ngb-tabset class="mt-2" #tabSet="ngbTabset" (tabChange)="onTabChange($event, tabSet)" [(activeId)]="declarationService.activeTabId" *ngIf="!masquageOngletsEtRecherche"
    style="min-height: unset">

      <ngb-tab id="vergers" title="Inventaire vergers">
      </ngb-tab>

      <ngb-tab id="recoltes" title="Récoltes" *hasRole="'DECLARATION_RECOLTE_ECRITURE || ORGANISME'">
      </ngb-tab>

      <ngb-tab id="stocks" title="Stocks" *hasRole="'DECLARATION_STOCK_ECRITURE || ORGANISME'">
      </ngb-tab>

      <ngb-tab id="ecoulements" title="Ecoulements" *hasRole="'DECLARATION_ECOULEMENT_ECRITURE || ORGANISME'">
      </ngb-tab>

    </ngb-tabset>
    <router-outlet></router-outlet>
  </div>
</div>


