import { Component, OnInit } from '@angular/core';
import { PressionResultatsService } from 'src/app/data/declaration/services/pression-resultats/pression-resultats.service';
import { PressionResultatItem } from 'src/app/data/declaration/models/pression-resultat-item.model';
import { PressionControle } from 'src/app/data/declaration/models/pression-controle.model';
import { PressionsService } from 'src/app/data/declaration/services/pressions/pressions.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditionControleComponent } from './modals/edition-controle/edition-controle.component';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { MouvementsProduitsService } from 'src/app/data/declaration/services/mouvements/mouvements-produits.service';

@Component({
  selector: 'app-list-controles',
  templateUrl: './list-controles.component.html',
  styleUrls: ['./list-controles.component.scss']
})
export class ListControlesComponent implements OnInit {
  objectif: number;
  realise: number;
  resteAFaire: number;
  resultats: PressionResultatItem[];
  pressions: PressionControle[];

  searchForm: FormGroup;
  pagination: { loadPages: (() => {})[]; loadPrev: () => {}; loadNext: () => {}; length: number; start: number; end: number; maxRange: number; };

  constructor(
    private readonly pressionResultatsService: PressionResultatsService,
    private readonly pressionsService: PressionsService,
    private readonly mouvementProduit: MouvementsProduitsService,
    private readonly fb: FormBuilder,
    private readonly modals: NgbModal,
    private readonly loader: AdvBootstrapLoaderService,
    private readonly modalService: AdvBootstrapModalService,
  ) { }


  ngOnInit() {
    this.searchForm = this.fb.group({
      pression: [undefined, Validators.required],
      raisonSociale: [""],
      non_controlable: [false],
      annee: [undefined, [Validators.required, Validators.pattern(/^\d{4}$/)]]
    });

    setTimeout(() => { this.loadData(); });
  }

  get selectedPression() {
    return this.searchForm.get('pression').value as PressionControle;
  }

  loadData() {
    this.pressionsService.getPressionsControle().pipe(
      this.loader.operator()
    ).subscribe(pressions => {
      this.pressions = pressions;
    });
  }

  search() {

    if (this.searchForm.valid) {
      const pression = this.searchForm.get('pression').value as PressionControle;
      const annee = +this.searchForm.get('annee').value as number;
      const raisonSociale = this.searchForm.get('raisonSociale').value as string;
      const non_controlable = this.searchForm.get('non_controlable').value as string;

      if (!pression.campagnes.find(c => c.annee === annee)) {

        this.modalService.confirm(`La pression de contrôle choisie n’a pas d’objectif défini pour l’année ${annee}. Voulez-vous définir un objectif ${annee} ?`, '').then(
          () => {
            this.pressionsService.ajouterCampagne(pression, annee).pipe(
              this.loader.operator()
            ).subscribe(() => {
              this.loadData();
            });
          },
          () => { }
        );

        return;
      }

      this.pressionResultatsService.getResultatsPression(pression.id, annee, raisonSociale, non_controlable).subscribe(res => {
        this.resultats = res.response.pressionResultatList;
        this.objectif = res.response.objectif;
        this.realise = res.response.realise;
        this.resteAFaire = res.response.resteAFaire;

        this.pagination = res.pagination;
      });
    }
  }

  getCVI(resultat: PressionResultatItem): string {
    try {
      return resultat.infosOperateur.find(infos => !!~infos.code.indexOf('CVI')).valeur;
    } catch (e) {
      return '';
    }
  }

  creerControle(resultat: PressionResultatItem) {
    const modal = this.modals.open(EditionControleComponent, { size: 'xl' as any, backdrop: 'static' });
    modal.componentInstance.resultatRecherche = resultat;
    modal.componentInstance.pression = this.searchForm.get('pression').value;

    modal.result.then(
      () => { this.search(); },
      () => { this.search(); }
    );
  }

  changeStatus(mouvement : PressionResultatItem, ischeck: boolean){
  this.mouvementProduit.setMouvementNonControlable(mouvement.idMouvement, ischeck).pipe(
    this.loader.operator()
  ).subscribe(() => {
      mouvement.estNonControlable = ischeck;
  });
  }
}
