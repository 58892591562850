import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SaisirConstatComponent } from './modals/saisir-constat/saisir-constat.component';
import { Controle } from 'src/app/data/declaration/models/controle.model';
import { NatureControle } from 'src/app/data/declaration/models/nature-controle.model';
import { ObjetControle } from 'src/app/data/declaration/models/objet-controle.model';
import * as moment from 'moment';
import { ReferentielServiceMock } from 'src/app/data/declaration/services/referentiel/referentiel.service.mock';
import { CahiersServiceMock } from 'src/app/data/habilitation/services/cahiers/cahiers.service.mock';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModalBackdrop } from '@ng-bootstrap/ng-bootstrap/modal/modal-backdrop';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { forkJoin } from 'rxjs';
import { ControlesService } from 'src/app/data/declaration/services/controles/controles.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { ControleResultat } from 'src/app/data/declaration/models/controle-resultat.model';
import { StatutControle } from 'src/app/data/declaration/models/statut-controle.model';
import { TypeControle } from 'src/app/data/declaration/models/type-controle.model';
import { Animateur } from 'src/app/data/commission/models/animateur.model';
import { AnimateursService } from 'src/app/data/commission/services/animateurs/animateurs.service';
import { SyntheseConstatsComponent } from './modals/synthese-constats/synthese-constats.component';
import { SaisieLot } from 'src/app/data/declaration/interfaces/saisie-lot.interface';
import { Manquement } from 'src/app/data/declaration/models/manquement.model';
import { LaboratoiresService } from 'src/app/data/declaration/services/laboratoires/laboratoires.service';
import { Laboratoire } from 'src/app/data/declaration/models/laboratoire.model';
import { ActivitesService } from 'src/app/data/habilitation/services/activites/activites.service';
import { Activite } from 'src/app/data/habilitation/models/activite.model';
import { TranslateService } from '@ngx-translate/core';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { CategorieEnum } from 'src/app/data/edition/models/enums/categorie.enum.model';
import { TelechargerDocumentComponent } from '../../../main/components/telecharger-document/telecharger-document.component';

@Component({
  selector: 'app-suivi-controles',
  templateUrl: './suivi-controles.component.html',
  styleUrls: ['./suivi-controles.component.scss']
})
export class SuiviControlesComponent implements OnInit {
  private idOrganisme: number;
  public controleResultat: ControleResultat;
  public formSearchControles: FormGroup;
  public refNatures: NatureControle[];
  public refStatuts: StatutControle[];
  public refTypes: TypeControle[];
  public animateurs: Animateur[];
  public activites: Activite[];
  public refLaboratoires: Laboratoire[];
  public controles: Controle[];
  public estFiltreAnalytique: Boolean = false;
  public estFiltreOrganoleptique: Boolean = false;
  public estRechercheAnalytique: Boolean = false;
  public estRechercheOrganoleptique: Boolean = false;
  pagination: {
    loadPages: (() => {})[];
    loadPrev: () => {};
    loadNext: () => {};
    length: number;
    start: number;
    end: number;
    maxRange: number;
    currentPage: number;
  };

  get nature() { return this.formSearchControles.get('nature'); }
  get statuts() { return this.formSearchControles.get('statuts'); }
  get types() { return this.formSearchControles.get('types'); }
  get agents() { return this.formSearchControles.get('agents'); }
  get debutDateControle() { return this.formSearchControles.get('debutDateControle'); }
  get finDateControle() { return this.formSearchControles.get('finDateControle'); }
  get laboratoire() { return this.formSearchControles.get('laboratoire'); }
  get numeroControle() { return this.formSearchControles.get('numeroControle'); }
  get numeroEchantillon() { return this.formSearchControles.get('numeroEchantillon'); }
  get numeroCommission() { return this.formSearchControles.get('numeroCommission'); }
  get numeroCVI() { return this.formSearchControles.get('numeroCVI'); }
  get laboratoires() { return this.formSearchControles.get('laboratoires'); }

  constructor(
    private readonly controleService: ControlesService,
    private readonly referentielService: ReferentielService,
    private readonly animateursService: AnimateursService,
    private readonly activiteService: ActivitesService,
    private readonly laboratoireService: LaboratoiresService,
    private readonly fb: FormBuilder,
    private readonly modalService: NgbModal,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly translate: TranslateService
  ) { }

  ngOnInit() {
    // Récupérer l'ID organisme
    this.idOrganisme = SessionContext.get('idOrganisme');

    // Instancier le formulaire de recherche
    this.formSearchControles = this.fb.group({
      nature: [undefined, Validators.required],
      statuts: undefined,
      types: undefined,
      agents: undefined,
      debutDateControle: undefined,
      finDateControle: undefined,
      laboratoire: undefined,
      numeroControle: undefined,
      numeroEchantillon: undefined,
      numeroCommission: undefined,
      numeroCVI: undefined,
      laboratoires: undefined
    });

    this.loadData();
  }

  private loadData(): void {
    forkJoin(
      this.animateursService.getAnimateurs(this.idOrganisme),
      this.laboratoireService.getLaboratoire(this.idOrganisme),
      this.referentielService.getReferentiel(),
      this.activiteService.getActivites()
    ).subscribe(([animateurs, laboratoires, referentiel, activites]) => {
      this.animateurs = animateurs;
      this.activites = activites;
      this.refLaboratoires = laboratoires;
      this.refNatures = referentiel.naturesControle;
      this.refStatuts = referentiel.statutsControle;
      this.refTypes = referentiel.typesControle;
      animateurs.map(animateur => animateur.nomComplet = animateur.nom + ' ' + animateur.prenom);
    });
  }

  private getMomentDate(date: {
    year: number,
    month: number,
    day: number
  }): moment.Moment {
    return (date) ? moment([date.year, date.month - 1, date.day, 0, 0, 0]) : null;
  }

  public updateStatut(controle: Controle) {
    this.modalsService.confirm(
      this.translate.instant(`page.controles.controles.modalConfirmText`),
      this.translate.instant(`page.controles.controles.modalConfirmTitle`), {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.valider`)
    }
    ).then(() => {
      controle.statut = this.refStatuts.find(statut => statut.code == 'TERMINE');
      this.controleService.modifierStatutControle(this.idOrganisme, controle).subscribe();
    }, () => { });
  }

  public onSubmit(): void {
    if (this.formSearchControles.valid) {
      const nature = this.nature.value;
      const statuts = (this.statuts.value) ? this.statuts.value.map(statut => statut.id) : undefined;
      const types = (this.types.value) ? this.types.value.map(type => type.id) : undefined;
      const agents = (this.agents.value) ? this.agents.value.map(agent => agent.id) : undefined;
      const laboratoires = (this.laboratoires.value) ? this.laboratoires.value.map(laboratoire => laboratoire.id) : undefined;
      const debutDateControle = this.debutDateControle.value;
      const finDateControle = this.finDateControle.value;
      const numeroControle = this.numeroControle.value;
      const numeroEchantillon = this.numeroEchantillon.value;
      const numeroCommission = this.numeroCommission.value;
      const numeroCVI = this.numeroCVI.value;

      const natureSelectionnee: NatureControle = this.refNatures.find(nature => nature.id == this.nature.value);
      if (natureSelectionnee.code === 'ORGANOLEPTIQUE') {
        this.estRechercheAnalytique = false;
        this.estRechercheOrganoleptique = true;
      } else if (natureSelectionnee.code === 'ANALYTIQUE') {
        this.estRechercheAnalytique = true;
        this.estRechercheOrganoleptique = false;
      } else {
        this.estRechercheAnalytique = false;
        this.estRechercheOrganoleptique = false;
      }

      this.controleService.getFilteredControles(
        this.idOrganisme,
        nature,
        statuts,
        types,
        agents,
        laboratoires,
        this.getMomentDate(debutDateControle),
        this.getMomentDate(finDateControle),
        numeroControle,
        numeroEchantillon,
        numeroCommission,
        numeroCVI
      ).subscribe(controleResultat => {
        this.controles = controleResultat.response.controles;
        this.pagination = controleResultat.pagination;
      });
    }
  }

  getCVI(resultat: Controle): string {
    try {
      return resultat.infosOperateur.find(infos => !!~infos.code.indexOf('CVI')).valeur;
    } catch (e) {
      return '';
    }
  }

  getLibelleCahier(resultat: Controle): string[] {
    const libellesCahier = resultat.cahiers.map(cahier => cahier.libelle);
    return (libellesCahier) ? libellesCahier : null;
  }

  getNomAgent(resultat: Controle): string {
    const animateur = this.animateurs.find(animateur => animateur.id == resultat.idAgent);
    return (animateur) ? `${animateur.nom} ${animateur.prenom}` : '';
  }

  getLibelleActivite(resultat: Controle): string {
    const libelleActivite = resultat.activites.map(activite => activite.libelle);
    return (libelleActivite) ? libelleActivite.join(',') : '';
  }

  getLibelleProduit(resultat: Controle): string[] {
    const libelleList = resultat.produits.map(produit => produit.libelle);
    return (libelleList) ? libelleList : null;
  }

  changeNature() {
    const natureSelectionnee: NatureControle = this.refNatures.find(nature => nature.id == this.nature.value);
    if (natureSelectionnee.code === 'ORGANOLEPTIQUE') {
      this.estFiltreAnalytique = false;
      this.estFiltreOrganoleptique = true;
    } else if (natureSelectionnee.code === 'ANALYTIQUE') {
      this.estFiltreAnalytique = true;
      this.estFiltreOrganoleptique = false;
    } else {
      this.estFiltreAnalytique = false;
      this.estFiltreOrganoleptique = false;
    }
  }

  /**
   * Initie une saisie par lot de manquements sur un contrôle donné
   * @param controle Le contrôle à invalider
   * @param lot Un lot en cours de saisie
   */
  public addConstat(
    controle: Controle,
    lot: SaisieLot<Manquement[]> = undefined
  ): void {
    // Ouvrir la modale de saisie
    const modalSaisie = this.modalService.open(SaisirConstatComponent, { backdrop: 'static', windowClass: 'app-modal-1000px' });
    modalSaisie.componentInstance.controle = controle;
    if (lot) {
      modalSaisie.componentInstance.saisieLot = lot;
    }

    // Ouvrir la modale de synthèse du lot
    modalSaisie.result.then((constatsManquements) => {
      const modalSynthese = this.modalService.open(SyntheseConstatsComponent, { backdrop: 'static', windowClass: 'app-modal-1000px' });
      modalSynthese.componentInstance.controle = controle;
      modalSynthese.componentInstance.manquements = constatsManquements;

      modalSynthese.result.then((lot: SaisieLot<Manquement[]>) => {
        if (lot && lot.saisieTerminee) {
          // Recharger la page courante
          this.pagination.loadPages[this.pagination.currentPage]();
        } else if (lot) {
          // Ré-ouvrir la modal de saisie de manquements
          this.addConstat(controle, lot);
        }
      }, () => { });
    }, () => { });
  }

  public telechargerDocument(controle: Controle) {
    const modalRef = this.modalService.open(TelechargerDocumentComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.controle = controle;
    modalRef.componentInstance.categorie = CategorieEnum.CONTROLE;
    modalRef.result.then(
      () => { },
      error => { throw error; }
    );
  }
}
